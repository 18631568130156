import React, { useState, useEffect } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Card, Button, Modal, Form } from "react-bootstrap";
import api, { handleApiError } from "../../Utils/api";
import TaskAndIssueComponent from "../TaskAndIssue/TaskAndIssueComponent";
import { convertDateFormat, DayDifference } from "../../Utils/commonFunction";
import TaskAndIssueDetailsComponent from "./TaskAndIssueDetailsComponent";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";
import Loader from "../../Components/Common/Loader ";

const TaskAndIssueTracking = () => {
  const [taskAndIssueStatus, setTaskAndIssueStatus] = useState([]);
  const [allTaskAndIssue, setAllTaskAndIssue] = useState([]);
  const [show, setShow] = useState(false);
  const [OpenDetailPopup, setOpenDetailPopup] = useState(false);
  const [taskAndIssueIDForDetails, setTaskAndIssueIDForDetails] = useState(0);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getTaskAndIssueStatus();
    getTaskAndIssues();
    setIsLoading(true);
    CheckRoleTemplatePermissions("TaskAndIssue")
      .then((obj) => {
        setRoleTemplatePermissions(obj);
      })
      .catch((error) => null)
      .finally(()=>{
        setIsLoading(false);
      })
  }, []);

  const fetchData = (apiEndpoint, setData) => {
    setIsLoading(true);
    api
      .get(apiEndpoint)
      .then(({ data }) => setData(data.data))
      .catch(handleApiError)
      .finally(()=>setIsLoading(false));
  };

  const getTaskAndIssueStatus = () =>
    fetchData(
      "TaskAndIssueStatusMaster/AllTaskAndIssueStatusMaster",
      setTaskAndIssueStatus
    );
  const getTaskAndIssues = () =>
    fetchData("TaskAndIssue/ShowTaskAndIssueForTracking", setAllTaskAndIssue);

  const filterTaskAndIssueByStatus = (stageID) =>
    allTaskAndIssue.filter(
      (item) => item.taskAndIssueStatusMasterID === stageID
    );

  const DraggableTaskAndIssueStatus = ({ stage }) => {
    const [, drop] = useDrop({
      accept: "TaskAndIssue",
      drop: (item) => {
        const updatedOpportunities = allTaskAndIssue.map((TaskAndIssue) =>
          TaskAndIssue.taskAndIssueID === item.id
            ? {
                ...TaskAndIssue,
                taskAndIssueStatusMasterID: stage.taskAndIssueStatusMasterID,
              }
            : TaskAndIssue
        );
        setAllTaskAndIssue(updatedOpportunities);

        // Call API to update task/issue status
        const data = {
          taskAndIssueID: item.id,
          taskAndIssueStatusMasterID: stage.taskAndIssueStatusMasterID,
        };

        api
          .patch(
            `TaskAndIssue/UpadateTaskAndIssueStatus?TaskAndIssueID=${data.taskAndIssueID}&TaskAndIssueStatusMasterID=${data.taskAndIssueStatusMasterID}`
          )
          .then((result) => {
            // Handle success, e.g., show a success message
            console.log(result.data);
          })
          .catch(handleApiError);
      },
    });

    const filteredItems = filterTaskAndIssueByStatus(
      stage.taskAndIssueStatusMasterID
    );

    // const { color, backgroundColor } = getStatusStyles(stage.taskAndIssueStatusMasterName);
    // const cardStyle = { backgroundColor};
    // const CardBorder ={border:`2px solid ${backgroundColor}`}

    return (
      <div ref={drop} className="pipeline-stage">
        <Card.Header className="">
          <div
            className="cardnumber"
            style={{ color: "white", backgroundColor: "red" }}
          >
            {filteredItems.length}
          </div>
          <div className="">
            <h4>{stage.taskAndIssueStatusMasterName}</h4>
          </div>
        </Card.Header>
        <div className="scrollable-data">
          <ul>
            {filteredItems.map((filteredItem) => (
              <DraggableTaskAndIssue
                key={filteredItem.taskAndIssueID}
                TaskAndIssue={filteredItem}
              />
            ))}
          </ul>
        </div>
      </div>
    );
  };

  const getStatusStyles = (status) => {
    const colors = {
      Task: { color: "white", backgroundColor: "#198754" },
      Issue: { color: "white", backgroundColor: "#0d6efd" },
      default: { color: "white", backgroundColor: "#dc3545" },
    };

    return colors[status] || colors.default;
  };

  const DraggableTaskAndIssue = ({ TaskAndIssue }) => {
    const [, drag] = useDrag({
      type: "TaskAndIssue",
      item: { id: TaskAndIssue.taskAndIssueID },
    });

    const { color, backgroundColor } = getStatusStyles(
      TaskAndIssue.taskAndIssueType
    );

    return (
      <li ref={drag}>
        <div
          className="cardDiv d-block"
          style={{ border: `0px` }}
          onClick={() => OpenDetails(TaskAndIssue.taskAndIssueID)}
        >
          <div className="ClientName" style={{ color, backgroundColor }}>
            {TaskAndIssue.clientName}
          </div>
          <div className="cardDetail">
            <label className="d-contents">Subject: </label>{" "}
            {TaskAndIssue.taskAndIssueSubject}
          </div>
          <div className="cardDetail d-flex justify-content-between">
            <div>
              {" "}
              <label className="d-contents">Date: </label>{" "}
              {convertDateFormat(TaskAndIssue.createdDate)}
            </div>
          </div>
          <div className="cardDetail d-flex justify-content-between">
            <div>
              <label className="d-contents">Expected: </label>{" "}
              {convertDateFormat(TaskAndIssue.expectedResolveDate)}
            </div>
          </div>
          <div className="cardDetail d-flex justify-content-between">
            <div>
              {" "}
              <label className="d-contents">D Name: </label>{" "}
              {TaskAndIssue.developerName}
            </div>
          </div>
          <div className="cardDetail d-flex justify-content-between">
            <div style={{ color: `${backgroundColor}` }}>
              {TaskAndIssue.taskAndIssueType}-{TaskAndIssue.priority}
            </div>
            <div>
              {TaskAndIssue.expectedResolveDate === null ? (
                ""
              ) : (
                <div
                  style={{
                    color: DayDifference(
                      TaskAndIssue.expectedResolveDate
                    ).includes("OverDue")
                      ? "red"
                      : "green",
                  }}
                >
                  {DayDifference(TaskAndIssue.expectedResolveDate)}
                </div>
              )}
            </div>
          </div>
        </div>
      </li>
    );
  };

  const DroppableContainer = () => {
    const [, drop] = useDrop({
      accept: "TaskAndIssueStatus",
    });

    return (
      <div ref={drop} className="pipeline-stages">
        {taskAndIssueStatus.map((stage, index) => (
          <DraggableTaskAndIssueStatus key={index} stage={stage} />
        ))}
      </div>
    );
  };

  const OpenDetails = (id) => {
    setOpenDetailPopup(true);
    setTaskAndIssueIDForDetails(id);
  };

  return (
    <>
      {" "}
      {isLoading && <Loader/>}
      <DndProvider backend={HTML5Backend}>
        <Card>
          <Card.Header className="d-flex justify-content-between">
            <span>TaskAndIssue Tracking</span>
            <div>
              <Button
                onClick={() => window.open("/TaskAndIssue", "_blank")}
                className="outline-primaryOfTapti mx-2 fw-bold"
              >
                TaskAndIssue Data
              </Button>
              {RoleTemplatePermissions &&
                RoleTemplatePermissions.addPermission && (
                  <Button
                    className="outline-primaryOfTapti "
                    onClick={() => setShow(true)}
                  >
                    <i class="fa fa-plus"></i>
                  </Button>
                )}
            </div>
          </Card.Header>
          <div className="pipeline-container">
            <DroppableContainer />
          </div>
        </Card>
      </DndProvider>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          getTaskAndIssues();
          getTaskAndIssueStatus();
        }}
        size="xl"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton closeVariant={"white"}>
          <Modal.Title id="example-custom-modal-styling-title">
            New TaskAndIssue
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <TaskAndIssueComponent
              TaskAndIssueID={() => {
                setShow(false);
                getTaskAndIssues();
                getTaskAndIssueStatus();
              }}
            />
          </Form.Group>
        </Modal.Body>
      </Modal>
      {OpenDetailPopup && (
        <TaskAndIssueDetailsComponent
          TaskAndIssueID={taskAndIssueIDForDetails}
          onOpen={OpenDetailPopup}
          onClose={() => {
            setOpenDetailPopup(false);
            setTaskAndIssueIDForDetails(0);
          }}
        />
      )}
    </>
  );
};

export default TaskAndIssueTracking;
