import React, { useState, useEffect } from "react";
import api from "../../Utils/api";
import { Form, Col, InputGroup, Modal } from "react-bootstrap";
import AreaComponent from "../Area/AreaComponent";
import Select from "react-select";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";

export default function AreaDDComponent({
  onSelectArea,
  selectedArea,
  onAdditionalChange,
  ValidationError,
}) {
  const [areaOptions, setAreaOptions] = useState([]);
  const [isAreaModalOpen, setIsAreaModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [validation, setValidation] = useState(false);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getAreaName();
    CheckRoleTemplatePermissions("Area")
      .then((obj) => {
        setRoleTemplatePermissions(obj);
      })
      .catch((error) => null);
  }, []);

  useEffect(() => {
    if (ValidationError && selectedArea === 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  }, [ValidationError, selectedArea]);

  useEffect(() => {
    // Set initial value for the dropdown during edit time
    if (selectedArea) {
      const selectedOption = areaOptions.find(
        (option) => option.areaID === selectedArea
      );
      setSelectedOption({
        value: selectedOption?.areaID,
        label: selectedOption?.areaName,
      });
      setValidation(false);
    }
  }, [selectedArea, areaOptions]);

  // Fetch all Area Name
  const getAreaName = () => {
    api
      .get("Area/AllArea")
      .then((result) => {
        setAreaOptions(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAreaChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onSelectArea(selectedOption ? selectedOption.value : null);

    if (onAdditionalChange) {
      onAdditionalChange({ onAdditionalChange });
    }
  };

  // Insert New Area
  const areaModalOpen = () => {
    setIsAreaModalOpen(true);
  };

  const closeAreaModal = () => {
    setIsAreaModalOpen(false);
  };

  const selectDDAreaID = (areaID) => {
    handleAreaChange({ value: areaID });
    getAreaName();
    closeAreaModal();
  };

  return (
    <>
      <Col md={4} sm={4} lg={4}>
        <Form.Group controlId="area_id">
          <Form.Label>Area Name:</Form.Label>
          <div className="mb-3 d-flex justify-content-between">
            <div className="selectStartIcon input-group-text" id="basic-addon1">
              <i className="fas fa-building"></i>
            </div>
            <Select
              className={`w-100 ${validation ? "borderRed" : ""} ${
                RoleTemplatePermissions && RoleTemplatePermissions.addPermission
                  ? "RadiusNo"
                  : "RadiusYes"
              }`}
              options={areaOptions.map((option) => ({
                value: option.areaID,
                label: option.areaName,
              }))}
              value={selectedOption}
              onChange={handleAreaChange}
              placeholder="Select Area Name"
              required
            />
            {RoleTemplatePermissions &&
              RoleTemplatePermissions.addPermission && (
                <div className="selectEndIcon input-group-text">
                  <i
                    className="fa fa-plus plusIcon"
                    onClick={areaModalOpen}
                  ></i>
                </div>
              )}
          </div>
          {validation && (
            <div className="ValidationErrorMsg">Please select an area.</div>
          )}
        </Form.Group>
      </Col>
      {/* Area Modal */}
      <Modal
        show={isAreaModalOpen}
        onHide={closeAreaModal}
        size="xl"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton closeVariant={"white"}>
          <Modal.Title id="example-custom-modal-styling-title">
            New Area
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <AreaComponent areaID={selectDDAreaID}></AreaComponent>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
}
