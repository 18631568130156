import React, { useState, useEffect, useRef } from "react";
import api, { handleApiError } from "../../Utils/api";
import {
  Form,
  Modal,
  Button,
  Col,
  Row,
  InputGroup,
  Container,
  CloseButton,
  Card,
} from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AreaDDComponent from "../Area/AreaDDComponent";
import { convertDateFormat } from "../../Utils/commonFunction";
import Swal from "sweetalert2";
import Select from "react-select";
import "./ManageClientLeadPopup.css";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";
import TaskAndIssueComponent from "../TaskAndIssue/TaskAndIssueComponent";
import Pipeline from "../Pipeline/Pipeline";
import Loader from "../../Components/Common/Loader ";

const emptyClient = {
  Client_id: 0,
  Client_Name: "",
  Client_MobileNumber: "",
  Client_Address: "",
  Client_EmailID: "",
  area_id: 0,
  type: "",
};

//Notes
const emptyNote = {
  Note_id: 0,
  Notes: "",
  client_id: 0,
};

//Pipeline
const emptyPipeline = {
  Opportunity_id: 0,
  Client_id: 0,
  Pipeline_id: 0,
  PipelineStage_id: 0,
};

const emptyCalender = {
  CalenderData_id: 0,
  CalendarHead_id: 0,
  Client_id: 0,
  Reminder_DateTime: "",
  Remarks: "",
};

export default function ManageClientLeadPopup({ clientID, onClose }) {
  // CommonCode
  const [isLoading, setIsLoading] = useState(false);

  //Client Code
  const [clientRefreshKey, setClientRefreshKey] = useState(0);
  const [clientValidated, setClientValidated] = useState(false);
  const [clientformData, setClientformData] = useState(emptyClient);
  const ClientFormRef = useRef(null);
  const [clientType, setClientType] = useState("");
  const [ClientRoleTemplatePermissions, setClientRoleTemplatePermissions] =
    useState("");

  useEffect(() => {
    setIsLoading(true);
    getSingleClient();
    getNotsOfClient();
    getClientWisePipelines();
    CheckRoleTemplatePermissions("Client")
      .then((obj) => {
        setClientRoleTemplatePermissions(obj);
      })
      .catch((error) => null)
      .finally(() => {
        setIsLoading(false);
      });
  }, [clientID]);

  const getSingleClient = () => {
    api
      .get(`Client/SingleClient?ClientID=${clientID}`)
      .then(({ data }) => {
        let clientData = data.data;
        setClientformData({
          Client_id: clientData.clientID,
          Client_Name: clientData.clientName,
          Client_MobileNumber: clientData.clientMobileNumber,
          Client_Address: clientData.address,
          Client_EmailID: clientData.emailID,
          area_id: clientData.areaID,
          type: clientData.type,
        });
        setClientType(clientData.type || "");
      })
      .catch(handleApiError);
  };

  const updateClient = (event) => {
    setIsLoading(true);
    if (clientformData.area_id === 0) {
      toast.error("Please select an area");
      return;
    }
    if (!clientformData.type) {
      toast.error("Please select a client type as Lead or Client");
      return;
    }
    setClientValidated(true);
    event.preventDefault();

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }

    const data = {
      clientID: clientformData?.Client_id || 0,
      clientName: clientformData.Client_Name,
      clientMobileNumber: clientformData.Client_MobileNumber,
      address: clientformData.Client_Address,
      emailID: clientformData.Client_EmailID,
      areaID: clientformData.area_id,
      type: clientformData.type,
    };

    const endpoint = "Client/ClientLeadUpdate";

    api
      .patch(endpoint, data)
      .then((result) => {
        if (result.status === 200) {
          toast.success(result.data.message);
          getSingleClient();
        } else {
          toast.error(result.data.message);
        }
        setClientRefreshKey((prevKey) => prevKey + 1);
      })
      .catch(handleApiError)
      .finally(() => {
        setIsLoading(false);
        setClientValidated(false);
      });
  };

  const handleRadioChange = (event) => {
    setClientType(event.target.value);
    clientformData.type = event.target.value;
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setClientformData((prevData) => ({ ...prevData, [name]: value }));
  };

  //Note Code

  const [noteValidated, setNoteValidated] = useState(false);
  const [noteFormData, setNoteFormData] = useState(emptyNote);
  const [notesData, setNoteData] = useState([]);
  const NoteFormRef = useRef(null);
  const [NoteRoleTemplatePermissions, setNoteRoleTemplatePermissions] =
    useState("");

  const insertAndUpDate = (event) => {
    setIsLoading(true);
    setNoteValidated(true);
    if (event) {
      event.preventDefault();
    }
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const data = {
        notesID: noteFormData?.Note_id || 0,
        clientID: clientID,
        Notes: noteFormData.Notes,
      };

      const endpoint =
        noteFormData.Note_id === 0 ? "Notes/InsertNotes" : "Notes/EditNotes";

      api[noteFormData.Note_id === 0 ? "post" : "patch"](endpoint, data)
        .then((result) => {
          if (result.status === 200) {
            toast.success(result.data.message);
            getNotsOfClient();
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred");
        })
        .finally(() => {
          setIsLoading(false);
          setNoteFormData(emptyNote);
          setNoteValidated(false); // Reset validation state
        });
    }
  };

  const handleInputChangeInNote = (event) => {
    const { name, value } = event.target;
    setNoteFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const getNotsOfClient = () => {
    api
      .get(`Notes/AllNotesClientWise?ClientID=${clientID}`)
      .then(({ data }) => setNoteData(data.data))
      .catch(handleApiError);
  };

  const getSingleClientNote = (id) => {
    api
      .get(`Notes/SingleNotes?NotesID=${id}`)
      .then(({ data }) => {
        let NoteData = data.data;
        setNoteFormData({
          Note_id: NoteData.notesID,
          Notes: NoteData.notes,
          client_id: clientID,
        });
      })
      .catch(handleApiError);
  };

  const notifySuccess = (message) => toast.success(message);
  const deleteClientNote = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`Notes/DeleteNotes?NotesID=${id}`)
          .then(({ status }) => {
            if (status === 200) {
              notifySuccess("Your record has been deleted.");
              getNotsOfClient();
            }
          })
          .catch(handleApiError);
      }
    });
  };

  //Pipeline Code Start Here
  const [pipelineFormData, setPipelineFormData] = useState(emptyPipeline);
  const [pipelineValidated, setPipelineValidated] = useState(false);
  const PipelineFormRef = useRef(null);
  const [pipelineData, setPipelineData] = useState([]);
  const [PipelineOptions, setPipelineOptions] = useState([]);
  const [pipelineStageOptions, setPipelineStageOptions] = useState([]);
  const [selectedPipeline, setSelectedPipeline] = useState(null); // New state for selected pipeline
  const [selectedPipelineStage, setSelectedPipelineStage] = useState(null); // New state for selected pipeline stage
  const [PipelineRoleTemplatePermissions, setPipelineRoleTemplatePermissions] =
    useState("");
  const [showPipelines, setShowPipelines] = useState(false);

  const HideAndShowPipelines = () => {
    setShowPipelines(!showPipelines);
  };

  useEffect(() => {
    setIsLoading(true);
    getPipelineName();
    CheckRoleTemplatePermissions("Opportunity")
      .then((obj) => {
        setPipelineRoleTemplatePermissions(obj);
      })
      .catch((error) => null)
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleInputChangeInOpportunity = (selectedOption) => {
    setPipelineFormData((prevData) => ({
      ...prevData,
      PipelineStage_id: selectedOption.value,
    }));
  };

  const getPipelineName = () => {
    api
      .get("Pipeline/AllPipeline")
      .then(({ data }) => setPipelineOptions(data.data))
      .catch(handleApiError);
  };

  const insertAndUpDatePipeline = (event) => {
    setIsLoading(true);
    if (pipelineFormData.PipelineStage_id === 0) {
      toast.error("Please select Pipelines");
      return;
    }
    console.log(pipelineFormData);
    setPipelineValidated(true);
    if (event) {
      event.preventDefault();
    }
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const data = {
        OpportunityID: pipelineFormData?.Opportunity_id || 0,
        ClientID: clientID,
        PipelineStageID: pipelineFormData.PipelineStage_id,
        IsActive: true,
      };
      const endpoint =
        pipelineFormData.Opportunity_id === 0
          ? "Opportunity/InsertOpportunity"
          : "Opportunity/EditPipelineIsActive";

      api[pipelineFormData.Opportunity_id === 0 ? "post" : "patch"](
        endpoint,
        data
      )
        .then((result) => {
          if (result.status === 200) {
            toast.success(result.data.message);
            getClientWisePipelines();
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred");
        })
        .finally(() => {
          HideAndShowPipelines();
          ClearPipelineData();
          setPipelineValidated(false); // Reset validation state
          setIsLoading(false);
        });
    }
  };

  const ClearPipelineData = () => {
    HideAndShowPipelines();
    setPipelineFormData(emptyPipeline);
    setSelectedPipelineStage(null);
    setSelectedPipeline(null);
  };

  const getClientWisePipelines = () => {
    api
      .get(`Opportunity/AllOpportunityClientWise?ClientID=${clientID}`)
      .then(({ data }) => setPipelineData(data.data))
      .catch(handleApiError);
  };

  const getPipelineWiseStageName = (id) => {
    api
      .get(`Opportunity/PipelineWisePipelineStageName?PipelineID=${id}`)
      .then(({ data }) => setPipelineStageOptions(data.data))
      .catch(handleApiError);
  };

  const getSingleClientPipeline = (id) => {
    api
      .get(`Opportunity/ClientWiseSingleOpportunity?OpportunityID=${id}`)
      .then(({ data }) => {
        let OppData = data.data;
        HideAndShowPipelines();
        setPipelineFormData({
          Opportunity_id: OppData.opportunityID,
          Client_id: clientID,
          Pipeline_id: OppData.pipelineID,
          PipelineStage_id: OppData.pipelineStageID,
        });
        setSelectedPipeline({
          value: OppData.pipelineID,
          label: OppData.pipelineName,
        });
        getPipelineWiseStageName(OppData.pipelineID);
        setSelectedPipelineStage({
          value: OppData.pipelineStageID,
          label: OppData.pipelineStageName,
        });
      })
      .catch(handleApiError);
  };

  const deleteClientPipeline = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`Opportunity/DeleteOpportunity?OpportunityID=${id}`)
          .then(({ status }) => {
            if (status === 200) {
              notifySuccess("Your record has been deleted.");
              getClientWisePipelines();
            }
          })
          .catch(handleApiError);
      }
    });
  };

  // const EditPipelineIsActive = (id) => {
  //
  //   api
  //     .patch(`Opportunity/EditPipelineIsActive?opportunityID=${id}`)
  //     .then(({ data }) => toast.success(data.message))
  //     .catch(handleApiError);
  // };

  // Pipeline Code End Here

  //Calender code start Here

  const [clientWiseCalenderData, setClientWiseCalenderData] = useState([]);
  const [calenderFormData, setCalenderFormData] = useState(emptyCalender);
  const [calendarHeadOptions, setCalendarHeadOptions] = useState([]);
  const [showCalender, setShowCalender] = useState(false);
  const [calenderValidated, setCalenderValidated] = useState(false);
  const [selectedCalenderHead, setSelectedCalenderHead] = useState(null);
  const [CalenderRoleTemplatePermissions, setCalenderRoleTemplatePermissions] =
    useState("");
  const CalenderFormRef = useRef(null);

  useEffect(() => {
  
    GetClientWiseCalenderData();
    getCalendarHeadName();
    CheckRoleTemplatePermissions("CalendarData")
      .then((obj) => {
        setIsLoading(true)
        setCalenderRoleTemplatePermissions(obj);
      })
      .catch((error) => null)
      .finally(()=>{
        setIsLoading(false);
      })
  }, [clientID]);

  const HideAndShowCalender = () => {
    setShowCalender(!showCalender);
  };

  const ClearCalenderData = () => {
    HideAndShowCalender();
    setCalenderFormData(emptyCalender);
    setSelectedCalenderHead(null);
  };

  const GetClientWiseCalenderData = () => {
    api
      .get(`CalendarData/ClientWiseCalenderData?clientID=${clientID}`)
      .then(({ data }) => {
        // if (data.data !== null) {
        setClientWiseCalenderData(data.data);
        // }
      })
      .catch(handleApiError);
  };

  const getCalendarHeadName = () => {
    api
      .get("CalendarHead/AllCalendarHead")
      .then(({ data }) => setCalendarHeadOptions(data.data))
      .catch(handleApiError);
  };
  const handleInputChangeCalender = (event) => {
    if (event && event.target) {
      const { name, value } = event.target;
      setCalenderFormData((prevData) => ({ ...prevData, [name]: value }));
    } else if (event && event.value) {
      // Assuming event.value is available for the Select component
      setSelectedCalenderHead(event);
      setCalenderFormData((prevData) => ({
        ...prevData,
        CalendarHead_id: event.value,
      }));
    }
  };

  const insertAndUpDateCalender = (event) => {
    setIsLoading(true)
    if (calenderFormData.CalendarHead_id === 0) {
      toast.error("Please select CalendarHead");
      return;
    }

    setCalenderValidated(true);
    if (event) {
      event.preventDefault();
    }
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const data = {
        CalendarDataID: calenderFormData?.CalenderData_id || 0,
        ClientID: clientID,
        CalendarHeadID: calenderFormData.CalendarHead_id,
        ReminderDateTime: calenderFormData.Reminder_DateTime,
        IsActive: true,
        Remarks: calenderFormData.Remarks,
      };
      const endpoint =
        calenderFormData.CalenderData_id === 0
          ? "CalendarData/InsertCalendarData"
          : "CalendarData/EditCalendarData";
      api[calenderFormData.CalenderData_id === 0 ? "post" : "patch"](
        endpoint,
        data
      )
        .then((result) => {
          if (result.status === 200) {
            toast.success(result.data.message);
            GetClientWiseCalenderData();
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred");
        })
        .finally(()=>{
          ClearCalenderData();
          setCalenderValidated(false); // Reset validation state
          setIsLoading(false)
        })
     
    }
  };

  const getSingleClientCalenderData = (id) => {
    api
      .get(`CalendarData/SingleCalendarData?CalendarDataID=${id}`)
      .then(({ data }) => {
        let OppData = data.data;
        HideAndShowCalender();
        setCalenderFormData({
          CalenderData_id: OppData.calendarDataID,
          Client_id: clientID,
          CalendarHead_id: OppData.calendarHeadID,
          Reminder_DateTime: OppData.reminderDateTime,
          Remarks: OppData.remarks,
        });
        setSelectedCalenderHead({
          value: OppData.calendarHeadID,
          label: OppData.calendarHeadName,
        });
      })
      .catch(handleApiError);
  };

  const deleteClientCalender = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`CalendarData/DeleteCalendarData?CalendarDataID=${id}`)
          .then(({ status }) => {
            if (status === 200) {
              notifySuccess("Your record has been deleted.");
              GetClientWiseCalenderData();
            }
          })
          .catch(handleApiError);
      }
    });
  };

  const PendingComplateCalender = (id) => {
    setIsLoading(true)
    api
      .patch(
        `CalendarData/EditCalendarDataComplatePending?CalenderDataID=${id}`
      )
      .then(({ data }) => {
        toast.success(data.message);
        GetClientWiseCalenderData(); // Move this function call inside the 'then' block
      })
      .catch(handleApiError)
      .finally(()=>{
        setIsLoading(false)
      })
  };

  //TaskAndIssue Start Here
  const [clientWiseTaskAndIssueData, setClientWiseTaskAndIssueData] = useState(
    []
  );
  const [isTaskAndIssueModalOpen, setIsTaskAndIssueModalOpen] = useState(false);
  const [
    TaskAndIssueRoleTemplatePermissions,
    setTaskAndIssueRoleTemplatePermissions,
  ] = useState("");

  useEffect(() => {
    setIsLoading(true)
    GetClientWiseTaskAndIssue();
    CheckRoleTemplatePermissions("TaskAndIssue")
      .then((obj) => {
        setTaskAndIssueRoleTemplatePermissions(obj);
      })
      .catch((error) => null)
      .finally(()=>{
        setIsLoading(false)
      })
  }, [clientID]);

  const GetClientWiseTaskAndIssue = () => {
    api
      .get(`TaskAndIssue/ClientWiseTaskAndIssue?clientID=${clientID}`)
      .then(({ data }) => {
        if (data.data !== null) {
          setClientWiseTaskAndIssueData(data.data);
        }
      })
      .catch(handleApiError);
  };

  return (
    <Modal
      show={true}
      onHide={onClose}
      fullscreen={true}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
    >
      {isLoading && <Loader/>}
      <Modal.Header closeButton closeVariant={"white"}>
        <Modal.Title id="example-custom-modal-styling-title">
          Client Detail: {clientformData.Client_Name} ({" "}
          {clientformData.Client_MobileNumber})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          className="BoxWithShadow"
          noValidate
          clientValidated={clientValidated}
          onSubmit={(e) => {
            if (e) {
              e.preventDefault();
            }

            if (ClientFormRef.current && e) {
              if (e.target.name === "ClientFormAtPopup") {
                updateClient(e);
              }
            }
          }}
          ref={ClientFormRef}
          name="ClientFormAtPopup"
        >
          <Row>
            <Col md={4} sm={4} lg={4}>
              <Form.Group controlId="Client_Name">
                <Form.Label>Client Name:</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <i class="fa fa-user"></i>
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    name="Client_Name"
                    placeholder="Enter Client Name"
                    value={clientformData.Client_Name}
                    onChange={handleInputChange}
                    required
                  />
                  {clientValidated && !clientformData.Client_Name && (
                    <div className="invalid-feedback">
                      Please Enter Client Name.
                    </div>
                  )}
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={4} sm={2} lg={4}>
              <Form.Group controlId="Client_MobileNumber">
                <Form.Label>Client Mobile No:</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <i class="fas fa-mobile"></i>
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    name="Client_MobileNumber"
                    placeholder="Enter MobileNumber"
                    value={clientformData.Client_MobileNumber}
                    onChange={handleInputChange}
                    required
                  />
                  {clientValidated && !clientformData.Client_MobileNumber && (
                    <div className="invalid-feedback">
                      Please Enter MobileNumber Name.
                    </div>
                  )}
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={4} sm={2} lg={4}>
              <Form.Group controlId="Client_Address">
                <Form.Label>Address:</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <i className="fa fa-map-marker"></i>
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    name="Client_Address"
                    placeholder="Enter Address"
                    value={clientformData.Client_Address}
                    onChange={handleInputChange}
                    required
                  />
                  {clientValidated && !clientformData.Client_Address && (
                    <div className="invalid-feedback">
                      Please Enter Address.
                    </div>
                  )}
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4} sm={4} lg={4}>
              <Form.Group controlId="Client_EmailID">
                <Form.Label>EmailID:</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <i class="fa fa-envelope"></i>
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    name="Client_EmailID"
                    placeholder="Enter EmailID"
                    value={clientformData.Client_EmailID}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <AreaDDComponent
              onSelectArea={(areaID) => {
                setClientformData((prevData) => ({
                  ...prevData,
                  area_id: areaID,
                }));
                // Reset validation error when a city is selected
              }}
              selectedArea={clientformData.area_id}
              key={clientRefreshKey}
            />
            <Col md={2} sm={2} lg={2} className="d-flex align-items-center">
              {["Lead", "Client"].map((type) => (
                <div key={`inline-radio-${type}`}>
                  <Form.Check
                    label={type.charAt(0).toUpperCase() + type.slice(1)}
                    name="clientType"
                    type="radio"
                    id={`inline-radio-${type}`}
                    value={type}
                    checked={clientformData.type === type}
                    onChange={handleRadioChange}
                    style={{ paddingRight: "1.2em" }}
                  />
                </div>
              ))}
            </Col>
            {ClientRoleTemplatePermissions &&
              ClientRoleTemplatePermissions.editPermission && (
                <Col md={2} sm={2} lg={2} className="d-flex align-items-center">
                  <Button type="submit" variant="primary">
                    Update
                  </Button>
                </Col>
              )}
          </Row>
        </Form>

        <Row>
          {CalenderRoleTemplatePermissions &&
            CalenderRoleTemplatePermissions.viewPermission && (
              <Col className="mt-3">
                <Card.Body className="BoxWithShadow p-0">
                  <Card.Title className="borderAtTitleBottom text-center p-3 ActivityTitle">
                    Calendar{" "}
                    {CalenderRoleTemplatePermissions &&
                      CalenderRoleTemplatePermissions.addPermission && (
                        <i
                          className="fa fa-plus rotate-icon"
                          onClick={HideAndShowCalender}
                        ></i>
                      )}
                  </Card.Title>
                  <div className={`${showCalender ? "" : "d-none"}`}>
                    <Form
                      className="p-3"
                      noValidate
                      Validated={clientValidated}
                      onSubmit={(e) => {
                        if (e) {
                          e.preventDefault();
                        }

                        if (CalenderFormRef.current && e) {
                          if (e.target.name === "calenderForm") {
                            insertAndUpDateCalender(e);
                          }
                        }
                      }}
                      ref={CalenderFormRef}
                      name="calenderForm"
                    >
                      <Form.Group controlId="reminderDateTime">
                        <InputGroup className="mb-3">
                          <Form.Control
                            type="datetime-local"
                            name="Reminder_DateTime"
                            value={calenderFormData.Reminder_DateTime}
                            onChange={handleInputChangeCalender}
                            isInvalid={
                              calenderValidated &&
                              !calenderFormData.Reminder_DateTime
                            }
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Reminder DateTime Details.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>

                      <Select
                        className=""
                        options={calendarHeadOptions.map((option) => ({
                          value: option.calendarHeadID,
                          label: option.calendarHeadName,
                        }))}
                        value={selectedCalenderHead}
                        onChange={(selectedOption) => {
                          setSelectedCalenderHead(selectedOption);
                          handleInputChangeCalender(selectedOption);
                        }}
                        placeholder="Select Calendar Head"
                        required
                      />

                      <Form.Group controlId="Remarks">
                        <InputGroup className="mb-3 mt-3">
                          <Form.Control
                            as="textarea"
                            rows={4}
                            name="Remarks"
                            placeholder="Remarks"
                            value={calenderFormData.Remarks}
                            onChange={handleInputChangeCalender}
                            isInvalid={
                              calenderValidated && !calenderFormData.Remarks
                            }
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Remarks
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                      <Row className="d-flex ">
                        <div className="margin_top10 d-flex justify-content-between">
                          <Button
                            variant="primary"
                            className=" text-start calenderButton"
                            onClick={ClearCalenderData}
                          >
                            Clear
                          </Button>

                          <Button
                            type="submit"
                            variant="primary"
                            className="calenderButton"
                          >
                            {calenderFormData?.CalenderData_id > 0
                              ? "Update"
                              : "Save"}
                          </Button>
                        </div>
                      </Row>
                    </Form>
                  </div>
                  <div
                    className={`${
                      showCalender
                        ? "CalenderContainer"
                        : "CalenderContainerOFF"
                    }`}
                  >
                    {clientWiseCalenderData.map((item) => (
                      <div
                        className="calenderDetailDiv"
                        key={item.calendarDataID}
                      >
                        <div className="d-flex justify-content-between">
                          <div className="font-weight-bold text-start">
                            {convertDateFormat(item.reminderDateTime)}
                          </div>
                          <div className="text-end">
                            <Button
                              variant="outline-success"
                              onClick={() =>
                                item.isActive
                                  ? PendingComplateCalender(item.calendarDataID)
                                  : null
                              }
                            >
                              {item.isActive ? (
                                <i class="fa fa-hourglass-half"></i>
                              ) : (
                                <i class="fa fa-thumbs-up fa-beat"></i>
                              )}
                            </Button>
                          </div>
                        </div>

                        <Row>
                          {" "}
                          <div className="margin_top10 d-flex justify-content-between dateDive">
                            <div className="font-weight-bold text-start">
                              {item.remarks}
                            </div>

                            <div className="font-weight-bold text-end">
                              {item.isActive &&
                                CalenderRoleTemplatePermissions.editPermission && (
                                  <ion-icon
                                    name="create-outline"
                                    class="ManageClientEditDelIcon editIcon"
                                    onClick={() =>
                                      getSingleClientCalenderData(
                                        item.calendarDataID
                                      )
                                    }
                                  ></ion-icon>
                                )}

                              {item.isActive &&
                                CalenderRoleTemplatePermissions.deletePermission && (
                                  <ion-icon
                                    name="trash-outline"
                                    class="ManageClientEditDelIcon deleteIcon"
                                    onClick={() =>
                                      deleteClientCalender(item.calendarDataID)
                                    }
                                  ></ion-icon>
                                )}
                            </div>
                          </div>
                        </Row>
                      </div>
                    ))}
                  </div>
                </Card.Body>
              </Col>
            )}

          {/* pipeline Component */}

          {PipelineRoleTemplatePermissions &&
            PipelineRoleTemplatePermissions.viewPermission && (
              <Col className="mt-3">
                <Card.Body className="BoxWithShadow p-0">
                  <Card.Title className="borderAtTitleBottom text-center p-3 PipelineTitle">
                    Pipeline{"   "}
                    {PipelineRoleTemplatePermissions &&
                      PipelineRoleTemplatePermissions.addPermission && (
                        <i
                          className="fa fa-plus rotate-icon"
                          onClick={HideAndShowPipelines}
                        ></i>
                      )}
                  </Card.Title>
                  <div className={`${showPipelines ? "" : "d-none"}`}>
                    <Form
                      className="p-3"
                      noValidate
                      Validated={pipelineValidated}
                      onSubmit={(e) => {
                        if (e) {
                          e.preventDefault();
                        }

                        if (PipelineFormRef.current && e) {
                          if (e.target.name === "PipelineForm") {
                            insertAndUpDatePipeline(e);
                          }
                        }
                      }}
                      ref={PipelineFormRef}
                      name="PipelineForm"
                    >
                      <div>
                        <Select
                          options={PipelineOptions.map((option) => ({
                            value: option.pipelineID,
                            label: option.pipelineName,
                          }))}
                          value={selectedPipeline}
                          onChange={(selectedOption) => {
                            setSelectedPipeline(selectedOption);
                            getPipelineWiseStageName(selectedOption.value);
                            handleInputChangeInOpportunity(selectedOption);
                          }}
                          placeholder="Select Pipeline"
                          required
                        />
                      </div>
                      <div className="mt-3">
                        <Select
                          options={pipelineStageOptions.map((option) => ({
                            value: option.pipelineStageID,
                            label: option.pipelineStageName,
                          }))}
                          value={selectedPipelineStage}
                          onChange={(selectedOption) => {
                            setSelectedPipelineStage(selectedOption);
                            handleInputChangeInOpportunity(selectedOption);
                          }}
                          placeholder="Select Pipeline Stage"
                          required
                        />
                      </div>
                      <Row className="d-flex ">
                        <div className="margin_top10 d-flex justify-content-between">
                          <Button
                            variant="primary"
                            className=" text-start pipelineButton"
                            onClick={ClearPipelineData}
                          >
                            Clear
                          </Button>

                          <Button
                            type="submit"
                            variant="primary"
                            className="pipelineButton"
                          >
                            {pipelineFormData?.Opportunity_id > 0
                              ? "Update"
                              : "Save"}
                          </Button>
                        </div>
                      </Row>
                    </Form>
                  </div>
                  <div
                    className={`${
                      showPipelines
                        ? "PipelineContainer"
                        : "PipelineContainerOFF"
                    }`}
                  >
                    {pipelineData.map((item) => (
                      <div
                        className="pipelineDetailDiv"
                        key={item.opportunityID}
                      >
                        <div className="my-2 d-flex justify-content-between dateDive">
                          <div className="font-weight-bold">
                            {item.pipelineName}{" "}
                            <ion-icon name="arrow-forward-outline"></ion-icon>
                          </div>
                          <div className="font-weight-bold">
                            {item.pipelineStageName}
                          </div>
                          {PipelineRoleTemplatePermissions &&
                            PipelineRoleTemplatePermissions.editPermission && (
                              <div className="font-weight-bold ">
                                {" "}
                                {item.isActive && (
                                  <ion-icon
                                    name="create-outline"
                                    class="ManageClientEditDelIcon editIcon"
                                    onClick={() =>
                                      getSingleClientPipeline(
                                        item.opportunityID
                                      )
                                    }
                                  ></ion-icon>
                                )}
                                {/* <ion-icon
                          name="trash-outline"
                          class="ManageClientEditDelIcon deleteIcon"
                          onClick={() =>
                            deleteClientPipeline(item.opportunityID)
                          }
                        ></ion-icon> */}
                              </div>
                            )}
                        </div>
                      </div>
                    ))}
                  </div>
                </Card.Body>
              </Col>
            )}

          {/* Task&Issue Component */}

          {TaskAndIssueRoleTemplatePermissions &&
            TaskAndIssueRoleTemplatePermissions.viewPermission && (
              <Col className="mt-3">
                <Card.Body className="BoxWithShadow p-0">
                  <Card.Title className="borderAtTitleBottom text-center p-3 AppointmentTitle">
                    Task & Issue{" "}
                    {TaskAndIssueRoleTemplatePermissions &&
                      TaskAndIssueRoleTemplatePermissions.addPermission && (
                        <i
                          className="fa fa-plus rotate-icon"
                          onClick={() => setIsTaskAndIssueModalOpen(true)}
                        ></i>
                      )}
                  </Card.Title>
                  <div className="TaskAndIssueContainer">
                    {clientWiseTaskAndIssueData.map((item) => (
                      <div
                        className="TaskAndIssuediv"
                        key={item.taskAndIssueID}
                      >
                        <div>
                          {" "}
                          <span className="fw-bold">Subject:-</span>{" "}
                          {item.taskAndIssueSubject}
                        </div>
                        <div>{item.taskAndIssueDetail}</div>
                        <div className="margin_top10 d-flex justify-content-between dateDive">
                          <div className="font-weight-bold">
                            <i class="fa fa-calendar"></i>{" "}
                            {convertDateFormat(item.expectedResolveDate)}
                          </div>
                          <div className="font-weight-bold">
                            <i class="fa fa-thumb-tack" aria-hidden="true"></i>{" "}
                            {item.ticketNo}
                          </div>
                          <div className="font-weight-bold ">
                            <i class="fa fa-user"></i> {item.developerName}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Card.Body>
              </Col>
            )}
          <Modal
            show={isTaskAndIssueModalOpen}
            onHide={() => setIsTaskAndIssueModalOpen(false)}
            size="xl"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton closeVariant={"white"}>
              <Modal.Title id="example-custom-modal-styling-title">
                New Client
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                <TaskAndIssueComponent
                  ClientID={clientID}
                  TaskAndIssueID={() => {
                    setIsTaskAndIssueModalOpen(false);
                    GetClientWiseTaskAndIssue();
                  }}
                />
              </Form.Group>
            </Modal.Body>
          </Modal>
          {/* Notes Component */}

          <Col className="mt-3">
            <Card.Body className="BoxWithShadow p-0">
              <Card.Title className="borderAtTitleBottom text-center p-3 NotesTitle">
                Notes
              </Card.Title>
              <Form
                className="p-3"
                noValidate
                Validated={noteValidated}
                onSubmit={(e) => {
                  if (e) {
                    e.preventDefault();
                  }

                  if (NoteFormRef.current && e) {
                    // Check for the formName to call insertCity
                    if (e.target.name === "NoteForm") {
                      insertAndUpDate(e);
                    }
                  }
                }}
                ref={NoteFormRef}
                name="NoteForm"
              >
                <Row>
                  <Form.Group controlId="Notes">
                    <Form.Control
                      as="textarea"
                      rows={4}
                      name="Notes"
                      placeholder="Enter Note"
                      value={noteFormData.Notes}
                      onChange={handleInputChangeInNote}
                      isInvalid={noteValidated && !noteFormData.Notes}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Notes Details.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="d-flex ">
                  <div className="margin_top10 d-flex justify-content-between">
                    <Button
                      variant="primary"
                      className=" text-start notesButton"
                      onClick={() =>
                        setNoteFormData({ ...noteFormData, Notes: "" })
                      }
                    >
                      Clear
                    </Button>

                    <Button
                      type="submit"
                      variant="primary"
                      className="notesButton"
                    >
                      {noteFormData?.Note_id > 0 ? "Update" : "Save"}
                    </Button>
                  </div>
                </Row>
              </Form>
              <div className="notesContainer">
                {notesData.map((item) => (
                  <div className="notesDetaildiv" key={item.notesID}>
                    <div>{item.notes}</div>
                    <div className="margin_top10 d-flex justify-content-between dateDive">
                      <div className="font-weight-bold">
                        <i class="fa fa-calendar"></i>{" "}
                        {convertDateFormat(item.createdDate)}
                      </div>
                      <div className="font-weight-bold">
                        <i class="fa fa-user"></i> {item.userName}
                      </div>
                      <div className="font-weight-bold ">
                        {" "}
                        <ion-icon
                          name="create-outline"
                          class="ManageClientEditDelIcon editIcon"
                          onClick={() => getSingleClientNote(item.notesID)}
                        ></ion-icon>
                        <ion-icon
                          name="trash-outline"
                          class="ManageClientEditDelIcon deleteIcon"
                          onClick={() => deleteClientNote(item.notesID)}
                        ></ion-icon>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Card.Body>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
