import React, { useEffect, useState } from "react";
import { WhatsAppStatus } from "../../Utils/commonFunction";
import axios from "axios";
import { Button, Image, Card } from "react-bootstrap";

export default function StatusOfWhatsApp() {
  const [Status, setStatus] = useState("");
  const [qrImage, setQrImage] = useState("");
  const [SMSLoginURL, setSMSLoginURL] = useState("");
  const [ButtonDisabled,setButtonDisabled]=useState(false);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await WhatsAppStatus();
      let url = response.data.smsLoginUrl;
      CheckStatus(url);
      setSMSLoginURL(url);
    } catch (error) {
      console.error("Error fetching WhatsApp status:", error);
    }
  };

  const CheckStatus = (LoginURL) => {
    
    axios
      .post(LoginURL)
      .then((response) => {
        
        const WhatsAppActive = response.data.data.status;
        setStatus(WhatsAppActive);
        setQrImage(
          response.data.data.qrimg.replace(
            "http://127.0.0.1:5000",
            "http://whatsapp.taptitechnology.com/nodeproject"
          )
          
        );
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  useEffect(() => {
    
    if (Status != "READY") {
      const intervalId = setInterval(() => {
        CheckStatus(SMSLoginURL);
      }, 4000);

      return () => clearInterval(intervalId); // Clear interval on component unmount
    }
  }, [Status]);

  const connectWhatsapp = () => {
    setButtonDisabled(true);
    
    axios
      .post(SMSLoginURL.replace("get_connection_status", "connect_now"))
      .then((response) => {
         CheckStatus(SMSLoginURL);
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };
  return (
    <>
      <Card className="text-center p-5">
        <Image
          src={qrImage}
          className="img img-responsive"
          style={{ margin: "0 auto" }}
        />
        <div className="mt-5"><strong>{Status}</strong></div>
        {Status !== "READY" && Status !== "QR GENERATED" && (
          <div>
            <Button
              variant="primary"
              size="lg"
              onClick={connectWhatsapp}
              id="btnWhatsapp"
              disabled = {ButtonDisabled}
            >
              Click To Connect Now
            </Button>
          </div>
        )}
      </Card>
    </>
  );
}
