import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api, { handleApiError } from "../../Utils/api";
import { Card } from "react-bootstrap";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import {
  Form,
  Button,
  Col,
  Row,
  InputGroup,
} from "react-bootstrap";
import { getCurrentDate ,convertDateFormat} from "../../Utils/commonFunction";

const DateTimeFilterData = {
  start_date: getCurrentDate(),
  end_date: getCurrentDate(),
  mobileNo: "",
};

export default function SMSHistory() {
  const [SMSHistory, setSMSHistory] = useState([]);
  const [formData, setFormData] = useState(DateTimeFilterData);

  useEffect(() => {
    getSMSHistory();
  }, []);


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const getSMSHistory = () => {
    
    api
      .get(
        `SMSHistory/SMSHistory?START_DATE=${formData.start_date}&END_DATE=${formData.end_date}&MobileNo=${formData.mobileNo}`
      )
      .then(({ data }) => setSMSHistory(data.data))
      .catch(handleApiError);
  };

  const ClearDataAndDD = () => {
    setFormData(DateTimeFilterData);
    getSMSHistory();
  };
  const columnDefs = [
    {
      headerName: "SMSDate",
      field: "smsDate",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      minWidth: 200,
      flex: 1,
      cellRenderer: ({ value }) => convertDateFormat(value),
    },
    {
      headerName: " Number",
      field: "number",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      minWidth: 100,
      flex: 1,
    },
    {
      headerName: "Msg",
      field: "msg",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      minWidth: 400,
      flex: 1,
    },
    {
      headerName: "Response",
      field: "smsRes",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      minWidth: 700,
      flex: 1,
    },
  ];

  return (
    <>
      <ToastContainer />
      <Card>
        <Card.Header>
          <span> SMSHistory</span>
        </Card.Header>
        <Card.Body>
          <Row className="justify-content-center">
            <Col md={3} sm={3} lg={3}>
              <Form.Group controlId="Expected_ResolveDate" className="d-flex">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <i className="fa fa-calendar"></i>
                  </InputGroup.Text>
                  <Form.Control
                    type="date"
                    name="start_date"
                    value={formData.start_date}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={3} sm={3} lg={3}>
              <Form.Group controlId="Expected_ResolveDate" className="d-flex">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <i className="fa fa-calendar"></i>
                  </InputGroup.Text>
                  <Form.Control
                    type="date"
                    name="end_date"
                    value={formData.end_date}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={3} sm={3} lg={3}>
              <Form.Group controlId="Expected_ResolveDate" className="d-flex">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <i className="fa fa-user"></i>
                  </InputGroup.Text>
                  <Form.Control
                    type="number"
                    name="mobileNo"
                    placeholder="Enter MobileNo"
                    value={formData.mobileNo}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </Form.Group>
            </Col>

            <Col md={1} sm={1} lg={1}>
              <div className="d-flex">
              <Button variant="outline-success" onClick={getSMSHistory}>
                Search
              </Button>
              <Button
                onClick={ClearDataAndDD}
                className="mx-2 outline-primaryOfTapti text-start"
              >
                <i className="fa fa-refresh"></i>
              </Button>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <AgGridComponent rowData={SMSHistory} columnDefs={columnDefs} />
        </Card.Body>
      </Card>
    </>
  );
}
