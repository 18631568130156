import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api, { handleApiError } from "../../Utils/api";
import { Form, Button, Col, Row, InputGroup } from "react-bootstrap";

const emptyCharge = {
  Charge_id: 0,
  Charge_Name: "",
  Charge_Rate: 0,
};

export default function ChargeComponent({
  ChargeID,
  editedChargeData,
  getChargeData,
}) {
  const [refreshKey, setRefreshKey] = useState(0);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyCharge);
  const ChargeFormRef = useRef(null);
  const [validationError, setValidationError] = useState(true);

  useEffect(() => {
    if (editedChargeData) {
      setFormData({
        Charge_id: editedChargeData.chargeID,
        Charge_Name: editedChargeData.chargeName,
        Charge_Rate: editedChargeData.rate,
      });
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }
  }, [editedChargeData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const insertOrUpdateCharge = (event) => {
    setValidated(true);
    event.preventDefault();

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }

    const data = {
      chargeID: formData?.Charge_id || 0,
      chargeName: formData.Charge_Name,
      rate: formData.Charge_Rate,
    };

    const endpoint =
      formData.Charge_id === 0 ? "Charge/InsertCharge" : "Charge/EditCharge";

    api[formData.Charge_id === 0 ? "post" : "patch"](endpoint, data)
      .then((result) => {
        setValidationError(true);
        if (result.status === 200) {
          toast.success(result.data.message);
          if (ChargeID) {
            ChargeID(result.data.data);
          }
        } else {
          toast.error(result.data.message);
        }
        setRefreshKey((prevKey) => prevKey + 1);
      })
      .catch(handleApiError);

    resetForm();
    setValidated(false);
  };

  const resetForm = () => {
    setFormData(emptyCharge);
  };

  return (
    <>
      <Form
        noValidate
        validated={validated}
        onSubmit={(e) => {
          if (e) {
            e.preventDefault();
          }

          if (ChargeFormRef.current && e) {
            if (e.target.name === "ChargeForm") {
              insertOrUpdateCharge(e);
            }
          }
        }}
        ref={ChargeFormRef}
        name="ChargeForm"
      >
        <Row>
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="Charge_Name">
              <Form.Label>Charge Name:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i class="fa fa-user"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="Charge_Name"
                  placeholder="Enter Charge Name"
                  value={formData.Charge_Name}
                  onChange={handleInputChange}
                  required
                />
                {validated && !formData.Charge_Name && (
                  <div className="invalid-feedback">
                    Please Enter Charge Name.
                  </div>
                )}
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="Charge_Rate">
              <Form.Label>Rate:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i class="fa fa-inr"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="Charge_Rate"
                  placeholder="Enter Charge Rate"
                  value={formData.Charge_Rate}
                  onChange={handleInputChange}
                  required
                />
                {validated && !formData.Charge_Rate && (
                  <div className="invalid-feedback">
                    Please Enter Charge Rate.
                  </div>
                )}
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <div className="card-footer margin_top10">
          <Button type="submit" variant="primary" className="mb-20px">
            {formData?.Charge_id > 0 ? "Update" : "Save"}
          </Button>
        </div>
      </Form>
    </>
  );
}
