// Form.js
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import api from "../../Utils/api";
import { Card } from "react-bootstrap";
import { Form, Button, Col, Row, InputGroup, Modal } from "react-bootstrap";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";

export default function FormAdd({ insertRecord, hideAgGrid }) {
  const [formData, setFormData] = useState([]);
  const [validated, setValidated] = useState(false);
  const [insertFormData, setInsertFormData] = useState({
    form_id: 0,
    form_group_id: 0,
    form_name: "",
    controller_name: "",
    action_name: "",
  });
  const [agGridHide, setAgGridHide] = useState(hideAgGrid);
  const [options, setOptions] = useState([]);
  const [selectedFormGroup, setSelectedFormGroup] = useState(formData.state_id);
  const [isFormGroupModalOpen, setIsFormGroupModalOpen] = useState(false);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getForm();
    getFormGroupName();
    CheckRoleTemplatePermissions("FormAdd")
      .then((obj) => {
        setRoleTemplatePermissions(obj);
      })
      .catch((error) => null);
  }, []);

  // Fetch all Forms
  const getForm = () => {
    api
      .get("Form/AllForm")
      .then((result) => {
        setFormData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Fetch all Form Group
  const getFormGroupName = () => {
    return new Promise((resolve, reject) => {
      api
        .get("FormGroup/AllFormGroup")
        .then((result) => {
          setOptions(result.data.data);
          resolve(result.data.data); // Resolve with the data
        })
        .catch((error) => {
          console.log(error);
          reject(error); // Reject with the error
        });
    });
  };

  const handleFormGroupChange = (event) => {
    setSelectedFormGroup(event.target.value);
    handleInputChange({
      target: { name: "form_group_id", value: event.target.value },
    });
  };

  const emptyForm = {
    form_id: 0,
    form_group_id: 0,
    form_name: "",
    controller_name: "",
    action_name: "",
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInsertFormData({ ...insertFormData, [name]: value });
  };

  // Insert & Update Data
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const data = {
        formID: insertFormData?.form_id || 0,
        formName: insertFormData.form_name,
        formGroupID: insertFormData.form_group_id,
        controllerName: insertFormData.action_name,
        actionName: insertFormData.controller_name,
      };

      const endpoint =
        insertFormData.form_id === 0 ? "Form/InsertForm" : "Form/EditForm";

      api[insertFormData.form_id === 0 ? "post" : "patch"](endpoint, data)
        .then((result) => {
          getForm();
          setSelectedFormGroup(0);
          insertFormData?.form_id === 0
            ? toast.success(result.data.message)
            : toast.success(result.data.message);
          if (agGridHide !== undefined) {
            insertRecord(result.data.data);
            setAgGridHide(false);
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred");
        });
      resetForm();
      setValidated(false); // Reset validation state
    }
  };

  // Reset form fields
  const resetForm = () => {
    setInsertFormData(emptyForm);
  };

  // Get data of a single Form
  const getSingleForm = (id) => {
    api
      .get(`Form/SingleForm?FormID=${id}`)
      .then((result) => {
        const FormData = result.data.data;
        setInsertFormData({
          form_id: FormData.formID,
          form_name: FormData.formName,
          action_name: FormData.actionName,
          controller_name: FormData.controllerName,
          form_group_id: FormData.formGroupID,
        });
        setSelectedFormGroup(FormData.formGroupID);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Delete Form
  const deleteForm = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`Form/DeleteForm?FormID=${id}`)
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              getForm();
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  };

  // AG Grid Table Column
  const columnDefs = [
    {
      headerName: "Group Name",
      field: "formGroupName",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      flex:1,
      minWidth:200
    },
    {
      headerName: "Form Name",
      field: "formName",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      flex:1,
      minWidth:200
    },
    {
      headerName: "Controller Name",
      field: "controllerName",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      flex:1,
      minWidth:200
    },
    {
      headerName: "Action Name",
      field: "actionName",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      flex:1,
      minWidth:200
    },
    ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
      headerName: "Edit",
      width: 70,
      field: "formID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="create-outline"
          class="iconsFonts editIcon"
          onClick={() => getSingleForm(value)}
        ></ion-icon>
      ),
      pinned:"right"
    },
  ]
  : []),
...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
  ? [
    {
      headerName: "Delete",
      width: 80,
      field: "formID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="trash-outline"
          class="iconsFonts deleteIcon"
          onClick={() => deleteForm(value)}
        ></ion-icon>
      ),
      pinned:"right"
    },
  ]
  : []),
];

  //Insert New State
  const formGroupModalOpen = () => {
    setIsFormGroupModalOpen(true);
  };

  const closeFormGroupModal = () => {
    setIsFormGroupModalOpen(false);
  };

  const [formGroupData, setFormGroupData] = useState({
    formGroup_id: 0,
    formGroup_name: "",
    icon_name: "",
  });

  const [validatedFormGroup, setFormGroupValidated] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const emptyFormGroup = {
    formGroup_id: 0,
    formGroup_name: "",
    icon_name: "",
  };

  const handleInputFormGroup = (event) => {
    const { name, value } = event.target;
    setFormGroupData({ ...formGroupData, [name]: value });
  };

  // Insert Form Group
  const InsertFormGroup = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    setFormGroupValidated(true);

    if (form.checkValidity() === false) {
      setIsFormValid(false);
      return;
    }
    const data = {
      formGroupID: 0,
      formGroupName: formGroupData.formGroup_name,
      faIcon: formGroupData.icon_name,
    };
    api
      .post("FormGroup/InsertFormGroup", data)
      .then((result) => {
        getFormGroupName();
        setValidated(false); // Reset validation state
        formGroupData?.formGroup_id === 0
          ? toast.success(result.data.message)
          : toast.success(result.data.message);
        handleInputChange({
          target: { name: "form_group_id", value: result.data.data },
        });
        setSelectedFormGroup(result.data.data);
        closeFormGroupModal();
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occurred");
      });
    resetFormGroup();
  };

  const resetFormGroup = () => {
    setFormGroupData(emptyFormGroup);
  };

  return (
    <>
      <ToastContainer />
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
      <Card>
        <Card.Header>
          <span>New Form</span>
        </Card.Header>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={(event) => handleSubmit(event, { resetForm })}
          >
            <Row>
              <Col md={4} sm={4} lg={4}>
                <Form.Group controlId="form_group_id">
                  <Form.Label>Form Group Name:</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <i class="fa fa-sun"></i>
                    </InputGroup.Text>
                    <Form.Select
                      aria-label="Select Form Group Name"
                      value={selectedFormGroup}
                      onChange={handleFormGroupChange}
                      required
                    >
                      <option value="">-- Select Form Group Name --</option>
                      {options.map((option) => (
                        <option
                          key={option.formGroupID}
                          value={option.formGroupID}
                        >
                          {option.formGroupName}
                        </option>
                      ))}
                    </Form.Select>
                    <InputGroup.Text>
                      <i
                        class="fa fa-plus plusIcon"
                        onClick={formGroupModalOpen}
                      ></i>
                    </InputGroup.Text>
                    {validated && !insertFormData.form_group_id && (
                      <div className="invalid-feedback">
                        Please Select Form Group Name.
                      </div>
                    )}
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={4} sm={2} lg={4}>
                <Form.Group controlId="form_name">
                  <Form.Label>Form Name:</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <i className="fas fa-sun"></i>
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="form_name"
                      placeholder="Enter Form Name"
                      value={insertFormData.form_name}
                      onChange={handleInputChange}
                      required
                    />
                    {validated && !insertFormData.client_name && (
                      <div className="invalid-feedback">
                        Please Enter Form Name.
                      </div>
                    )}
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={4} sm={2} lg={4}>
                <Form.Group controlId="controller_name">
                  <Form.Label>Controller Name:</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <i className="fas fa-sun"></i>
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="controller_name"
                      placeholder="Enter Controller Name"
                      value={insertFormData.controller_name}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4} sm={2} lg={4}>
                <Form.Group controlId="action_name">
                  <Form.Label>Action Name:</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <i className="fas fa-sun"></i>
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="action_name"
                      placeholder="Enter Action Name"
                      value={insertFormData.action_name}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <div className="card-footer margin_top10">
              <Button type="submit" variant="primary" className="mb-20px">
                {insertFormData?.form_id > 0 ? "Update" : "Save"}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
      )}
      {!agGridHide && (
        <Card>
          <Card.Header>
            <span>Form List</span>
          </Card.Header>
          <Card.Body>
            <AgGridComponent rowData={formData} columnDefs={columnDefs} />
          </Card.Body>
        </Card>
      )}

      <Modal
        show={isFormGroupModalOpen}
        onHide={closeFormGroupModal}
        size="xl"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton closeVariant={'white'} >
          <Modal.Title id="example-custom-modal-styling-title">
            New Form Group
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form
              noValidate
              validated={validatedFormGroup}
              onSubmit={InsertFormGroup}
            >
              <Row>
                <Col md={4} sm={2} lg={4}>
                  <Form.Group controlId="formGroup_name">
                    <Form.Label>Form Group Name:</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <i className="fas fa-snowflake"></i>
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="formGroup_name"
                        placeholder="Enter Form Group Name"
                        value={formGroupData.formGroup_name}
                        onChange={handleInputFormGroup}
                        required
                      />
                      {validatedFormGroup && !formGroupData.formGroup_name && (
                        <div className="invalid-feedback">
                          Please Enter Form Group Name.
                        </div>
                      )}
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col md={4} sm={2} lg={4}>
                  <Form.Group controlId="icon_name">
                    <Form.Label>Icon:</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <i className="fas fa-snowflake"></i>
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        name="icon_name"
                        placeholder="Enter Icon Name"
                        value={formGroupData.icon_name}
                        onChange={handleInputFormGroup}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <div className="card-footer margin_top10">
                <Button type="submit">Submit</Button>
                {isFormValid ? null : (
                  <p>Please fill out all required fields.</p>
                )}
              </div>
            </Form>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
}
