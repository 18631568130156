import React from "react";
import "./Welcome.css";
import { Row } from "react-bootstrap";
import logo from "../../assets/logo.png";

export default function Welcome() {
  return (
    <section className="WelcomeSection">
      <Row className="p-5">
        <div className="col-lg-6 d-flex flex-column justify-content-center pt-4  pt-lg-0 order-2 order-lg-1 aos-init aos-animate">
          <h1 className="fw-bolder" style={{color:"#20336b"}}>WelCome To Tapti Technology</h1>
          <p>
            Tapti Technology is a global hospital software provider company
            highly committed to improving the quality of hospital management.
            For this purpose, Tapti Technology is engaged in the research and
            development for best hospital management across the world.
          </p>
          <h6 style={{color:"#00baf2"}}>Mobile.No: 8000008862</h6>
          <h6 style={{color:"#00baf2"}}>EmailID: sales@taptitechnology.com</h6>
        </div>
        <div className="col-lg-6 order-1 order-lg-2 hero-img aos-init aos-animate text-center ">
          <img src={logo} alt="" className="img-fluid " />
        </div>
      </Row>
    </section>
  );
}
