import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api, { handleApiError } from "../../Utils/api";
import {
  Form,
  Button,
  Col,
  Row,
  InputGroup,
  Card,
  Modal,
} from "react-bootstrap";
import CalendarHeadDDComponent from "../CalendarHead/CalendarHeadDDComponent";
import ClientDDComponent from "../Client/ClientDDComponent";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import { getCurrentDate } from "../../Utils/commonFunction";
import { convertDateFormat } from "../../Utils/commonFunction";
import CalendarDataComponent from "./CalendarDataComponent";

const emptyCalenderFilterData = {
  start_date: getCurrentDate(),
  end_date: getCurrentDate(),
  calenderHead_id: 0,
  client_id: 0,
};

const emptyCalender = {
  CalenderData_id: 0,
  CalendarHead_id: 0,
  Client_id: 0,
  Reminder_DateTime: "",
  Remarks: "",
  IsActive: false,
};

export default function CalenderFilterData() {
  const [refreshKey, setRefreshKey] = useState(0); // Key for refreshing the dropdown
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyCalenderFilterData);
  const fliterformdata = useRef(null);
  const [validationError, setValidationError] = useState(true);
  const [filterData, setFilterData] = useState([]);
  const [showCalendarData, setShowCalendarData] = useState(false);

  const [filterdataeditData, setFilterdataeditData] = useState(emptyCalender);

  const [editCalenderData, setEditCalenderData] = useState(null);

  useEffect(() => {
    GetCalenderData();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const FilterData = (event) => {
    ;

    setValidated(true);
    if (event) {
      event.preventDefault();
    }
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      GetCalenderData();
      setValidated(false); // Reset validation state
    }
  };

  // Reset form fields
  const resetForm = () => {
    setFormData(emptyCalenderFilterData);
  };

  const GetCalenderData = () => {
    ;
    if (formData.start_date != "") {
      const data = {
        START_DATE: formData.start_date,
        END_DATE: formData.end_date,
        CalenderHeadID: formData.calenderHead_id,
      };
      api
        .get(
          `CalendarData/AllCalendarData?START_DATE=${data.START_DATE}&END_DATE=${data.END_DATE}&CalenderHeadID=${data.CalenderHeadID}`
        )
        .then((result) => {
          setValidationError(true);
          if (result.status === 200) {
            setFilterData(result.data.data);
          } else {
            toast.error(result.data.message);
          }
          setRefreshKey(refreshKey + 1);
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred");
        });
    }
  };

  const columnDefs = [
    {
      headerName: "CalendarHead Name",
      field: "calendarHeadName",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      flex: 1,
      minWidth:150
    },
    {
      headerName: "Client Name",
      field: "clientName",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      flex: 1,
      minWidth:300
    },
    {
      headerName: "ReminderDateTime",
      field: "reminderDateTime",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      flex: 1,
      valueFormatter: function (params) {
        return convertDateFormat(params.value);
      },
      minWidth:150
    },

    {
      headerName: "Remarks",
      field: "remarks",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      flex: 1,
      minWidth:200
    },
    {
      headerName: "Edit",
      width: 70,
      field: "calendarDataID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="create-outline"
          class="iconsFonts editIcon"
          onClick={() => {
            getfilterdata(value);
            setShowCalendarData(true);
          }}
        ></ion-icon>
      ),
      pinned:"right"
    },
  ];

  const getfilterdata = (id) => {
    ;
    api
      .get(`CalendarData/SingleCalendarData?CalendarDataID=${id}`)
      .then(({ data }) => {
        ;
        setEditCalenderData(data.data);
      })
      .catch(handleApiError);
  };

  const EditReset = () => {
    setShowCalendarData(false);
    GetCalenderData();
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            // onSubmit={(event) => insertAreaMaster(event, { resetForm })}
            onSubmit={(e) => {
              if (e) {
                e.preventDefault();
              }

              if (fliterformdata.current && e) {
                // Check for the formName to call insertCity
                if (e.target.name === "filterdataform") {
                  FilterData(e);
                }
              }
            }}
            ref={fliterformdata}
            name="filterdataform"
          >
            <Row>
              <Col md={4} sm={4} lg={4}>
                <Form.Group controlId="Expected_ResolveDate">
                  <Form.Label>Start Date:</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <i class="fa fa-calendar"></i>
                    </InputGroup.Text>
                    <Form.Control
                      type="date"
                      name="start_date"
                      value={formData.start_date}
                      onChange={handleInputChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Expected start_date
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={4} sm={4} lg={4}>
                <Form.Group controlId="Expected_ResolveDate">
                  <Form.Label>End Date:</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <i class="fa fa-calendar"></i>
                    </InputGroup.Text>
                    <Form.Control
                      type="date"
                      name="end_date"
                      value={formData.end_date}
                      onChange={handleInputChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Expected end_date
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
              <CalendarHeadDDComponent
                onSelectCalendarHead={(calendarHeadID) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    calenderHead_id: calendarHeadID,
                  }));
                  setValidationError(false); // Reset validation error when a city is selected
                }}
                selectedCalendarHead={formData.calenderHead_id} // Pass the state_id
                validationError={validationError}
                key={refreshKey} // Key to force re-render
              />
            </Row>

            <div className="card-footer margin_top10">
              <Button type="submit" variant="primary" className="mb-20px">
                Search
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>
          <span>Filter Data</span>
        </Card.Header>
        <Card.Body>
          <AgGridComponent rowData={filterData} columnDefs={columnDefs} />
        </Card.Body>
      </Card>
      <Modal
        show={showCalendarData}
        onHide={EditReset}
        size="xl"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton closeVariant={"white"}>
          <Modal.Title id="example-custom-modal-styling-title">
            Edit CalenderHead Data
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CalendarDataComponent
            editedCalendarDataData={editCalenderData}
            CalendarDataID={EditReset}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
