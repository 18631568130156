import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import api from "../../Utils/api";
import { Card } from "react-bootstrap";
import CityComponent from "./CityComponent";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";

export default function City() {
  const [CityData, setCityData] = useState([]);
  const [editStateData, setEditStateData] = useState(null);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");
  
  useEffect(() => {
    getCity();
    CheckRoleTemplatePermissions("City")
    .then((obj) => {
      setRoleTemplatePermissions(obj);
    })
    .catch((error) => null);
  }, []);

  // Fetch all Citys
  const getCity = () => {
    api
      .get("City/AllCity")
      .then((result) => {
        setCityData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Get data of a single City
  const getSingleCity = (id) => {
    api
      .get(`City/SingleCity?CityID=${id}`)
      .then((result) => {
        const CityData = result.data.data;
        setEditStateData(CityData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Delete City
  const deleteCity = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`City/DeleteCity?CityID=${id}`)
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              getCity();
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  };

  // AG Grid Table Column
  const columnDefs = [
    {
      headerName: "State Name",
      field: "stateName",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      flex: 1,
      minWidth:150,
    },
    {
      headerName: "City Name",
      field: "cityName",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      flex: 1,
      minWidth:200
    },
    ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
      headerName: "Edit",
      width: 70,
      field: "cityID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="create-outline"
          class="iconsFonts editIcon"
          onClick={() => getSingleCity(value)}
        ></ion-icon>
      ),
      pinned: 'right'
    }, ]
    : []),
  ...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
    ? [
        {
      headerName: "Delete",
      width: 80,
      field: "cityID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="trash-outline"
          class="iconsFonts deleteIcon"
          onClick={() => deleteCity(value)}
        ></ion-icon>
      ),
      pinned: 'right'
    },
  ]
: []),
];


  return (
    
      <>
        <ToastContainer />
        {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
        <Card>
          <Card.Header>
            <span>New City</span>
          </Card.Header>
          <Card.Body>
            <CityComponent
              cityID={getCity}
              editCityData={editStateData}
              getCityData={getSingleCity}
            />
          </Card.Body>
        </Card>
        )}
        <Card>
          <Card.Header>
            <span>City List</span>
          </Card.Header>
          <Card.Body>
            <AgGridComponent rowData={CityData} columnDefs={columnDefs} />
          </Card.Body>
        </Card>
      </>
    
  );
}

