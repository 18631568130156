import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import api, { handleApiError } from "../../Utils/api";
import { Card } from "react-bootstrap";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import SMSTemplateComponent from "./SMSTemplateComponent";
import SMSApiDDComponent from "../SMSApi/SMSApiDDComponent";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";

export default function SMSTemplate() {
  const [SMSTemplateData, setSMSTemplateData] = useState([]);
  const [editSMSTemplateData, setEditSMSTemplateData] = useState(null);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getSMSTemplate();
    CheckRoleTemplatePermissions("SMSTemplate")
      .then((obj) => {
        setRoleTemplatePermissions(obj);
      })
      .catch((error) => null);
  }, []);

  const notifySuccess = (message) => toast.success(message);

  const getSMSTemplate = () => {
  
    api
      .get(`SMSTemplate/AllSMSTemplate`)
      .then(({ data }) => setSMSTemplateData(data.data))
      .catch(handleApiError);
  };

  const getSingleSMSTemplate = (id) => {
    
    api
      .get(`SMSTemplate/SingleSMSTemplate?SMSTemplateID=${id}`)
      .then(({ data }) => setEditSMSTemplateData(data.data))
      .catch(handleApiError);
  };

  const deleteSMSTemplate = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`SMSTemplate/DeleteSMSTemplate?SMSTemplateID=${id}`)
          .then(({ status }) => {
            if (status === 200) {
              notifySuccess("Your record has been deleted.");
              getSMSTemplate();
            }
          })
          .catch(handleApiError);
      }
    });
  };


  const columnDefs = [
    {
      headerName: "SMSTemplateName",
      field: "smsTemplateName",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      minWidth: 100,
      flex:1
    },
    {
      headerName: "SMSText",
      field: "smsText",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      //wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      minWidth: 800,
      flex:1,
      cellRenderer: (params) => {
        return params.value.split('\n').map((line, index) => (
          <div key={index}>{line}</div>
        ));
      },
    },
    {
      headerName: "CCNumber",
      field: "ccNumber",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      minWidth: 100,
      flex:1,
      cellRenderer: ({ value }) => {
        return value == null ? 0 : value;
      }
      
    }, ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
      headerName: "Edit",
      width: 70,
      field: "smsTemplateID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="create-outline"
          class="iconsFonts editIcon"
          onClick={() => getSingleSMSTemplate(value)}
        ></ion-icon>
      ),
      pinned: 'right',
    },
  ]
  : []),
...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
  ? [
      {
      headerName: "Delete",
      width: 80,
      field: "smsTemplateID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="trash-outline"
          class="iconsFonts deleteIcon"
          onClick={() => deleteSMSTemplate(value)}
        ></ion-icon>
      ),
      pinned: 'right',
    },
  ]
: []),
  ];

  return (
    <>
    <ToastContainer />
    {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
    <Card>
      <Card.Header>
        <span>New SMSTemplate</span>
      </Card.Header>
      <Card.Body>
        <SMSTemplateComponent
          SMSTemplateID={getSMSTemplate}
          editedSMSTemplateData={editSMSTemplateData}
          getSMSTemplateData={getSingleSMSTemplate}
        />
      </Card.Body>
    </Card>)}
    <Card>
      <Card.Body>
     <AgGridComponent rowData={SMSTemplateData} columnDefs={columnDefs} />
      </Card.Body>
    </Card >
  </>
  )
}
