import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api, { handleApiError } from "../../Utils/api";
import {
  Form,
  Button,
  Col,
  Row,
  InputGroup,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import AreaDDComponent from "../Area/AreaDDComponent";
import SourceTypeDDComponent from "../SourceType/SourceTypeDDComponent";
import Loader from "../../Components/Common/Loader ";
import Swal from "sweetalert2";

const emptyClient = {
  Client_id: 0,
  Client_Name: "",
  Client_MobileNumber: "",
  Client_Address: "",
  Client_EmailID: "",
  area_id: 0,
  type: "",
  SourceType_id: 0,
  Admin_Name: "",
  Admin_Mobilenumber: "",
  Admin_EmailID: "",
  Remarks: "",
  Recovery_EmailID: "",
  Password: "",
  RemoteDesk_Address: "",
  isActive: true,
};

export default function ClientComponent({
  ClientID,
  editedClientData,
  getClientData,
  onClear,
}) {
  const [refreshKey, setRefreshKey] = useState(0);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyClient);
  const [areaDDValidationError, setAreaDDValidationError] = useState(false);
  const [sourceTypeDDValidationError, setSourceTypeDDValidationError] =
    useState(false);

  const ClientFormRef = useRef(null);
  let isLoading = useRef(false);

  useEffect(() => {
    ;
    if (editedClientData) {
      setClientType(editedClientData.type);
      setFormData({
        Client_id: editedClientData.clientID,
        Client_Name: editedClientData.clientName,
        Client_MobileNumber: editedClientData.clientMobileNumber,
        Client_Address: editedClientData.address,
        Client_EmailID: editedClientData.emailID,
        area_id: editedClientData.areaID || 0,
        type: editedClientData.type,
        SourceType_id: editedClientData.sourceTypeID || 0,
        Admin_Name: editedClientData.adminName,
        Admin_Mobilenumber: editedClientData.adminMobilenumber,
        Admin_EmailID: editedClientData.adminEmailID,
        Remarks: editedClientData.remarks,
        Recovery_EmailID: editedClientData.recoveryEmailID,
        Password: editedClientData.password,
        RemoteDesk_Address: editedClientData.remoteDeskAddress,
        isActive: editedClientData.isActive,
      });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [editedClientData]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
  };

  const insertOrUpdateClient = (event) => {
    setValidated(true);
    isLoading.current = true;
    event.preventDefault();

    const form = event.currentTarget;

    let validationErrors = [];
    if (!formData.Client_Name) validationErrors.push("Client Name");
    if (!formData.Client_MobileNumber)
      validationErrors.push("Client Mobile Number");
    if (!formData.Client_Address) validationErrors.push("Client Address");
    if (!formData.area_id) validationErrors.push("Area");
    if (!formData.SourceType_id) validationErrors.push("Source Type");

    if (validationErrors.length > 0) {
      Swal.fire({
        title: "Something went wrong!",
        text: `Please fill the following fields: ${validationErrors.join(
          ", "
        )}`,
        icon: "error",
        confirmButtonText: "OK",
      });
      isLoading.current = false;
      return;
    }

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setAreaDDValidationError(formData.area_id === 0);
      setSourceTypeDDValidationError(formData.SourceType_id === 0);
      isLoading.current = false;
      return;
    }
    const data = {
      clientID: formData?.Client_id || 0,
      clientName: formData.Client_Name,
      clientMobileNumber: formData.Client_MobileNumber,
      address: formData.Client_Address,
      emailID: formData.Client_EmailID,
      areaID: formData.area_id,
      type: formData.type,
      sourceTypeID: formData.SourceType_id,
      adminName: formData.Admin_Name,
      adminMobilenumber: formData.Admin_Mobilenumber,
      adminEmailID: formData.Admin_EmailID,
      remarks: formData.Remarks,
      recoveryEmailID: formData.Recovery_EmailID,
      password: formData.Password,
      remoteDeskAddress: formData.RemoteDesk_Address,
      IsActive: formData.isActive,
    };

    const endpoint =
      formData.Client_id === 0
        ? "Client/ClientLeadInsert"
        : "Client/ClientLeadUpdate";

    api[formData.Client_id === 0 ? "post" : "patch"](endpoint, data)
      .then((result) => {
        if (result.status === 200) {
          setAreaDDValidationError(false);
          setSourceTypeDDValidationError(false);
          toast.success(result.data.message);
          if (ClientID) {
            ClientID(result.data.data);
          }
        } else {
          toast.error(result.data.message);
        }
        setRefreshKey((prevKey) => prevKey + 1);
      })
      .catch(handleApiError)
      .finally(() => {
        isLoading.current = false;
        resetForm();
        onClear();
        setValidated(false);
      });
  };

  const resetForm = () => {
    setFormData(emptyClient);
    setClientType("");
  };

  const [clientType, setClientType] = useState("");

  const handleRadioChange = (event) => {
    setClientType(event.target.value);
    formData.type = event.target.value;
  };

  return (
    <>
      {isLoading.current && <Loader />}
      <Form
        noValidate
        validated={validated}
        onSubmit={(e) => {
          if (e) {
            e.preventDefault();
          }

          if (ClientFormRef.current && e) {
            if (e.target.name === "ClientForm") {
              insertOrUpdateClient(e);
            }
          }
        }}
        ref={ClientFormRef}
        name="ClientForm"
      >
        <Row>
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="TaskAndIssue_Name">
              <Form.Label> Type:</Form.Label>
              <InputGroup className="mb-3">
                <ButtonGroup className="w-50">
                  {["Lead", "Client"].map((type) => (
                    <ToggleButton
                      label={type.charAt(0).toUpperCase() + type.slice(1)}
                      key={type}
                      id={`inline-radio-${type}`}
                      type="radio"
                      variant={
                        type === "Lead" ? "outline-primary" : "outline-success"
                      }
                      name="clientType"
                      value={type}
                      checked={clientType === type}
                      onChange={handleRadioChange}
                      required
                      disabled={
                        editedClientData && editedClientData
                          ? editedClientData.type == "Client"
                          : null
                      }
                    >
                      {type}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
                {validated && !formData.type && (
                  <div className=" ValidationErrorMsg mt-0 ">
                    Please provide a Type.
                  </div>
                )}
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="Client_Name">
              <Form.Label>Client Name:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i class="fa fa-user"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="Client_Name"
                  placeholder="Enter Client Name"
                  value={formData.Client_Name}
                  onChange={handleInputChange}
                  required
                />
                {validated && !formData.Client_Name && (
                  <div className="invalid-feedback  ">
                    Please Enter Client Name.
                  </div>
                )}
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={4} sm={2} lg={4}>
            <Form.Group controlId="Client_MobileNumber">
              <Form.Label>Client Mobile No:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i class="fas fa-mobile"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="Client_MobileNumber"
                  placeholder="Enter MobileNumber"
                  value={formData.Client_MobileNumber}
                  onChange={handleInputChange}
                  required
                />
                {validated && !formData.Client_MobileNumber && (
                  <div className="invalid-feedback">
                    Please Enter MobileNumber Name.
                  </div>
                )}
              </InputGroup>
            </Form.Group>
          </Col>

          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="Client_EmailID">
              <Form.Label>EmailID:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i class="fa fa-envelope"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="Client_EmailID"
                  placeholder="Enter EmailID"
                  value={formData.Client_EmailID}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={2} lg={4}>
            <Form.Group controlId="Client_Address">
              <Form.Label>Address:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fa fa-map-marker"></i>
                </InputGroup.Text>
                <Form.Control
                  as="textarea" // Change here to make it a textarea
                  rows={3} // Adjust the number of rows as needed
                  name="Client_Address"
                  placeholder="Enter Address"
                  value={formData.Client_Address}
                  onChange={handleInputChange}
                  required
                />
                {validated && !formData.Client_Address && (
                  <div className="invalid-feedback">Please Enter Address.</div>
                )}
              </InputGroup>
            </Form.Group>
          </Col>
          <AreaDDComponent
            onSelectArea={(areaID) => {
              setFormData((prevData) => ({
                ...prevData,
                area_id: areaID,
              }));
              setAreaDDValidationError(false); // Reset areaDDValidationError when selecting an area
            }}
            selectedArea={formData.area_id}
            ValidationError={areaDDValidationError} // Pass the validation error to AreaDDComponent
            key={`ClientDDComponent-${refreshKey}`}
          />
          <SourceTypeDDComponent
            onSelectSourceType={(sourceTypeID) => {
              setFormData((prevData) => ({
                ...prevData,
                SourceType_id: sourceTypeID,
              }));
              setSourceTypeDDValidationError(false);
            }}
            selectedSourceType={formData.SourceType_id}
            ValidationError={sourceTypeDDValidationError}
            key={`SourceTypeDDComponent-${refreshKey}`}
          />
        </Row>
        {clientType == "Client" && (
          <Row className="mt-4">
            <Col md={4} sm={4} lg={4}>
              <Form.Group controlId="Client_Name">
                <Form.Label>Admin Name:</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <i class="fa fa-user"></i>
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    name="Admin_Name"
                    placeholder="Enter Admin Name"
                    value={formData.Admin_Name}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={4} sm={4} lg={4}>
              <Form.Group controlId="Client_Name">
                <Form.Label>Admin MobileNumber:</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <i class="fa fa-user"></i>
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    name="Admin_Mobilenumber"
                    placeholder="Enter Admin MobileNumber"
                    value={formData.Admin_Mobilenumber}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={4} sm={4} lg={4}>
              <Form.Group controlId="Client_Name">
                <Form.Label>Admin EmailID:</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <i class="fa fa-user"></i>
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    name="Admin_EmailID"
                    placeholder="Enter Admin EmailID "
                    value={formData.Admin_EmailID}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        )}
        {clientType == "Client" && (
          <Row className="mt-3">
            <Col md={4} sm={4} lg={4}>
              <Form.Group controlId="Client_Name">
                <Form.Label>Recovery Email:</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <i class="fa fa-user"></i>
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    name="Recovery_EmailID"
                    placeholder="Enter Recovery EmailID "
                    value={formData.Recovery_EmailID}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={4} sm={4} lg={4}>
              <Form.Group controlId="Client_Name">
                <Form.Label>Password:</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <i class="fa fa-user"></i>
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    name="Password"
                    placeholder="Enter Password"
                    value={formData.Password}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={4} sm={4} lg={4}>
              <Form.Group controlId="Client_Name">
                <Form.Label>Anydesk No:</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <i class="fa fa-user"></i>
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    name="RemoteDesk_Address"
                    placeholder="Enter Anydesk No"
                    value={formData.RemoteDesk_Address}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        )}
        {clientType == "Client" && (
          <Row>
            <Form.Group controlId="Client_Address">
              <Form.Label>Remarks:</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  as="textarea" // Change here to make it a textarea
                  rows={3} // Adjust the number of rows as needed
                  name="Remarks"
                  placeholder="Enter Remarks"
                  value={formData.Remarks}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </Form.Group>
          </Row>
        )}
        <Row>
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="isActive">
              <Form.Label></Form.Label>
              <Form.Check
                type="checkbox"
                label="Is Active"
                name="isActive"
                checked={formData.isActive}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <div className="card-footer margin_top10">
          <Button type="submit" variant="primary" className="mb-20px">
            {formData?.Client_id > 0 ? "Update" : "Save"}
          </Button>
        </div>
      </Form>
    </>
  );
}
