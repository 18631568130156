// api.js

import axios from "axios";
import data from "../UserSettings.json";
import { toast } from "react-toastify";

const api = axios.create({
  baseURL: data.apiUrl,
});

const notifyError = (message) => toast.error(message);
export const handleApiError = (error) => {
  notifyError("An error occurred.");
};

export default api;
