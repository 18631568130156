import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api, { handleApiError } from "../../Utils/api";
import { Form, Button, Col, Row } from "react-bootstrap";
import PipelineStageDDComponent from "../PipelineStage/PipelineStageDDComponent";
import ClientDDComponent from "../Client/ClientDDComponent";

const emptyOpportunity = {
  opportunity_id: 0,
  client_id: 0,
  pipelineStage_id: 0,
  isActive: true,
};

export default function OpportunityComponent({
  opportunityID,
  editedOpportunityData,
  setShowOpportunity,
  getOpportunityData,
}) {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyOpportunity);
  const [refreshKey, setRefreshKey] = useState(0);

  const OpportunityFormRef = useRef(null);

  useEffect(() => {
    if (editedOpportunityData) {
      setFormData({
        opportunity_id: editedOpportunityData.opportunityID,
        client_id: editedOpportunityData.clientID,
        pipelineStage_id: editedOpportunityData.pipelineStageID,
        IsActive: editedOpportunityData.isActive,
      });
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }
  }, [editedOpportunityData]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    // Use the checked property for checkboxes
    const inputValue = type === "checkbox" ? checked : value;

    setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
  };

  const insertOrUpdateOpportunity = (event) => {
    setValidated(true);
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }
    console.log(formData.client_id);
    const data = {
      opportunityID: formData?.opportunity_id || 0,
      clientID: formData.client_id,
      pipelineStageID: formData.pipelineStage_id,
      IsActive: formData.isActive,
    };
    const endpoint =
      formData.opportunity_id === 0
        ? "Opportunity/InsertOpportunity"
        : "Opportunity/EditOpportunity";

    api[formData.opportunity_id === 0 ? "post" : "patch"](endpoint, data)
      .then((result) => {
        if (result.status === 200) {
          toast.success(result.data.message);
          if (opportunityID) {
            opportunityID(result.data.data);
          }
          
        } else {
          toast.error(result.data.message);
        }
        setRefreshKey(refreshKey + 1);
      })
      .catch(handleApiError);
    resetFormOpportunity();
    setValidated(false);
    setShowOpportunity(false);
  };

  const resetFormOpportunity = () => {
    setFormData(emptyOpportunity);
    setShowOpportunity(false);
  };

  return (
    <>
      <Form
        noValidate
        validated={validated}
        onSubmit={(e) => {
          if (e) {
            e.preventDefault();
          }

          if (OpportunityFormRef.current && e) {
            if (e.target.name === "OpportunityForm") {
              insertOrUpdateOpportunity(e);
            }
          }
        }}
        ref={OpportunityFormRef}
        name="OpportunityForm"
      >
        <Row>
        <ClientDDComponent
            onSelectClient={(clientID) => {
              setFormData((prevData) => ({
                ...prevData,
                client_id: clientID,
              }));
            }}
            selectedClient={formData.client_id}
            // key={refreshKey}
            key={`ClientDDComponent-${refreshKey}`}
          />
          <PipelineStageDDComponent
            onSelectPipelineStage={(pipelineStageID) => {
              setFormData((prevData) => ({
                ...prevData,
                pipelineStage_id: pipelineStageID,
              }));
            }}
            selectedPipelineStage={formData.pipelineStage_id}
            // key={refreshKey}
            key={`PipelineStageDDComponent-${refreshKey}`}
          />
          
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="isActive">
              <Form.Label></Form.Label>
              <Form.Check
                type="checkbox"
                label="Is Active"
                name="isActive"
                checked={formData.isActive}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <div className="card-footer margin_top10">
          <Button type="submit" variant="primary" className="mb-20px">
            {formData?.opportunity_id > 0 ? "Update" : "Save"}
          </Button>
        </div>
      </Form>
    </>
  );
}
