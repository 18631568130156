import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Form,
  Row,
  InputGroup,
  Button,
  Image,
  Col,
} from "react-bootstrap";
import Webcam from "react-webcam";

const videoConstraints = {
  width: 300,
  facingMode: "environment",
};

const ImageUploadAndWebcamComponent = ({
  showModal,
  onClose,
  onImageCapture,
  onClear,
  onFileUpload,
}) => {
  const [show, setShow] = useState(false);
  const webcamRef = useRef(null);
  const imageInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState("");
  const [capturedImage, setCapturedImage] = useState([]);
  const [image, setImage] = useState([]);

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  const dataURLtoBlob = (dataURL) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const handleClose = () => {
    setShow(false);
    setCapturedImage([]);
    onClose();
    if (imageInputRef.current) {
      imageInputRef.current.value = null;
    }
    if (webcamRef.current) {
      webcamRef.current.value = null;
    }
  };

  const handleClear = () => {
    setCapturedImage([]);
    onClear();
  };

  const handleInputChange = (event) => {
    ;
    const { name, files } = event.target;
    

    if (name === "Image") {
      for (let i = 0; i < files.length; i++) {
        const selectedFile = files[i];
        setSelectedImage((prevData) => ({ ...prevData, selectedFile }));
        onFileUpload(selectedFile);
      }
    }
    if (name === "CaptureImage") {
      const imageSrc = webcamRef.current.getScreenshot();
      const prefixedFilename = dataURLtoBlob(imageSrc);
      const capturedFile = new File([prefixedFilename], `CapturedImage.jpg`, {
        type: "image/jpeg",
      });
      onImageCapture(capturedFile);
      //setImage((prevData) => ({ ...prevData, imageSrc }));
      setCapturedImage((prevData) => [
        ...prevData,
        URL.createObjectURL(capturedFile),
      ]);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton closeVariant={"white"}>
          <Modal.Title id="example-custom-modal-styling-title">
            Upload Image
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Row>
              <Form.Group controlId="Image">
                <Form.Label>Image Upload:</Form.Label>
                <div className="d-flex justify-content-between">
                  <div>
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      videoConstraints={videoConstraints}
                    />
                    <div className="my-3">
                      <Button
                        variant="outline-success"
                        onClick={() =>
                          handleInputChange({
                            target: { name: "CaptureImage" },
                          })
                        }
                      >
                        Capture Image
                      </Button>
                    </div>
                  </div>
                  <div className="mx-5">
                    <Row>
                      {capturedImage &&
                        capturedImage.map((capturedImage, index) => (
                          <Col md={4} sm={4} lg={4} >
                            <Image
                              src={capturedImage}
                              alt={`capturedImage ${index + 1}`}
                              thumbnail
                              className="mt-20 w-100 "
                            />
                          </Col>
                        ))}
                    </Row>
                  </div>
                </div>
                <Form.Control
                  type="file"
                  name="Image"
                  ref={imageInputRef} // Create a ref for the file input
                  onChange={handleInputChange}
                  multiple
                />
              </Form.Group>
            </Row>
            <Row>
              <div className="margin_top10">
                <Button
                  onClick={handleClose}
                  variant="outline-primary"
                  className="mb-20 mx-2 text-end"
                >
                  Upload
                </Button>
                <Button
                  onClick={handleClear}
                  variant="outline-danger"
                  className="mb-20 text-start"
                >
                  clear
                </Button>
              </div>
            </Row>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ImageUploadAndWebcamComponent;
