import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import api, { handleApiError } from "../../Utils/api";
import { Card } from "react-bootstrap";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import InvoiceComponent from "./InvoiceComponent";
import PrintInvoiceComponent from "./PrintInvoiceComponent";
import ReactDOMServer from "react-dom/server";
import {
  convertDateFormat,
  convertDateFormatWithOutTime,
} from "../../Utils/commonFunction";
import {
  Form,
  Button,
  Col,
  Row,
  InputGroup,
  Modal,
  Dropdown,
} from "react-bootstrap";
import { getCurrentDate } from "../../Utils/commonFunction";
import TypeaheadComponent from "../../Components/Common/TypeaheadComponent";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";

export default function Invoice() {
  const DateTimeFilterData = {
    start_date: getCurrentDate(),
    end_date: getCurrentDate(),
    clientID: 0,
  };

  const [InvoiceData, setInvoiceData] = useState([]);
  const [editInvoiceData, setEditInvoiceData] = useState(null);
  const [formData, setFormData] = useState(DateTimeFilterData);
  const [refreshKey, setRefreshKey] = useState(0);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getAllInvoice();
    CheckRoleTemplatePermissions("Invoice")
      .then((obj) => {
        setRoleTemplatePermissions(obj);
      })
      .catch((error) => null);
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const notifySuccess = (message) => toast.success(message);

  const getInvoice = () => {
    ;
    api
      .get(
        `Invoice/FilterInvoiceData?START_DATE=${formData.start_date}&END_DATE=${formData.end_date}&ClientID=${formData.clientID}`
      )
      .then(({ data }) => {
        setInvoiceData(data.data);
      })
      .catch(handleApiError);
  };

  const getAllInvoice = () => {
    api
      .get("Invoice/AllInvoice")
      .then(({ data }) => setInvoiceData(data.data))
      .catch(handleApiError);
  };

  const getSingleInvoice = (id) => {
    api
      .get(`Invoice/SelectInvoice?InvoiceID=${id}`)
      .then(({ data }) => setEditInvoiceData(data.data))
      .catch(handleApiError);
  };

  const deleteInvoice = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`Invoice/DeleteInvoice?InvoiceID=${id}`)
          .then(({ status }) => {
            if (status === 200) {
              notifySuccess("Your record has been deleted.");
              getInvoice();
            }
          })
          .catch(handleApiError);
      }
    });
  };

  //  print invoice due amount data

  const PrintDueAMount = () => {
    api
      .get(`Invoice/ListOfDueAmountOfClientInvoice?START_DATE=${formData.start_date}&END_DATE=${formData.end_date}&ClientID=${formData.clientID}`)
      .then(({ data }) => {
        var DueAmountList = data.data;
        let printWindow = window.open("", "_blank");
        const printableContent = DueAmountListDataPrint(DueAmountList);
        printWindow.document.write(printableContent);
        printWindow.document.close();
        printWindow.print();
      })
      .catch(handleApiError);
  };

  const columnDefs = [
    {
      headerName: "InvoiceNo",
      field: "invoiceNo",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      flex: 1,
      minWidth: 120,
      suppressMovable: true,
    },
    {
      headerName: "InvoiceDate",
      field: "invoiceDate",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value }) => convertDateFormatWithOutTime(value),
      flex: 1,
      minWidth: 180,
      suppressMovable: true,
    },
    {
      headerName: "Client Name",
      field: "clientName",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      minWidth: 450,
      flex: 1,
      suppressMovable: true,
    },
    {
      headerName: "NetAmount",
      field: "netAmount",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      flex: 1,
      minWidth: 100,
      suppressMovable: true,
    },
    {
      headerName: "Receive Amount",
      field: "totalReceivedAmount",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      flex: 1,
      minWidth: 100,
      suppressMovable: true,
    },
    {
      headerName: "DueAmount",
      field: "dueAmount",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      flex: 1,
      minWidth: 100,
      suppressMovable: true,
    },
    ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
            headerName: "Edit",
            width: 70,
            field: "invoiceID",
            cellStyle: { textAlign: "center" },
            headerClass: "center-text widthTableColum",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="create-outline"
                class="iconsFonts editIcon"
                onClick={() => getSingleInvoice(value)}
              ></ion-icon>
            ),
            pinned: "right",
            suppressMovable: true, // Pin to the right side
          },
        ]
      : []),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
      ? [
          {
            headerName: "Delete",
            width: 80,
            field: "invoiceID",
            cellStyle: { textAlign: "center" },
            headerClass: "center-text widthTableColum",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="trash-outline"
                class="iconsFonts deleteIcon"
                onClick={() => deleteInvoice(value)}
              ></ion-icon>
            ),
            pinned: "right",
            suppressMovable: true, // Pin to the right side
          },
        ]
      : []),
    {
      headerName: "Print",
      width: 70,
      field: "invoiceID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      width: 80,
      cellRenderer: ({ value }) => (
        <ion-icon
          name="print-outline"
          class="iconsFonts editIcon"
          onClick={() => openPrintTab(value)}
        ></ion-icon>
      ),
      pinned: "right",
      suppressMovable: true, // Pin to the right side
    },
  ];

  const openPrintTab = (invoiceID) => {
    const printWindow = window.open("", "_blank");

    api
      .get(`Invoice/SelectInvoice?InvoiceID=${invoiceID}`)
      .then(({ data }) => {
        const printComponent = (
          <PrintInvoiceComponent invoiceData={data.data} />
        );
        const printContent =
          ReactDOMServer.renderToStaticMarkup(printComponent);

        if (printWindow) {
          printWindow.document.write(`
            <html>
              <head>
                <title>Print</title>
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet">
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
              </head>
              <body>
                ${printContent}
              </body>
            </html>`);

          //printWindow.close();
        }
      })
      .catch((error) => {
        console.error("API error:", error);
      });
  };

  const handleClientSelection = (selectedClient) => {
    if (selectedClient) {
      setFormData((prevData) => ({ ...prevData, clientID: selectedClient }));
    }
  };

  const DueAmountListDataPrint = (InvoiceDueAMountList) => {
    let totalNetAmount = 0;
    let totalReceivedAmount = 0;
    let totalDueAmount = 0;
  
    let printContent = `
      <html>
        <head>
          <title>Client List</title>
          <style>
            table {
              border-collapse: collapse;
              width: 100%;
            }
            th, td {
              border: 1px solid black;
              padding: 2px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
          </style>
        </head>
        <body>
          <h2>Invoice Due Amount List</h2>
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Invoice No</th>
                <th>Invoice Date</th>
                <th>Client Name</th>
                <th>Net Amount</th>
                <th>Receive Amount</th>
                <th>Due Amount</th>
              </tr>
            </thead>
            <tbody>`;
  
    InvoiceDueAMountList.forEach((item, index) => {
      totalNetAmount += item.netAmount;
      totalReceivedAmount += item.totalReceivedAmount;
      totalDueAmount += item.dueAmount;
  
      printContent += `
        <tr>
          <td>${index + 1}</td>
          <td>${item.invoiceNo}</td>
          <td>${convertDateFormatWithOutTime(item.invoiceDate)}</td>
          <td>${item.clientName}</td>
          <td>${item.netAmount}</td>
          <td>${item.totalReceivedAmount}</td>
          <td>${item.dueAmount}</td>
        </tr>`;
    });
  
    printContent += `
        <tr>
          <td colspan="4" style="text-align: right;"><strong>Total</strong></td>
          <td><strong>${totalNetAmount}</strong></td>
          <td><strong>${totalReceivedAmount}</strong></td>
          <td><strong>${totalDueAmount}</strong></td>
        </tr>`;
  
    printContent += `
            </tbody>
          </table>
        </body>
      </html>`;
  
    return printContent;
  };
  

  return (
    <>
      <ToastContainer />
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
        <InvoiceComponent
          InvoiceID={getAllInvoice}
          editedInvoiceData={editInvoiceData}
          getInvoiceData={getSingleInvoice}
        />
      )}
      <Card>
        <Card.Header>
          <span>Invoice List</span>
        </Card.Header>
        <Card.Body>
          <Row className="justify-content-center">
            <Col md={3} sm={3} lg={3}>
              <Form.Group controlId="Expected_ResolveDate" className="d-flex">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <i className="fa fa-calendar"></i>
                  </InputGroup.Text>
                  <Form.Control
                    type="date"
                    name="start_date"
                    value={formData.start_date}
                    onChange={handleInputChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Expected start_date
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={3} sm={3} lg={3}>
              <Form.Group controlId="Expected_ResolveDate" className="d-flex">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <i className="fa fa-calendar"></i>
                  </InputGroup.Text>
                  <Form.Control
                    type="date"
                    name="end_date"
                    value={formData.end_date}
                    onChange={handleInputChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Expected end_date
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={3} sm={3} lg={3}>
              <Form.Group controlId="Expected_ResolveDate" className="d-flex">
                <InputGroup className="mb-3">
                  <TypeaheadComponent
                    type={"Client"}
                    onClientSelect={handleClientSelection}
                    onClear={refreshKey}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={1} sm={1} lg={1}>
              <Button variant="outline-success" onClick={getInvoice}>
                Search
              </Button>
            </Col>
            <Col md={1} sm={1} lg={1}>
              <Button variant="outline-info" onClick={PrintDueAMount}>
                Due Bill
              </Button>
            </Col>
          </Row>
        </Card.Body>
        <Card.Body>
          <AgGridComponent rowData={InvoiceData} columnDefs={columnDefs} />
        </Card.Body>
      </Card>
    </>
  );
}
