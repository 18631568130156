// import { React, useState, useEffect } from "react";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import api from "./Utils/api";
// import Navbar from "./Components/Header/Header";
// import Protected from "./Features/Protected";
// import Login from "./Pages/Login/Login";
// import Logout from "./Pages/Logout/Logout";
// import PageNotFound from "./Pages/PageNotFound/PageNotFound";
// import State from "./Pages/State/State";
// import City from "./Pages/City/City";
// import Area from "./Pages/Area/Area";
// import UserMaster from "./Pages/UserMaster/UserMaster";
// import Client from "./Pages/Client/Client";
// import RoleTemplate from "./Pages/RoleTemplate/RoleTemplate";
// import FormAdd from "./Pages/Form/FormAdd";
// import UserProfile from "./Pages/UserProfile/UserProfile";
// import Pipeline from "./Pages/Pipeline/Pipeline";
// import PipelineStage from "./Pages/PipelineStage/PipelineStage";
// import Opportunity from "./Pages/Opportunity/Opportunity";
// import CalendarHead from "./Pages/CalendarHead/CalendarHead";
// import CalendarData from "./Pages/CalendarData/CalendarData";
// import TaskAndIssue from "./Pages/TaskAndIssue/TaskAndIssue";
// import Developer from "./Pages/Developer/Developer";
// import SourceType from "./Pages/SourceType/SourceType";
// import TaskAndIssueTracking from "./Pages/TaskAndIssueTracking/TaskAndIssueTracking";
// import Dashboard from "./Pages/Dashboard/Dashboard";
// import Invoice from "./Pages/Invoice/Invoice";
// import Charge from "./Pages/Charge/Charge";
// import "./App.css";

// function App() {
//   const [userName, setUserName] = useState("");

//   useEffect(() => {
//     const storedUserName = localStorage.getItem("userName");
//     if (storedUserName) {
//       setUserName(storedUserName);
//     }
//   }, []);

//   let loginToken = localStorage.getItem("token");
//   // Comment Type here data.

//   const [rolePermissionData, setRolePermissionData] = useState([]);

//   useEffect(() => {
//     getRolePermission();
//   }, []);

//   // Fetch all departments
//   const getRolePermission = () => {
//
//     api
//       .get("RoleTemplateDetail/ViewPermissionWiseFormShow")
//       .then((result) => {
//         setRolePermissionData(result.data.data);
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

//   return (
//     <div className="App">
//       {loginToken ? (
//         <>
//           <BrowserRouter>
//             <Navbar userName={userName} />
//             <Routes>
//               <Route path="/" element={<Protected Component={Dashboard} />} />

//               <Route path="/Area" element={<Protected Component={Area} />} />

//               <Route
//                 path="/CalendarData"
//                 element={<Protected Component={CalendarData} />}
//               />
//               <Route
//                 path="/CalendarHead"
//                 element={<Protected Component={CalendarHead} />}
//               />
//               <Route path="/Charge" element={<Protected Component={Charge} />} />
//               <Route path="/City" element={<Protected Component={City} />} />
//               <Route
//                 path="/Client"
//                 element={<Protected Component={Client} />}
//               />
//               <Route
//                 path="/Developer"
//                 element={<Protected Component={Developer} />}
//               />
//               <Route
//                 path="/FormAdd"
//                 element={<Protected Component={FormAdd} />}
//               />
//               <Route
//                 path="/Invoice"
//                 element={<Protected Component={Invoice} />}
//               />
//               <Route
//                 path="/Opportunity"
//                 element={<Protected Component={Opportunity} />}
//               />
//               <Route
//                 path="/Pipeline"
//                 element={<Protected Component={Pipeline} />}
//               />
//               <Route
//                 path="/PipelineStage"
//                 element={<Protected Component={PipelineStage} />}
//               />
//               <Route
//                 path="/RoleTemplate"
//                 element={<Protected Component={RoleTemplate} />}
//               />
//               <Route path="/State" element={<Protected Component={State} />} />
//               <Route path="/SourceType" element={<Protected Component={SourceType} />} />
//               <Route path="/TaskAndIssue" element={<Protected Component={TaskAndIssue} />} />
//               <Route path="/TaskAndIssueTracking" element={<Protected Component={TaskAndIssueTracking} />} />

//               <Route
//                 path="/UserMaster"
//                 element={<Protected Component={UserMaster} />}
//               />
//               <Route
//                 path="/UserProfile"
//                 element={<Protected Component={UserProfile} />}
//               />
//               <Route path="/logout" Component={Logout} />
//               <Route
//                 path="*"
//                 element={<Protected Component={PageNotFound} />}
//               />
//             </Routes>
//           </BrowserRouter>
//         </>
//       ) : (
//         <Login />
//       )}
//     </div>
//   );
// }

import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import api from "./Utils/api";
import Navbar from "./Components/Header/Header";
import Protected from "./Features/Protected";
import Login from "./Pages/Login/Login";
import Logout from "./Pages/Logout/Logout";
import PageNotFound from "./Pages/PageNotFound/PageNotFound";
import State from "./Pages/State/State";
import City from "./Pages/City/City";
import Area from "./Pages/Area/Area";
import UserMaster from "./Pages/UserMaster/UserMaster";
import UserTypeMaster from "./Pages/UserTypeMaster/UserTypeMaster";
import Client from "./Pages/Client/Client";
import RoleTemplate from "./Pages/RoleTemplate/RoleTemplate";
import FormAdd from "./Pages/Form/FormAdd";
import UserProfile from "./Pages/UserProfile/UserProfile";
import Pipeline from "./Pages/Pipeline/Pipeline";
import PipelineStage from "./Pages/PipelineStage/PipelineStage";
import Opportunity from "./Pages/Opportunity/Opportunity";
import CalendarHead from "./Pages/CalendarHead/CalendarHead";
import CalendarData from "./Pages/CalendarData/CalendarData";
import TaskAndIssue from "./Pages/TaskAndIssue/TaskAndIssue";
import Developer from "./Pages/Developer/Developer";
import SourceType from "./Pages/SourceType/SourceType";
import ExpenseMaster from "./Pages/ExpenseMaster/ExpenseMaster";
import ExpenseVourcher from "./Pages/ExpenseVourcher/ExpenseVourcher";
import EmailTemplate from "./Pages/EmailTemplate/EmailTemplate";
import SMSApi from "./Pages/SMSApi/SMSApi";
import SMSHistory from "./Pages/SMSHistory/SMSHistory";
import SMSTemplate from "./Pages/SMSTemplate/SMSTemplate";
import SMSSend from "./Pages/SMSSend.jsx/SMSSend";
import TaskAndIssueTracking from "./Pages/TaskAndIssueTracking/TaskAndIssueTracking";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Invoice from "./Pages/Invoice/Invoice";
import Charge from "./Pages/Charge/Charge";
import Spinner from "react-bootstrap/Spinner";
import InvoiceReceive from "./Pages/InvoiceReceive/InvoiceReceive";
import { WhatsAppStatus } from "../src/Utils/commonFunction";
import { Form, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import StatusOfWhatsApp from "./Pages/StatusOfWhatsApp/StatusOfWhatsApp";
import Welcome from "./Pages/Welcome/Welcome";

import "./App.css";

const routeConfig = [
  { path: "/Dashboard", component:Dashboard , permission: "Dashboard" },
  { path: "/Area", component: Area, permission: "Area" },
  {
    path: "/CalendarData",
    component: CalendarData,
    permission: "CalendarData",
  },
  {
    path: "/CalendarHead",
    component: CalendarHead,
    permission: "CalendarHead",
  },
  { path: "/Charge", component: Charge, permission: "Charge" },
  { path: "/City", component: City, permission: "City" },
  { path: "/Client", component: Client, permission: "Client" },
  { path: "/Developer", component: Developer, permission: "Developer" },
  {
    path: "/EmailTemplate",
    component: EmailTemplate,
    permission: "EmailTemplate",
  },
  { path: "/FormAdd", component: FormAdd, permission: "FormAdd" },
  { path: "/Invoice", component: Invoice, permission: "Invoice" },
  { path: "/Opportunity", component: Opportunity, permission: "Opportunity" },
  { path: "/Pipeline", component: Pipeline, permission: "Pipeline" },
  {
    path: "/PipelineStage",
    component: PipelineStage,
    permission: "PipelineStage",
  },
  {
    path: "/RoleTemplate",
    component: RoleTemplate,
    permission: "RoleTemplate",
  },
  { path: "/State", component: State, permission: "State" },
  { path: "/SourceType", component: SourceType, permission: "SourceType" },
  {
    path: "/TaskAndIssue",
    component: TaskAndIssue,
    permission: "TaskAndIssue",
  },
  {
    path: "/TaskAndIssueTracking",
    component: TaskAndIssueTracking,
    permission: "TaskAndIssueTracking",
  },
  { path: "/UserMaster", component: UserMaster, permission: "UserMaster" },
  { path: "/UserProfile", component: UserProfile, permission: "UserProfile" },
  {
    path: "/UserTypeMaster",
    component: UserTypeMaster,
    permission: "UserTypeMaster",
  },
  {
    path: "/InvoiceReceive",
    component: InvoiceReceive,
    permission: "InvoiceReceive",
  },
  { path: "/SMSApi", component: SMSApi, permission: "SMSApi" },
  { path: "/SMSHistory", component: SMSHistory, permission: "SMSHistory" },
  {
    path: "/StatusOfWhatsApp",
    component: StatusOfWhatsApp,
    permission: "StatusOfWhatsApp",
  },
  {
    path: "/ExpenseMaster",
    component: ExpenseMaster,
    permission: "ExpenseMaster",
  },
  {
    path: "/ExpenseVourcher",
    component: ExpenseVourcher,
    permission: "ExpenseVourcher",
  },
  { path: "/SMSTemplate", component: SMSTemplate, permission: "SMSTemplate" },
  { path: "/SMSSend", component: SMSSend, permission: "SMSSend" },
];

function App() {
  const [userName, setUserName] = useState("");
  const [loginToken, setLoginToken] = useState(false);
  const [rolePermissionData, setRolePermissionData] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [WhatsAppStatusModelOpen, setWhatsAppStatusModel] = useState(false);
  const [defaultOpenPage, setDefaultOpenPage] = useState(null);

  useEffect(() => {
    const storedUserName = localStorage.getItem("userName");
    if (storedUserName) {
      setUserName(storedUserName);
      getDefaultOpenPage(storedUserName);
    }

    const storedToken = localStorage.getItem("token");
    setLoginToken(!!storedToken);

    getRolePermission();
  }, []);

  useEffect(() => {
    const WhatsAppData = localStorage.getItem("WhatsAppStatus");

    if (WhatsAppData == "true") {
      WhatsAppStatus()
        .then((WhatsAppStatus1) => {
          if (WhatsAppStatus1.message === "false") {
            // setWhatsAppStatusModel(true);
            return null;
          } else {
            const LoginURL = WhatsAppStatus1.data.smsLoginUrl;
            axios
              .post(LoginURL)
              .then((response) => {
                const WhatsAppActive = response.data.data.status;
                if (WhatsAppActive != "READY") {
                  Swal.fire({
                    title:
                      "Your Whatsapp Is Disconnected. Would you like to connect now? If so, please press YES",
                    type: "warning",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      window.open("/StatusOfWhatsApp");
                    }
                  });
                }
              })
              .catch((error) => {
                console.error("Error making POST request:", error);
              });
          }
        })
        .catch((error) => {
          console.error("Error fetching WhatsApp status:", error);
        });
      localStorage.setItem("WhatsAppStatus", false);
    }
  }, []);

  const getRolePermission = () => {
    api
      .get("RoleTemplateDetail/ViewPermissionWiseFormShow")
      .then((result) => {
        setRolePermissionData(result.data.data);
        setLoading(false); // Set loading to false once data is fetched
      })
      .catch((error) => {
        console.log(error);
        setLoading(false); // Set loading to false in case of an error
      });
  };

  const hasPermission = (permissionName) => {
    return rolePermissionData.some(
      (item) => item.actionName === permissionName && item.viewPermission
    );
  };

  const getDefaultOpenPage = (name) => {
    api
      .get(`UserTypeMaster/Usertype?UserName=${name}`)
      .then((result) => {
        ;
        const componentName = result.data.data;

        setDefaultOpenPage(componentName);
      })
      .catch((error) => {
        console.log(error);
      });
  };

 if (defaultOpenPage != null) {
  
  const defaultRoute = routeConfig.find(
    (route) => route.permission === defaultOpenPage
  )?.component;

  if (defaultRoute) {
    const page = {
      path: "/",
      component: defaultRoute,
      permission: defaultOpenPage,
    };
    routeConfig.push(page);
  } else {
    const page = {
      path: "/",
      component: Welcome,
      permission: "Welcome",
    };
    routeConfig.push(page);
  }
}

 

  return (
    <div className="App">
      {loginToken ? (
        <>
          <Modal
            show={WhatsAppStatusModelOpen}
            onHide={() => setWhatsAppStatusModel(false)}
            size="xl"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton closeVariant={"white"}>
              <Modal.Title id="example-custom-modal-styling-title">
                New SMSTemplate
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group>Hello</Form.Group>
            </Modal.Body>
          </Modal>
          <BrowserRouter>
            <Navbar userName={userName} />
            {loading ? (
              // Display a Bootstrap Spinner while data is being fetched
              <div className="overlay">
                <Spinner animation="" variant="primary">
                  <span class="loader"></span>
                </Spinner>
              </div>
            ) : (
              <Routes>
                {routeConfig.map(
                  (route) =>
                    hasPermission(route.permission) && (
                      <Route
                        key={route.path}
                        path={route.path}
                        element={<Protected Component={route.component} />}
                      />
                    )
                )}
                <Route
                  path="/logout"
                  element={<Protected Component={Logout} />}
                />
                <Route
                  path="*"
                  element={<Protected Component={PageNotFound} />}
                />
              </Routes>
            )}
          </BrowserRouter>
        </>
      ) : (
        <Login />
      )}
    </div>
  );
}

export default App;
