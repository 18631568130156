import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import api from "../../Utils/api";
import { Card } from "react-bootstrap";
import ExpenseMasterComponent from "./ExpenseMasterComponent";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";

export default function ExpenseMaster() {
  const [expenseMasterData, setExpenseMasterData] = useState([]);
  const [editExpenseMasterData, setEditExpenseMasterData] = useState(null);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getExpenseMaster();
    CheckRoleTemplatePermissions("ExpenseMaster")
    .then((obj) => {
      setRoleTemplatePermissions(obj);
    })
    .catch((error) => null);
  }, []);

  // Fetch all Areas
  const getExpenseMaster = () => {
    
    api
      .get("ExpenseMaster/AllExpenceMaster")
      .then((result) => {
        setExpenseMasterData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Get data of a single Area
  const getSingleExpenseMaster = (id) => {
    api
      .get(`ExpenseMaster/SingleExpenseMaster?ExpenseMasterID=${id}`)
      .then((result) => {
        const expenseMasterData = result.data.data;
        setEditExpenseMasterData(expenseMasterData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Delete Area
  const deleteArea = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`ExpenseMaster/DeleteExpenceMaster?ExpenceMasterID=${id}`)
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              getExpenseMaster();
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  };

  // AG Grid Table Column
  const columnDefs = [
    {
      headerName: "Expense Master Name",
      field: "expenseMasterName",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      flex: 1,
      minWidth: 150,
    },
    ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
      headerName: "Edit",
      width: 70,
      field: "expenseMasterID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="create-outline"
          class="iconsFonts editIcon"
          onClick={() => getSingleExpenseMaster(value)}
        ></ion-icon>
      ),
      pinned: "right",
    }, ]
    : []),
  ...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
    ? [
        {
      headerName: "Delete",
      width: 80,
      field: "expenseMasterID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="trash-outline"
          class="iconsFonts deleteIcon"
          onClick={() => deleteArea(value)}
        ></ion-icon>
      ),
      pinned: "right",
    },
  ]
: []),
];


  return (
    <>
      <ToastContainer />
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
      <Card>
        <Card.Header>
          <span>New Expense</span>
        </Card.Header>
        <Card.Body>
          <ExpenseMasterComponent
            expenseMasterID={getExpenseMaster}
            editedExpenseMasterData={editExpenseMasterData}
            getExpenseeMasterData={getSingleExpenseMaster}
          />
        </Card.Body>
      </Card>
      )}
      <Card>
        <Card.Header>
          <span>Expense Master List</span>
        </Card.Header>
        <Card.Body>
          <AgGridComponent
            rowData={expenseMasterData}
            columnDefs={columnDefs}
          />
        </Card.Body>
      </Card>
    </>
  );
}
