import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from "ag-grid-react";
import { useRef, useEffect } from "react";

const AgGridComponent = ({
  rowData,
  columnDefs,
  onSelectionChanged,
  autoGroupColumnDef,
  onGridReady,
  grid,
  createFooterData,
  gridOptions,
  pinnedBottomRowData,
  onFilterChanged,
  paginationPageSize = 20,
  height = 500
}) => {
  const gridRef = useRef();

  // useEffect(() => {
  //   if (gridRef.current && gridRef.current.api) {
  //     gridRef.current.api.paginationSetPageSize(paginationPageSize);
  //   }
  // }, [paginationPageSize]);

  return (
    <div className="ag-theme-quartz my-grid-margin" style={{height : height}}>
      <AgGridReact
        ref={gridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        pagination={true}
        paginationPageSize={paginationPageSize}
        rowSelection="multiple"
        animateRows={true}
        onGridReady={onGridReady}
        onSelectionChanged={onSelectionChanged}
        gridOptions={gridOptions}
        pinnedBottomRowData={pinnedBottomRowData}
        onFilterChanged={onFilterChanged}
        paginationPageSizeSelector={[paginationPageSize,10, 25, 50,200,500]}
        suppressServerSideFullWidthLoadingRow={true}
      />
    </div>
  );
};

export default AgGridComponent;
