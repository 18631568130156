import React from "react";
import logo from "../../assets/logo.png";
import { convertDateFormatWithOutTime } from "../../Utils/commonFunction";

const PrintInvoiceComponent = ({ InvoiceReceiveData }) => {
  const borderWithBottomNone = {
    border: "1px solid black",
    borderBottom: "none",
  };
  const borderWithBottomNonecolor = {
    border: "1px solid black",
    borderBottom: "none",
    background: "#e3e3e3",
  };

  const borderWithTopNone = {
    border: "1px solid black",
    borderTop: "none",
  };

  const TableBottomMargin = {
    marginBottom: "0px",
  };

  const NormalFontSize = {
    fontSize: "12px",
  };

  const convertToWords = (number) => {
    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "Ten",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    const convertChunk = (chunk) => {
      let result = "";
      const hundred = Math.floor(chunk / 100);
      const remainder = chunk % 100;

      if (hundred > 0) {
        result += units[hundred] + " Hundred ";
      }

      if (remainder > 0) {
        if (remainder < 10) {
          result += units[remainder];
        } else if (remainder < 20) {
          result += teens[remainder - 10];
        } else {
          const tensDigit = Math.floor(remainder / 10);
          const onesDigit = remainder % 10;
          result += tens[tensDigit] + " " + units[onesDigit];
        }
      }

      return result;
    };

    if (number === 0) {
      return "Zero";
    }

    const billion = Math.floor(number / 1000000000);
    const million = Math.floor((number % 1000000000) / 1000000);
    const thousand = Math.floor((number % 1000000) / 1000);
    const remainder = number % 1000;

    let result = "";

    if (billion > 0) {
      result += convertChunk(billion) + " Billion ";
    }

    if (million > 0) {
      result += convertChunk(million) + " Million ";
    }

    if (thousand > 0) {
      result += convertChunk(thousand) + " Thousand ";
    }

    if (remainder > 0) {
      result += convertChunk(remainder);
    }

    return result.trim();
  };

  return (
    <>
      <div className="">
        <table className="table" style={TableBottomMargin}>
          <tbody>
            {/* ======first col code ==================================================================================*/}
            <tr>
              <td
                rowSpan={4}
                style={{
                  width: "200px",
                  padding: "10px",
                  ...borderWithBottomNone,
                }}
                className=" align-middle "
              >
                <img src={logo} alt="Company Logo" style={{ width: "180px" }} />
              </td>
              <th
                colSpan={2}
                className="text-center "
                style={{ fontSize: "26px", ...borderWithBottomNone }}
              >
                <strong>TAPTI TECHNOLOGY</strong>
              </th>
            </tr>
            {/* ======company Information code ==================================================================================*/}
            <tr>
              <td
                style={{
                  width: "70%",
                  ...borderWithBottomNone,
                  ...NormalFontSize,
                }}
              >
                <div>
                  <strong>
                    <i className="fa fa-map-marker"></i>
                    &nbsp;Address:&nbsp;
                  </strong>
                  B-24, Paras Society, Katargam, Surat-395004
                </div>
                <div>
                  <strong>
                    <i className="fa fa-phone"></i>&nbsp;MO.No:&nbsp;
                  </strong>
                  8000008862
                </div>
                <div>
                  <strong>
                    <i className="fa fa-envelope"></i>&nbsp;email:&nbsp;
                  </strong>
                  info@taptitechnology.com
                </div>
                <div>
                  <strong>
                    <i className="fa fa-globe font16px"></i>&nbsp;Website:&nbsp;
                  </strong>
                  www.taptitechnology.com
                </div>
              </td>
              <td
                style={{
                  width: "250px",
                  ...borderWithBottomNone,
                  ...NormalFontSize,
                }}
              >
                <div>
                  <strong>&nbsp;PAN:&nbsp;</strong>AAOFT1428K
                </div>
                <div>
                  <strong>&nbsp;UAN:&nbsp;</strong>GJ22D0235811
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        {/* ======Second Table code ==================================================================================*/}

        <table className="table" style={TableBottomMargin}>
          <tr>
            <th
              colspan="3"
              style={borderWithBottomNonecolor}
              className="text-center"
            >
              <strong style={{ fontSize: "18px" }}>
                <i class="fa fa-file-text-o font16px"></i>&nbsp;INVOICE
              </strong>
            </th>
          </tr>

          {/* ======Client Information code ==================================================================================*/}
          <tr>
            <td
              style={{
                width: "50%",
                ...borderWithBottomNone,
                ...NormalFontSize,
              }}
            >
              <div style={{ fontSize: "16px" }}>
                <strong>&nbsp;ClientName:&nbsp;</strong>
                {InvoiceReceiveData.clientName}&nbsp;&nbsp;
              </div>
              <div style={{ fontSize: "16px" }}>
                <strong>&nbsp;MobileNumber:&nbsp;</strong>
                {InvoiceReceiveData.clientMobileNumber}
              </div>
              <div style={{ fontSize: "16px" }}>
                <strong>&nbsp;Address:&nbsp;</strong>
                {InvoiceReceiveData.address}
              </div>
            </td>
            {/* ======Invoice Information code ==================================================================================*/}

            <td
              style={{
                width: "50%",
                ...borderWithBottomNone,
                ...NormalFontSize,
              }}
            >
              <div style={{ fontSize: "16px" }}>
                <strong>&nbsp;InvoiceRecevie No:&nbsp;</strong>
                {InvoiceReceiveData.invoiceReceiveNo}
              </div>
              <div style={{ fontSize: "16px" }}>
                <strong>&nbsp;InvoiceRecevie Date:&nbsp;</strong>
                {convertDateFormatWithOutTime(
                  InvoiceReceiveData.invoiceReceiveDate
                )}
              </div>
            </td>
          </tr>
          {/* ======charge table code ==================================================================================*/}
        </table>

        {/* ======Amount In Word div code ==================================================================================*/}
        <table className="table" style={TableBottomMargin}>
          {/* ======Amount div code ==================================================================================*/}

          {/* ======Bank Details div code ==================================================================================*/}

          <tr>
            <td
              colSpan={3}
              style={{
                border: "1px solid black",
                ...NormalFontSize,
                fontSize: "16px",
              }}
            >
              <h5>{`"Received Amount Rs.${InvoiceReceiveData.amount} /- By ${
                InvoiceReceiveData.paymentName
              } 
Received with thanks a sum of ${convertToWords(
                InvoiceReceiveData.amount
              )} Against Invoice No: ${InvoiceReceiveData.invoiceNo}" `}</h5>
            </td>
          </tr>
          {/* ======Terms & Conditions. div code ==================================================================================*/}
          {InvoiceReceiveData.remarks && (
            <tr>
              <td
                style={{
                  ...borderWithTopNone,
                  ...NormalFontSize,
                  fontSize: "16px",
                }}
                colSpan={3}
              >
                <div>
                  <strong>&nbsp;Remarks:&nbsp;</strong>
                  {InvoiceReceiveData.remarks}
                </div>
              </td>
            </tr>
          )}
          {/* ======Last line  code ==================================================================================*/}

          <tr>
            <td
              style={{
                width: "50%",
                border: "1px solid",
                ...NormalFontSize,
              }}
            >
              <div
                className="text-start"
                style={{ marginTop: "60px", fontSize: "16px" }}
              >
                <strong>&nbsp;For, CLIENT SIGNATURE&nbsp;</strong>
              </div>
            </td>
            {/* ======Invoice Information code ==================================================================================*/}

            <td
              style={{
                width: "50%",
                border: "1px solid",
                ...NormalFontSize,
              }}
            >
              <div
                className="text-end"
                style={{ marginTop: "60px", fontSize: "16px" }}
              >
                <strong>&nbsp;For, TAPTI TECHNOLOGY &nbsp;</strong>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </>
  );
};

export default PrintInvoiceComponent;
