import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, Form, Button, Modal, Col, Row } from "react-bootstrap";
import OpportunityComponent from "./OpportunityComponent";
import PipelineDDComponent from "../Pipeline/PipelineDDComponent";
import ClientDDComponent from "../Client/ClientDDComponent";
import OpportunityDataComponent from "./OpportunityDataComponent";
import AreaDDComponent from "../Area/AreaDDComponent";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";

const emptyOpportunity = {
  Pipeline_id: 0,
  Client_id: 0,
  area_id: 0,
};

export default function Opportunity() {
  const [validated, setValidated] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [formData, setFormData] = useState(emptyOpportunity);
  const [showOpportunity, setShowOpportunity] = useState(false);
  const [showOpportunityDataComponent, setShowOpportunityDataComponent] =
    useState(false);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  const ClearDataAndDD = () => {
    setRefreshKey((prevKey) => prevKey + 1);
    setShowOpportunityDataComponent(false);
    setFormData(emptyOpportunity);
  };

  useEffect(() => {
    CheckRoleTemplatePermissions("Opportunity")
      .then((obj) => {
        setRoleTemplatePermissions(obj);
      })
      .catch((error) => null);
  }, []);

  return (
    <>
      <ToastContainer />
      <Card>
        <Card.Header>
          <Row>
            <Col
              style={{
                textAlign: "start",
                fontSize: "16px",
                padding: "6px",
                display: "inline-block",
                fontWeight: "bold  ",
              }}
            >
              <span> Opportunity</span>
            </Col>

            <Col style={{ textAlign: "end" }}>
              {RoleTemplatePermissions &&
                RoleTemplatePermissions.addPermission && (
                  <Button
                    className="mr-2 outline-primaryOfTapti"
                    onClick={() => setShowOpportunity(true)}
                  >
                    New +
                  </Button>
                )}
              <Button
                onClick={ClearDataAndDD}
                className="mx-2 outline-primaryOfTapti"
              >
                <i className="fa fa-refresh"></i>
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Form noValidate validated={validated}>
            <Row>
              <PipelineDDComponent
                onSelectPipeline={(pipelineID) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    Pipeline_id: pipelineID,
                  }));
                }}
                selectedPipeline={formData.Pipeline_id}
                key={`PipelineDDComponent-${refreshKey}`}
                onAdditionalChange={() => setShowOpportunityDataComponent(true)}
              />
              <ClientDDComponent
                onSelectClient={(clientID) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    Client_id: clientID,
                  }));
                }}
                selectedClient={formData.Client_id}
                key={`ClientDDComponent-${refreshKey}`}
                onAdditionalChange={() => setShowOpportunityDataComponent(true)}
              />
              <AreaDDComponent
                onSelectArea={(areaID) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    area_id: areaID,
                  }));
                }}
                selectedArea={formData.area_id}
                key={`AreaDDComponent-${refreshKey}`}
                onAdditionalChange={() => setShowOpportunityDataComponent(true)}
              />
            </Row>
          </Form>
        </Card.Body>
      </Card>

      {showOpportunityDataComponent && (
        <OpportunityDataComponent
          clientID={formData.Client_id}
          pipelineID={formData.Pipeline_id}
          areaID={formData.area_id}
        />
      )}

      <Modal
        show={showOpportunity}
        onHide={() => setShowOpportunity(false)}
        size="xl"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton closeVariant={"white"}>
          <Modal.Title id="example-custom-modal-styling-title">
            New Opportunity
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OpportunityComponent setShowOpportunity={setShowOpportunity} />
        </Modal.Body>
      </Modal>
    </>
  );
}
