import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import api,{handleApiError} from "../../Utils/api";
import { Card } from "react-bootstrap";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import DeveloperComponent from "./DeveloperComponent"
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";

export default function Developer() {
  const [DeveloperData, setDeveloperData] = useState([]);
  const [editDeveloperData, setEditDeveloperData] = useState(null);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getDeveloper();
    CheckRoleTemplatePermissions("Developer")
    .then((obj) => {
      setRoleTemplatePermissions(obj);
    })
    .catch((error) => null);
  }, []);

  const notifySuccess = (message) => toast.success(message);

  const getDeveloper = () => {
    api
      .get("Developer/AllDeveloper")
      .then(({ data }) => setDeveloperData(data.data))
      .catch(handleApiError);
  };

  const getSingleDeveloper = (id) => {
    
    api
      .get(`Developer/SingleDeveloper?DeveloperID=${id}`)
      .then(({ data }) => setEditDeveloperData(data.data))
      .catch(handleApiError);
  };

  const deleteDeveloper = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`Developer/DeleteDeveloper?DeveloperID=${id}`)
          .then(({ status }) => {
            if (status === 200) {
              notifySuccess("Your record has been deleted.");
              getDeveloper();
            }
          })
          .catch(handleApiError);
      }
    });
  };

  const columnDefs = [
    {
      headerName: "Developer  Name",
      field: "developerName",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      flex: 1,
      minWidth:150
    },
    ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
      headerName: "Edit",
      width: 70,
      field: "developerID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="create-outline"
          class="iconsFonts editIcon"
          onClick={() => getSingleDeveloper(value)}
        ></ion-icon>
      ),
      pinned:"right"
    }, ]
    : []),
  ...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
    ? [
        {
      headerName: "Delete",
      width: 80,
      field: "developerID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="trash-outline"
          class="iconsFonts deleteIcon"
          onClick={() => deleteDeveloper(value)}
        ></ion-icon>
      ),
      pinned:"right"
    },
  ]
: []),
];


  return (
    <>
      <ToastContainer />
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
      <Card>
        <Card.Header>
          <span>New Developer</span>
        </Card.Header>
        <Card.Body>
          <DeveloperComponent
            DeveloperID={getDeveloper}
            editedDeveloperData={editDeveloperData}
            getDeveloperData={getSingleDeveloper}
          />
        </Card.Body>
      </Card>
      )}
      <Card>
        <Card.Header>
          <span>Developer List</span>
        </Card.Header>
        <Card.Body>
          <AgGridComponent rowData={DeveloperData} columnDefs={columnDefs} />
        </Card.Body>
      </Card>
    </>
  );
}
