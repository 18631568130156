import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api, { handleApiError } from "../../Utils/api";
import {
  Form,
  Button,
  Col,
  Row,
  InputGroup,
} from "react-bootstrap";

const emptySMSApi = {
  SMSApi_id: 0,
  SMSApi_CompanyName: "",
  RequestType: "",
  HeaderField: "",
  BodyText: "",
  SMSUrl: "",
  SMSBalanceUrl: "",
  SMSLoginUrl: "",
};

export default function SMSApiComponent({
  SMSApiID,
  editedSMSApiData,
  getSMSApiData,
}) {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptySMSApi);

  const SMSApiFormRef = useRef(null);

  useEffect(() => {
    if (editedSMSApiData) {
      setFormData({
        SMSApi_id: editedSMSApiData.smsApiID,
        SMSApi_CompanyName: editedSMSApiData.smsCompanyName,
        RequestType: editedSMSApiData.requestType,
        HeaderField: editedSMSApiData.headerField,
        BodyText: editedSMSApiData.bodyText,
        SMSUrl: editedSMSApiData.smsUrl,
        SMSBalanceUrl: editedSMSApiData.smsBalanceUrl,
        SMSLoginUrl: editedSMSApiData.smsLoginUrl,
      });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [editedSMSApiData]);

  const handleInputChange = (event) => {
    const { name, value} = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const insertOrUpdateSMSApi = (event) => {
    
    setValidated(true);
    event.preventDefault();

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }
    const data = {
      SMSApiID: formData?.SMSApi_id || 0,
      SMSCompanyName: formData.SMSApi_CompanyName,
      SMSUrl: formData.SMSUrl,
      SMSBalanceUrl: formData.SMSBalanceUrl,
      RequestType: formData.RequestType,
      HeaderField: formData.HeaderField,
      BodyText: formData.BodyText,
      SMSLoginUrl: formData.SMSLoginUrl,
    };

    const endpoint =
      formData.SMSApi_id === 0 ? "SMSApi/InsertSMSApi" : "SMSApi/EditSMSApi";

    api[formData.SMSApi_id === 0 ? "post" : "patch"](endpoint, data)
      .then((result) => {
        if (result.status === 200) {
          toast.success(result.data.message);
          if (SMSApiID) {
            SMSApiID(result.data.data);
          }
        } else {
          toast.error(result.data.message);
        }
      })
      .catch(handleApiError);

    resetForm();
    setValidated(false);
  };

  const resetForm = () => {
    setFormData(emptySMSApi);
  };

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={(e) => {
        if (e) {
          e.preventDefault();
        }

        if (SMSApiFormRef.current && e) {
          if (e.target.name === "SMSApiForm") {
            insertOrUpdateSMSApi(e);
          }
        }
      }}
      ref={SMSApiFormRef}
      name="SMSApiForm"
    >
      <Row>
        <Col md={2} className="text-end">
          <Form.Label>SMSApi Name:</Form.Label>
        </Col>
        <Col md={6} sm={6} lg={6}>
          <Form.Group controlId="SMSApi_Name">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <i className="fa fa-user"></i>
              </InputGroup.Text>
              <Form.Control
                type="text"
                name="SMSApi_CompanyName"
                placeholder="Enter CompanyName"
                value={formData.SMSApi_CompanyName}
                onChange={handleInputChange}
                required
              />
              {validated && !formData.SMSApi_CompanyName && (
                <div className="invalid-feedback">
                  Please Enter SMSApi Name.
                </div>
              )}
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={2} className="text-end">
          <Form.Label>Request Type:</Form.Label>
        </Col>
        <Col md={6} sm={6} lg={6}>
          <Form.Group controlId="SMSApi_Name">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <i className="fa fa-send"></i>
              </InputGroup.Text>
              <Form.Select
                name="RequestType"
                value={formData.RequestType}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Select Request Type
                </option>
                <option value="GET">GET</option>
                <option value="POST">POST</option>
              </Form.Select>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={2} className="text-end">
          <Form.Label>Header Field:</Form.Label>
        </Col>
        <Col md={6} sm={6} lg={6}>
          <Form.Group controlId="HeaderField">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <i className="fa fa-file"></i>
              </InputGroup.Text>
              <Form.Control
                as="textarea" // Use textarea
                rows={2} // Specify the number of rows you want
                name="HeaderField" // Using camelCase
                placeholder="Enter Header Field"
                value={formData.HeaderField}
                onChange={handleInputChange}
              />
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={2} className="text-end">
          <Form.Label>Body Text:</Form.Label>
        </Col>
        <Col md={6} sm={6} lg={6}>
          <Form.Group controlId="BodyText">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <i className="fa fa-file"></i>
              </InputGroup.Text>
              <Form.Control
                as="textarea" // Use textarea
                rows={2} // Specify the number of rows you want
                name="BodyText" // Using camelCase
                placeholder="Enter Header Field"
                value={formData.BodyText}
                onChange={handleInputChange}
              />
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={2} className="text-end">
          <Form.Label>SMSUrl:</Form.Label>
        </Col>
        <Col md={6} sm={6} lg={6}>
          <Form.Group controlId="SMSApi_Name">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                @
              </InputGroup.Text>
              <Form.Control
                type="text"
                name="SMSUrl"
                value={formData.SMSUrl}
                onChange={handleInputChange}
                required
              />
              {validated && !formData.SMSUrl && (
                <div className="invalid-feedback">
                  Please Enter SMSApi Name.
                </div>
              )}
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={2} className="text-end">
          <Form.Label>SMS BalanceUrl:</Form.Label>
        </Col>
        <Col md={6} sm={6} lg={6}>
          <Form.Group controlId="SMSApi_Name">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
               @
              </InputGroup.Text>
              <Form.Control
                type="text"
                name="SMSBalanceUrl"
                value={formData.SMSBalanceUrl}
                onChange={handleInputChange}
                required
              />
              {validated && !formData.SMSBalanceUrl && (
                <div className="invalid-feedback">
                  Please Enter SMSApi Name.
                </div>
              )}
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={2} className="text-end">
          <Form.Label>SMS Login Url:</Form.Label>
        </Col>
        <Col md={6} sm={6} lg={6}>
          <Form.Group controlId="SMSApi_Name">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
               @
              </InputGroup.Text>
              <Form.Control
                type="text"
                name="SMSLoginUrl"
                value={formData.SMSLoginUrl}
                onChange={handleInputChange}
                required
              />
              {validated && !formData.SMSLoginUrl && (
                <div className="invalid-feedback">
                  Please Enter SMSApi Name.
                </div>
              )}
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>

      <div className="card-footer margin_top10">
        <Button type="submit" variant="primary" className="mb-20px">
          {formData?.SMSApi_id > 0 ? "Update" : "Save"}
        </Button>
      </div>
    </Form>
  );
}
