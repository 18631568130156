import React, { useState, useEffect } from "react";
import api from "../../Utils/api";
import { Form, Col, InputGroup, Modal } from "react-bootstrap";
import ExpenseMasterComponent from "../ExpenseMaster/ExpenseMasterComponent";
import Select from "react-select";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";

export default function ExpenseMasterDDComponent({
  onSelectExpenseMaster,
  selectedExpenseMaster,
  onAdditionalChange,
  ValidationError,
}) {
  const [expenseMasterOptions, setExpenseMasterOptions] = useState([]);
  const [isExpenseMasterModalOpen, setIsExpenseMasterModalOpen] =
    useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [validation, setValidation] = useState(false);
  const [error, setError] = useState(null);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getExpenseMasterName();
    CheckRoleTemplatePermissions("ExpenseMaster")
      .then((obj) => {
        setRoleTemplatePermissions(obj);
      })
      .catch((error) => null);
  }, []);

  useEffect(() => {
    if (ValidationError && selectedExpenseMaster === 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  }, [ValidationError, selectedExpenseMaster]);

  useEffect(() => {
    if (selectedExpenseMaster) {
      const selectedOption = expenseMasterOptions.find(
        (option) => option.expenseMasterID === selectedExpenseMaster
      );
      setSelectedOption({
        value: selectedOption?.expenseMasterID,
        label: selectedOption?.expenseMasterName,
      });
      setValidation(false);
    }
  }, [selectedExpenseMaster, expenseMasterOptions]);

  const getExpenseMasterName = () => {
    api
      .get("ExpenseMaster/AllExpenceMaster")
      .then((result) => {
        setExpenseMasterOptions(result.data.data);
      })
      .catch((error) => {
        setError("Error fetching expense master names");
      });
  };

  const handleExpenseMasterChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onSelectExpenseMaster(selectedOption ? selectedOption.value : null);
    if (onAdditionalChange) {
      onAdditionalChange({ onAdditionalChange });
    }
  };

  const ExpenseMasterModalOpen = () => {
    setIsExpenseMasterModalOpen(true);
  };

  const closeExpenseMasterModal = () => {
    setIsExpenseMasterModalOpen(false);
  };

  const selectDDExpenseMasterID = (expenseMasterID) => {
    handleExpenseMasterChange({ value: expenseMasterID });
    getExpenseMasterName();
    closeExpenseMasterModal();
  };

  return (
    <>
      <Col md={4} sm={4} lg={4}>
        <Form.Group controlId="expenseMaster_id">
          <Form.Label>ExpenseMaster Name:</Form.Label>
          <div className="mb-3 d-flex justify-content-between">
            <div className="selectStartIcon input-group-text" id="basic-addon1">
              <i className="fas fa-building"></i>
            </div>
            <Select
              className={`w-100 ${validation ? "borderRed" : ""} ${
                RoleTemplatePermissions?.addPermission
                  ? "RadiusNo"
                  : "RadiusYes"
              }`}
              options={expenseMasterOptions.map((option) => ({
                value: option.expenseMasterID,
                label: option.expenseMasterName,
              }))}
              value={selectedOption}
              onChange={handleExpenseMasterChange}
              placeholder="ExpenseMaster Name"
              required
            />
            {RoleTemplatePermissions &&
              RoleTemplatePermissions.addPermission && (
                <div className="selectEndIcon input-group-text">
                  <i
                    className="fa fa-plus plusIcon"
                    onClick={ExpenseMasterModalOpen}
                  ></i>
                </div>
              )}
          </div>
          {validation && (
            <div className="ValidationErrorMsg">
              Please select an ExpenseMaster.
            </div>
          )}
          {error && <div className="ErrorMessage">{error}</div>}
        </Form.Group>
      </Col>
      {/* ExpenseMaster Modal */}
      <Modal
        show={isExpenseMasterModalOpen}
        onHide={closeExpenseMasterModal}
        size="xl"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton closeVariant={"white"}>
          <Modal.Title id="example-custom-modal-styling-title">
            New ExpenseMaster
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <ExpenseMasterComponent
              expenseMasterID={selectDDExpenseMasterID}
            ></ExpenseMasterComponent>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
}
