import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api, { handleApiError } from "../../Utils/api";
import { Form, Button, Col, Row, InputGroup } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const emptyEmailTemplate = {
  EmailTemplate_id: 0,
  EmailTemplate_Name: "",
  Email_Subject: "",
  Email_Body: "",
  CCEmail: "",
  IsActive: true,
};

export default function EmailTemplateComponent({
  EmailTemplateID,
  editedEmailTemplateData,
  getEmailTemplateData,
}) {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyEmailTemplate);
  const [refreshKey, setRefreshKey] = useState(0);
  const [SMSApiDDValidationError, setSMSApiValidationError] = useState(false);

  const EmailTemplateFormRef = useRef(null);

  useEffect(() => {
    if (editedEmailTemplateData) {
      setFormData({
        EmailTemplate_id: editedEmailTemplateData.emailTemplateID,
        EmailTemplate_Name: editedEmailTemplateData.emailTemplateName,
        Email_Subject: editedEmailTemplateData.emailSubject,
        Email_Body: editedEmailTemplateData.emailBody,
        CCEmail: editedEmailTemplateData.ccEmail,
        IsActive: editedEmailTemplateData.isActive,
      });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [editedEmailTemplateData]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
  };

  const insertOrUpdateEmailTemplate = (event) => {
    setValidated(true);
    event.preventDefault();

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }
    ;
    const data = {
      EmailTemplateID: formData?.EmailTemplate_id || 0,
      EmailTemplateName: formData.EmailTemplate_Name,
      EmailSubject: formData.Email_Subject,
      EmailBody: formData.Email_Body,
      CCEmail: formData.CCEmail,
      IsActive: formData.IsActive,
    };

    const endpoint =
      formData.EmailTemplate_id === 0
        ? "EmailTemplate/InsertEmailTemplate"
        : "EmailTemplate/EditEmailTemplate";

    api[formData.EmailTemplate_id === 0 ? "post" : "patch"](endpoint, data)
      .then((result) => {
        if (result.status === 200) {
          toast.success(result.data.message);
          if (EmailTemplateID) {
            EmailTemplateID(result.data.data);
          }
        } else {
          toast.error(result.data.message);
        }
        setRefreshKey((prevKey) => prevKey + 1);
      })
      .catch(handleApiError);

    resetForm();
    setValidated(false);
  };

  const resetForm = () => {
    setFormData(emptyEmailTemplate);
  };

  return (
    <>
      {" "}
      <Form
        noValidate
        validated={validated}
        onSubmit={(e) => {
          if (e) {
            e.preventDefault();
          }

          if (EmailTemplateFormRef.current && e) {
            if (e.target.name === "EmailTemplateForm") {
              insertOrUpdateEmailTemplate(e);
            }
          }
        }}
        ref={EmailTemplateFormRef}
        name="EmailTemplateForm"
      >
        <Row>
          <Col md={4} sm={2} lg={4}>
            <Form.Group controlId="Client_MobileNumber">
              <Form.Label>Template Name:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i class="fas fa-tag"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="EmailTemplate_Name"
                  placeholder="Enter Template Name"
                  value={formData.EmailTemplate_Name}
                  onChange={handleInputChange}
                  required
                />
                {validated && !formData.EmailTemplate_Name && (
                  <div className="invalid-feedback">
                    Please Enter Template Name.
                  </div>
                )}
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={4} sm={2} lg={4}>
            <Form.Group controlId="Client_MobileNumber">
              <Form.Label>CC Email:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i class="fas fa-mobile"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="CCEmail"
                  placeholder="Enter CC Number"
                  value={formData.CCEmail}
                  onChange={handleInputChange}
                  required
                />
                {validated && !formData.CCEmail && (
                  <div className="invalid-feedback">Please Enter CCEmail.</div>
                )}
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={8} sm={8} lg={8}>
            <Form.Group controlId="HeaderField">
              <Form.Label> Email Subject</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fa fa-commenting"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="Email_Subject"
                  placeholder="Enter  Email Subject"
                  value={formData.Email_Subject}
                  onChange={handleInputChange}
                  required
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={8} sm={8} lg={8}>
            <Form.Group controlId="HeaderField">
              <Form.Label>Email Body</Form.Label>
              <div style={{ height: "400px", width: "100%" }}>
                <CKEditor
                  editor={ClassicEditor}
                  data={formData.Email_Body}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    handleInputChange({
                      target: { name: "Email_Body", value: data },
                    });
                  }}
                />
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="isActive">
              <Form.Label></Form.Label>
              <Form.Check
                type="checkbox"
                label="Is Active"
                name="IsActive"
                checked={formData.IsActive}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <div className="card-footer margin_top10">
          <Button type="submit" variant="primary" className="mb-20px">
            {formData?.EmailTemplate_id > 0 ? "Update" : "Save"}
          </Button>
        </div>
      </Form>
    </>
  );
}
