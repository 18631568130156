import React, { useState, useEffect } from "react";
import api, { handleApiError } from "../../Utils/api";
import { Form, Col, InputGroup, Modal } from "react-bootstrap";
import PipelineStageComponent from "./PipelineStageComponent";
import Select from "react-select";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";

export default function PipelineStageDDComponent({
  onSelectPipelineStage,
  selectedPipelineStage,
  ValidationError,
}) {
  const [PipelineStageOptions, setPipelineStageOptions] = useState([]);
  const [isPipelineStageModalOpen, setIsPipelineStageModalOpen] =
    useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [validation, setValidation] = useState(false);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getPipelineStageName();
    CheckRoleTemplatePermissions("PipelineStage")
      .then((obj) => {
        setRoleTemplatePermissions(obj);
      })
      .catch((error) => null);
  }, []);

  useEffect(() => {
    if (ValidationError && selectedPipelineStage === 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  }, [ValidationError, selectedPipelineStage]);

  useEffect(() => {
    // Set initial value for the dropdown during edit time
    if (selectedPipelineStage) {
      const selectedOption = PipelineStageOptions.find(
        (option) => option.pipelineStageID === selectedPipelineStage
      );
      setSelectedOption({
        value: selectedOption?.pipelineStageID, // Add a check for undefined
        label: selectedOption?.pipelineStageName, // Add a check for undefined
      });
      setValidation(false);
    }
  }, [selectedPipelineStage, PipelineStageOptions]);

  // Fetch all City Name
  const getPipelineStageName = () => {
    api
      .get("PipelineStage/AllPipelineStage")
      .then(({ data }) => setPipelineStageOptions(data.data))
      .catch(handleApiError);
  };

  const handlePipelineStageChange = (option) => {
    setSelectedOption(option);
    onSelectPipelineStage(option ? option.value : null);
  };

  // Insert New State
  const PipelineStageModalOpen = () => {
    setIsPipelineStageModalOpen(true);
  };

  const closePipelineStageModal = () => {
    setIsPipelineStageModalOpen(false);
  };

  const selectDDPipelineStageID = (PipelineStageID) => {
    handlePipelineStageChange({ value: PipelineStageID });
    getPipelineStageName();
    closePipelineStageModal();
  };

  return (
    <>
      <Col md={4} sm={4} lg={4}>
        <Form.Group controlId="city_id">
          <Form.Label>Pipeline Stage Name:</Form.Label>
          <div className="mb-3 d-flex justify-content-between">
            <div className="selectStartIcon input-group-text" id="basic-addon1">
              <i className="fas fa-star"></i>
            </div>
            <Select
              className={`w-100 ${validation ? "borderRed" : ""} ${
                RoleTemplatePermissions?.addPermission
                  ? "RadiusNo"
                  : "RadiusYes"
              }`}
              options={PipelineStageOptions.map((option) => ({
                value: option.pipelineStageID,
                label: option.pipelineStageName,
              }))}
              value={selectedOption}
              onChange={handlePipelineStageChange}
              placeholder=" PipelineStage Name"
              required
            />
            {RoleTemplatePermissions &&
              RoleTemplatePermissions.addPermission && (
                <div className="selectEndIcon input-group-text">
                  <i
                    className="fa fa-plus plusIcon"
                    onClick={PipelineStageModalOpen}
                  ></i>
                </div>
              )}
          </div>
          {validation && (
            <div className="ValidationErrorMsg">
              Please select an PipelineStage.
            </div>
          )}
        </Form.Group>
      </Col>

      {/* City Modal */}
      <Modal
        show={isPipelineStageModalOpen}
        onHide={closePipelineStageModal}
        size="xl"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton closeVariant={"white"}>
          <Modal.Title id="example-custom-modal-styling-title">
            New PipelineStage
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <PipelineStageComponent
              pipelineStageID={selectDDPipelineStageID}
            ></PipelineStageComponent>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
}
