import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api, { handleApiError } from "../../Utils/api";
import { Form, Button, Col, Row, InputGroup } from "react-bootstrap";
import PipelineDDComponent from "../Pipeline/PipelineDDComponent";

const emptyPipelineStage = {
  PipelineStage_id: 0,
  PipelineStage_Name: "",
 Pipeline_id : 0,
  IsActive: true,
  Display_Priority: 0,
};

export default function PipelineStageComponent({
  PipelineStageID,
  editedPipelineStageData,
  getPipelineStageData,
}) {
  const [refreshKey, setRefreshKey] = useState(0);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyPipelineStage);
  const PipelineStageFormRef = useRef(null);
 const [pipelineDDValidation,setPipelineDDValidation] = useState(false);

  useEffect(() => {
    if (editedPipelineStageData) {
      setFormData({
        PipelineStage_id: editedPipelineStageData.pipelineStageID,
        PipelineStage_Name: editedPipelineStageData.pipelineStageName,
        Pipeline_id: editedPipelineStageData.pipelineID,
        Display_Priority: editedPipelineStageData.displayPriority,
        IsActive: editedPipelineStageData.isActive,
      });
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }
  }, [editedPipelineStageData]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    // Use the checked property for checkboxes
    const inputValue = type === "checkbox" ? checked : value;

    setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
  };

  const insertOrUpdatePipelineStage = (event) => {
    setValidated(true);
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setPipelineDDValidation(formData.Pipeline_id === 0);
      return;
    }

    const data = {
      PipelineStageID: formData?.PipelineStage_id || 0,
      PipelineStageName: formData.PipelineStage_Name,
      PipelineID: formData.Pipeline_id,
      DisplayPriority: formData.Display_Priority,
      IsActive: formData.IsActive,
    };

    const endpoint =
      formData.PipelineStage_id === 0
        ? "PipelineStage/InsertPipelineStage"
        : "PipelineStage/EditPipelineStage";

    api[formData.PipelineStage_id === 0 ? "post" : "patch"](endpoint, data)
      .then((result) => {
        if (result.status === 200) {
          setPipelineDDValidation(false);
          toast.success(result.data.message);
          PipelineStageID(result.data.data);
        } else {
          toast.error(result.data.message);
        }
        setRefreshKey((prevKey) => prevKey + 1);
      })
      .catch(handleApiError);

    resetForm();
    setValidated(false);
  };

  const resetForm = () => {
    
    setFormData(emptyPipelineStage);
  };

  return (
    <>
      <Form
        noValidate
        validated={validated}
        onSubmit={(e) => {
          if (e) {
            e.preventDefault();
          }

          if (PipelineStageFormRef.current && e) {
            if (e.target.name === "PipelineStageForm") {
              insertOrUpdatePipelineStage(e);
            }
          }
        }}
        ref={PipelineStageFormRef}
        name="PipelineStageForm"
      >
        <Row>
          <PipelineDDComponent
            onSelectPipeline={(pipelineID) => {
              setFormData((prevData) => ({
                ...prevData,
                Pipeline_id: pipelineID,
              }));
             setPipelineDDValidation(false);
            }}
            selectedPipeline={formData.Pipeline_id}
            key={refreshKey}
            ValidationError={pipelineDDValidation}
          />
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="PipelineStage_Name">
              <Form.Label>Pipeline Stage Name:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fa fa-star"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="PipelineStage_Name"
                  placeholder="Enter PipelineStage Name"
                  value={formData.PipelineStage_Name}
                  onChange={handleInputChange}
                  required
                />
                {validated && !formData.PipelineStage_Name && (
                  <div className="invalid-feedback">
                    Please Enter PipelineStage Name.
                  </div>
                )}
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="Display_Priority">
              <Form.Label>Display Priority:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fa fa-star"></i>
                </InputGroup.Text>
                <Form.Control
                  type="number"
                  name="Display_Priority"
                  placeholder="Display Priority"
                  value={formData.Display_Priority}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="IsActive">
              <Form.Label></Form.Label>
              <Form.Check
                type="checkbox"
                label="Is Active"
                name="IsActive"
                checked={formData.IsActive}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <div className="card-footer margin_top10">
          <Button type="submit" variant="primary" className="mb-20px">
            {formData?.PipelineStage_id > 0 ? "Update" : "Save"}
          </Button>
        </div>
      </Form>
    </>
  );
}
