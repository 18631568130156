import React, { useState,useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import {
  Card,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Table,
  Modal,
  Dropdown,
} from "react-bootstrap";
import api, { handleApiError } from "../../Utils/api";
import TypeaheadComponent from "../../Components/Common/TypeaheadComponent";
import SMSApiDDComponent from "../SMSApi/SMSApiDDComponent";
import SMSTemplateDDComponent from "../SMSTemplate/SMSTemplateDDComponent";
import { json } from "react-router";
import { getCurrentDate } from "../../Utils/commonFunction";

const DateTimeFilterData = {
  start_date: getCurrentDate(),
  end_date: getCurrentDate(),
  ClientID: 0,
};

export default function SMSSend() {
  const [selectedSMSApiID, setSelectedSMSApiID] = useState(0);
  const [selectedSMSTemplateID, setSelectedSMSTemplateID] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [refreshKeyOfSMSAPi, setRefreshKeyOfSMSAPi] = useState(0);
  const [refreshKeyOfSMSTemplate, setRefreshKeyOfSMSTemplate] = useState(0);
  const [newMobileNumber, setNewMobileNumber] = useState("");
  const [msgOfSMS, setMsgOfSMS] = useState("");
  const [isSelectClientModalOpen, setIsSelectClientModalOpen] = useState(false);
  const [formData, setFormData] = useState(DateTimeFilterData);
  const [selectedType, setSelectedType] = useState("Client");
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedImageForDisplay, setSelectedImageForDisplay] = useState("");


  const imageInputRef = useRef(null);

  const handleClientSelection = (selectedClient) => {
    api
      .get(`Client/SingleClient?ClientID=${selectedClient}`)
      .then(({ data }) => {
        setTableData((prevData) => [...prevData, data.data]);
        setRefreshKey((prevKey) => prevKey + 1);
      })
      .catch(handleApiError);
  };

  const ClientOrLeadData = (type) => {
    api
      .get(`SMSSend/ClientOrLeadList?type=${type}`)
      .then(({ data }) =>
        setTableData((prevData) => [...prevData, ...data.data])
      )
      .catch(handleApiError);
  };

  const removeRow = (rowNumber) => {
    const updatedList = tableData.filter((_, index) => index !== rowNumber);
    setTableData(updatedList);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewMobileNumber(value);
  };

  const handleInputChangeModel = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const addMobileNumber = () => {
    setTableData((prevData) => [
      ...prevData,
      { clientMobileNumber: newMobileNumber },
    ]);
    setNewMobileNumber("");
  };

  const getClient = () => {
    api
      .get(
        `Client/AllClientDateWise?START_DATE=${formData.start_date}&END_DATE=${formData.end_date}&Type=${selectedType}&ClientID=${formData.ClientID}`
      )
      .then(({ data }) => {
        setTableData((prevData) => [...prevData, ...data.data]);
        setIsSelectClientModalOpen(false);
      })
      .catch(handleApiError);
  };
  const getSMSTemplate = (id) => {
    api
      .get(`SMSTemplate/SingleSMSTemplate?SMSTemplateID=${id}`)
      .then(({ data }) => setMsgOfSMS(data.data.smsText))
      .catch(handleApiError);
  };

  const handleTypeChange = (selectedValue) => {
    setSelectedType(selectedValue);
  };

  const SendSMS = () => {
    const mobileNumbersArray = tableData.map((item) => item.clientMobileNumber);
    const formattedNumbersString = mobileNumbersArray.join(",");

    if (formattedNumbersString == null || formattedNumbersString == "") {
      toast.error("select MobileNumber");
      return;
    }
    if (selectedSMSApiID == 0) {
      toast.error("select SMSApi");
      return;
    }
    if (msgOfSMS == null || msgOfSMS == "") {
      toast.error("Type Msg");
      return;
    }

    const formData = new FormData();
    formData.append('MobileNoList', formattedNumbersString);
    formData.append('Msg', msgOfSMS);
    formData.append('SMSApiID', selectedSMSApiID);
    
    // Check if an image file is selected
    if (selectedImage) {
      formData.append('Files', selectedImage);
    }
    api
    .post('SMSSend/InsertSendSMS', formData)
      .then(({ data }) => {
        toast.success(data.message);
        setSelectedSMSApiID(0);
        setSelectedSMSTemplateID(0);
        setTableData([]);
        setRefreshKey((prevKey) => prevKey + 1);
        setRefreshKeyOfSMSAPi((prevKey) => prevKey + 1);
        setRefreshKeyOfSMSTemplate((prevKey) => prevKey + 1);
        setNewMobileNumber("");
        setMsgOfSMS("");
        setSelectedImage("");
        setSelectedImageForDisplay("")
        if (imageInputRef.current) {
          imageInputRef.current.value = null;
        }
      })
      .catch(handleApiError);
  };

  const handleImageChange = (event) => {
    
    const { name, files } = event.target;
        const selectedFile = files[0];
        setSelectedImage(selectedFile);

        const fileImage = event.target.files[0];

        if (fileImage) {
          // Use FileReader to convert the selected file to a data URL
          const reader = new FileReader();
    
          reader.onloadend = () => {
            // Set the selectedImage state with the data URL
            setSelectedImageForDisplay(reader.result);
          };
          reader.readAsDataURL(fileImage);
        }
   
  };
  return (
    <>
      <ToastContainer />
      <Card>
        <Card.Header>
          <span>Send SMS</span>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={7}>
              <Col md={12} className="d-flex my-2 borderOFSendSMS">
                <Col md={12} className="d-flex text-center">
                  <Button
                    className="outline-primaryOfTapti mx-2"
                    onClick={() => ClientOrLeadData("Lead")}
                  >
                    <i className="fa fa-user"></i> All Lead
                  </Button>
                  <Button
                    className="outline-primaryOfTapti"
                    onClick={() => ClientOrLeadData("Client")}
                  >
                    <i className="fa fa-user"></i> All Client
                  </Button>
                  <Button
                    className="outline-primaryOfTapti mx-2"
                    onClick={() => setIsSelectClientModalOpen(true)}
                  >
                    <i className="fa fa-user"></i> Select
                  </Button>
                </Col>
              </Col>
              <Col md={12} className="d-flex my-2 borderOFSendSMS ">
                <Col md={12} className="d-flex">
                  <Col md={3} sm={3} lg={3} className="text-end ">
                    <strong className="align-middle ">
                      Search Client:&nbsp;
                    </strong>
                  </Col>
                  <Col md={8} sm={8} lg={8}>
                    <TypeaheadComponent
                      type={"Client"}
                      onClientSelect={handleClientSelection}
                      onClear={refreshKey}
                    />
                  </Col>
                </Col>
              </Col>
              <Col md={12} className="d-flex my-2 borderOFSendSMS">
                <Col md={12} className="d-flex">
                  <Col md={3} sm={3} lg={3} className="text-end ">
                    <strong className="align-middle ">
                      Search Lead:&nbsp;
                    </strong>
                  </Col>
                  <Col md={8} sm={8} lg={8}>
                    <TypeaheadComponent
                      type={"Lead"}
                      onClientSelect={handleClientSelection}
                      onClear={refreshKey}
                    />
                  </Col>
                </Col>
              </Col>
              <Col md={12} className="d-flex my-2 borderOFSendSMS">
                <Col md={12} className="d-flex">
                  <Col md={8} sm={8} lg={8} className="text-end ">
                    <Form.Control
                      type="text"
                      name="newMobileNumber"
                      placeholder=" MobileNumber"
                      value={newMobileNumber}
                      onChange={handleInputChange}
                      required
                    />
                  </Col>
                  <Col md={2} sm={2} lg={2} className="text-end">
                    <Button
                      className="outline-primaryOfTapti"
                      onClick={addMobileNumber}
                    >
                      Add
                    </Button>
                  </Col>
                </Col>
              </Col>
              <Col md={12} className="d-flex my-2 borderOFSendSMS ">
                <Col md={6} className="text-start">
                  {" "}
                  <strong className="text-start align-middle">
                    Total Count:{tableData.length}
                  </strong>
                </Col>
                <Col md={6} className="text-end">
                  {" "}
                  <Button
                    onClick={() => setTableData([])}
                    className="mx-2 outline-primaryOfTapti"
                  >
                    <i className="fa fa-refresh"></i>
                  </Button>
                </Col>
              </Col>
              <Col md={12}>
                <div className="text-center">
                  <Button onClick={SendSMS}>
                    <i className="fa fa-send"></i> Send SMS
                  </Button>
                </div>
              </Col>
            </Col>
            <Col md={5} className="borderOFSendSMS">
              <Col md={12}>
                <SMSApiDDComponent
                  onSelectSMSApi={(SMSApiID) => {
                    setSelectedSMSApiID(SMSApiID);
                  }}
                  selectedSMSApi={selectedSMSApiID}
                  ColSize={12}
                  key={`SMSApiDDComponent-${refreshKeyOfSMSAPi}`}
                />
                <SMSTemplateDDComponent
                  onSelectSMSTemplate={(SMSTemplateID) => {
                    setSelectedSMSTemplateID(SMSTemplateID);
                    getSMSTemplate(SMSTemplateID);
                  }}
                  selectedSMSTemplate={selectedSMSTemplateID}
                  ColSize={12}
                  key={`SMSTemplateDDComponent-${refreshKeyOfSMSTemplate}`}
                />
              </Col>
              <Col md={12}>
                <Form.Group controlId="HeaderField">
                  <Form.Label> SMS Text</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <i className="fa fa-commenting"></i>
                    </InputGroup.Text>
                    <Form.Control
                      as="textarea"
                      rows={6}
                      name="msgOfSMS"
                      placeholder="Enter SMS Text"
                      value={msgOfSMS}
                      onChange={(e) => setMsgOfSMS(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group controlId="HeaderField">
                  <Form.Label> Send Image</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="file"
                      name="Image"
                      ref={imageInputRef} // Create a ref for the file input
                      onChange={handleImageChange} // Call the onChange function when the file input changes
                    />
                  </InputGroup>
                </Form.Group>
                {/* You can display the selected image if needed */}
                {selectedImageForDisplay && (
                  <img
                    alt="Selected"
                    src={selectedImageForDisplay}
                    style={{ maxWidth: "100%", maxHeight: "200px" }}
                  />
                )}
              </Col>
            </Col>
          </Row>
          <Table bordered className="mt-4" style={{ border: "#20336b" }}>
            <thead style={{ color: "red", backgroundColor: "white" }}>
              <tr>
                <th>Type</th>
                <th>Name</th>
                <th>Mobile Number</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((item, rowNumber) => (
                  <tr>
                    <td>{item.type}</td>
                    <td>{item.clientName}</td>
                    <td>{item.clientMobileNumber}</td>
                    <td className="text-center">
                      <Button
                        variant="outline-danger "
                        size="sm"
                        onClick={() => removeRow(rowNumber)}
                      >
                        <i class="fa fa-trash-o" aria-hidden="true"></i>
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Modal
        show={isSelectClientModalOpen}
        onHide={() => setIsSelectClientModalOpen(false)}
        size="xl"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton closeVariant={"white"}>
          <Modal.Title id="example-custom-modal-styling-title">
            Select
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Row className="justify-content-center">
              <Col md={3} sm={3} lg={3}>
                <Form.Group controlId="Expected_ResolveDate" className="d-flex">
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <i className="fa fa-calendar"></i>
                    </InputGroup.Text>
                    <Form.Control
                      type="date"
                      name="start_date"
                      value={formData.start_date}
                      onChange={handleInputChangeModel}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Expected start_date
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={3} sm={3} lg={3}>
                <Form.Group controlId="Expected_ResolveDate" className="d-flex">
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <i className="fa fa-calendar"></i>
                    </InputGroup.Text>
                    <Form.Control
                      type="date"
                      name="end_date"
                      value={formData.end_date}
                      onChange={handleInputChangeModel}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Expected end_date
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>

              <Col md={1} sm={1} lg={1}>
                <Dropdown
                  className="clientDD"
                  onSelect={(eventKey) => handleTypeChange(eventKey)}
                >
                  <Dropdown.Toggle
                    variant="outline-primary"
                    id="dropdown-basic"
                  >
                    {selectedType}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="Client">Client</Dropdown.Item>
                    <Dropdown.Item eventKey="Lead">Lead</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col md={1} sm={1} lg={1}>
                <Button variant="outline-success" onClick={getClient}>
                  Search
                </Button>
              </Col>
            </Row>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
}
