import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../Utils/api";
import { Form, Button, Col, Row, InputGroup } from "react-bootstrap";
import ExpenseMaster from "./ExpenseMaster";

const emptyExpenseMaster = {
  expenseMaster_id: 0,
  expenseMaster_name: "",
};

export default function ExpenseMasterComponent({
  expenseMasterID,
  editedExpenseMasterData,
  getExpenseeMasterData,
}) {
  const [refreshKey, setRefreshKey] = useState(0); // Key for refreshing the dropdown
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyExpenseMaster);
  const expenseMasterFormRef = useRef(null);

  useEffect(() => {}, [refreshKey]);
  useEffect(() => {
    if (editedExpenseMasterData) {
      setFormData({
        expenseMaster_id: editedExpenseMasterData.expenseMasterID,
        expenseMaster_name: editedExpenseMasterData.expenseMasterName,
      });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [editedExpenseMasterData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Insert & Update Data
  const insertExpenseMaster = (event) => {
    setValidated(true);
    event.preventDefault();
    
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    } else {
      const data = {
        expenseMasterID: formData?.expenseMaster_id || 0,
        expenseMasterName: formData.expenseMaster_name,
      };
      const endpoint =
        formData.expenseMaster_id === 0
          ? "ExpenseMaster/InsertExpenceMaster"
          : "ExpenseMaster/EditExpenceMaster";

      api[formData.expenseMaster_id === 0 ? "post" : "patch"](endpoint, data)
        .then((result) => {
          if (result.status === 200) {
            toast.success(result.data.message);
            if(expenseMasterID){
              expenseMasterID(result.data.data);
            }
            
          } else {
            toast.error(result.data.message);
          }
          setRefreshKey(refreshKey + 1);
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred");
        });

      resetForm();
      setValidated(false); // Reset validation state
    }
  };

  // Reset form fields
  const resetForm = () => {
    setFormData(emptyExpenseMaster);
  };

  return (
    <>
      <Form
        noValidate
        validated={validated}
        onSubmit={(e) => {
          if (e) {
            e.preventDefault();
          }

          if (expenseMasterFormRef.current && e) {
            // Check for the formName to call insertCity
            if (e.target.name === "areaForm") {
              insertExpenseMaster(e);
            }
          }
        }}
        ref={expenseMasterFormRef}
        name="areaForm"
      >
        <Row>
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="expenseMaster_name">
              <Form.Label>Expense Name:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-building"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="expenseMaster_name"
                  placeholder="Enter Expense Name"
                  value={formData.expenseMaster_name}
                  onChange={handleInputChange}
                  required
                />
                {validated && !formData.expenseMaster_name && (
                  <div className="invalid-feedback">
                    Please Enter expenseMaster Name.
                  </div>
                )}
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <div className="card-footer margin_top10">
          <Button type="submit" variant="primary" className="mb-20px">
            {formData?.expenseMaster_id > 0 ? "Update" : "Save"}
          </Button>
        </div>
      </Form>
    </>
  );
}
