import React, { useState, useEffect, useRef } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import api from "../../Utils/api";
import { Card } from "react-bootstrap";
import AreaComponent from "./AreaComponent";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";
import Loader from "../../Components/Common/Loader ";

export default function Area() {
  const [areaData, setAreaData] = useState([]);
  const [editAreaData, setEditAreaData] = useState(null);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");
  const [isLoading,setIsLoading]=useState(false)

  //let IsLoading = useRef(false)

  useEffect(() => {
    
    getArea();
    CheckRoleTemplatePermissions("Area")
      .then((obj) => {
        setRoleTemplatePermissions(obj);
      })
      .catch((error) => null)
      
  }, []);

  // Fetch all Areas
  const getArea = () => {
    setIsLoading(true)
    api
      .get("Area/AllArea")
      .then((result) => {
        setAreaData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(()=>{
        setIsLoading(false);
      });
  };

  // Get data of a single Area
  const getSingleArea = (id) => {
    api
      .get(`Area/SingleArea?AreaID=${id}`)
      .then((result) => {
        const AreaData = result.data.data;
        setEditAreaData(AreaData);
        console.log(RoleTemplatePermissions);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Delete Area
  const deleteArea = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`Area/DeleteArea?AreaID=${id}`)
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              getArea();
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  };

  // AG Grid Table Column
  const columnDefs = [
    {
      headerName: "Area Name",
      field: "areaName",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "City Name",
      field: "cityName",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "State Name",
      field: "stateName",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      flex: 1,
      minWidth: 100,
    },
    ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
            headerName: "Edit",
            width: 70,
            field: "areaID",
            cellStyle: { textAlign: "center" },
            headerClass: "center-text widthTableColum",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="create-outline"
                class="iconsFonts editIcon"
                onClick={() => getSingleArea(value)}
              ></ion-icon>
            ),
            pinned: "right",
          },
        ]
      : []),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
      ? [
          {
            headerName: "Delete",
            width: 80,
            field: "areaID",
            cellStyle: { textAlign: "center" },
            headerClass: "center-text widthTableColum",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="trash-outline"
                class="iconsFonts deleteIcon"
                onClick={() => deleteArea(value)}
              ></ion-icon>
            ),
            pinned: "right",
          },
        ]
      : []),
  ];

  // Variya Roshan

  return (
    <>
    
   
      <ToastContainer />
      {isLoading && <Loader/> }
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
        <Card>
          <Card.Header>
            <span>New Area</span>
          </Card.Header>
          <Card.Body>
            <AreaComponent
              areaID={getArea}
              editedAreaData={editAreaData}
              getAreaData={getSingleArea}
            />
          </Card.Body>
        </Card>
      )}

      <Card>
        <Card.Header>
          <span>Area List</span>
        </Card.Header>
        <Card.Body>
          <AgGridComponent rowData={areaData} columnDefs={columnDefs} />
        </Card.Body>
      </Card>
    </>
  );
}
