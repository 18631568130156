import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api, { handleApiError } from "../../Utils/api";
import { Form, Button, Col, Row, InputGroup } from "react-bootstrap";
import CalendarHeadDDComponent from "../CalendarHead/CalendarHeadDDComponent";
import ClientDDComponent from "../Client/ClientDDComponent";

const emptyCalendarData = {
  calendarData_id: 0,
  client_id: 0,
  calendarHead_id: 0,
  isActive: false,
  reminderDateTime: "",
  remarks: "",
};

export default function CalendarDataComponent({
  CalendarDataID,
  editedCalendarDataData,
  setShowCalendarData,
  getCalendarDataData,
}) {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyCalendarData);
  const [refreshKey, setRefreshKey] = useState(0);
  const [clientDDValidation,setClientDDValidation] = useState(false);
  const [calenderHeadDDValidation,setCalenderHeadDDValidation] = useState(false);

  const CalendarDataFormRef = useRef(null);

  useEffect(() => {
    if (editedCalendarDataData) {
      setFormData({
        calendarData_id: editedCalendarDataData.calendarDataID,
        client_id: editedCalendarDataData.clientID,
        calendarHead_id: editedCalendarDataData.calendarHeadID,
        isActive: editedCalendarDataData.isActive,
        reminderDateTime: editedCalendarDataData.reminderDateTime,
        remarks: editedCalendarDataData.remarks,
      });
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }
  }, [editedCalendarDataData]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    // Use the checked property for checkboxes
    const inputValue = type === "checkbox" ? checked : value;

    setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
  };

  const insertOrUpdateCalendarData = (event) => {
    setValidated(true);
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setCalenderHeadDDValidation(formData.calendarHead_id === 0);
      setClientDDValidation(formData.client_id === 0);
      return;
    }
    console.log(formData.client_id);
    const data = {
      CalendarDataID: formData?.calendarData_id || 0,
      ClientID: formData.client_id,
      CalendarHeadID: formData.calendarHead_id,
      IsActive: formData.isActive,
      ReminderDateTime: formData.reminderDateTime,
      Remarks: formData.remarks,
    };
    const endpoint =
      formData.calendarData_id === 0
        ? "CalendarData/InsertCalendarData"
        : "CalendarData/EditCalendarData";

    api[formData.calendarData_id === 0 ? "post" : "patch"](endpoint, data)
      .then((result) => {
        if (result.status === 200) {
          toast.success(result.data.message);
          if (CalendarDataID) {
            CalendarDataID(result.data.data);
          }
        } else {
          toast.error(result.data.message);
        }
        setRefreshKey(refreshKey + 1);
      })
      .catch(handleApiError);
    resetFormCalendarData();
    setValidated(false);
  };

  const resetFormCalendarData = () => {
    setFormData(emptyCalendarData);
    if (!editedCalendarDataData) {
      setShowCalendarData(false);
    }
  };

  return (
    <>
      <Form
        noValidate
        validated={validated}
        onSubmit={(e) => {
          if (e) {
            e.preventDefault();
          }

          if (CalendarDataFormRef.current && e) {
            if (e.target.name === "CalendarDataForm") {
              insertOrUpdateCalendarData(e);
            }
          }
        }}
        ref={CalendarDataFormRef}
        name="CalendarDataForm"
      >
        <Row>
          <CalendarHeadDDComponent
            onSelectCalendarHead={(calendarHeadID) => {
              setFormData((prevData) => ({
                ...prevData,
                calendarHead_id: calendarHeadID,
              }));
              setCalenderHeadDDValidation(false);
            }}
            selectedCalendarHead={formData.calendarHead_id}
            ValidationError={calenderHeadDDValidation}
            key={`CalendarHeadDDComponent-${refreshKey}`}
          />
          <ClientDDComponent
            onSelectClient={(clientID) => {
              setFormData((prevData) => ({
                ...prevData,
                client_id: clientID,
              }));
              setClientDDValidation(false)
            }}
            selectedClient={formData.client_id}
            ValidationError={clientDDValidation}
            key={`ClientDDComponent-${refreshKey}`}
          />
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="reminderDateTime">
              <Form.Label>Reminder Date:</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  type="datetime-local"
                  name="reminderDateTime"
                  value={formData.reminderDateTime}
                  onChange={handleInputChange}
                  required
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={12} sm={4} lg={12}>
            <Form.Group controlId="remarks">
              <InputGroup className="mb-3">
                <Form.Control
                  as="textarea"
                  rows={4}
                  name="remarks"
                  placeholder="Remarks"
                  value={formData.remarks}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        {editedCalendarDataData && (
          <Row>
            <Col md={4} sm={4} lg={4}>
              <Form.Group controlId="isActive">
                <Form.Label></Form.Label>
                <Form.Check
                  type="checkbox"
                  label="Is Active"
                  name="isActive"
                  checked={formData.isActive}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
          </Row>
        )}

        <div className="card-footer margin_top10">
          <Button type="submit" variant="primary" className="mb-20px">
            {formData?.calendarData_id > 0 ? "Update" : "Save"}
          </Button>
        </div>
      </Form>
    </>
  );
}
