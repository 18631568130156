import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api, { handleApiError } from "../../Utils/api";
import {
  Form,
  Button,
  Col,
  Row,
  InputGroup,
  ButtonGroup,
  ToggleButton,
  Image,
} from "react-bootstrap";
import ClientDDComponent from "../Client/ClientDDComponent";
import DeveloperDDComponent from "../Developer/DeveloperDDComponent";
import Webcam from "react-webcam";
import ImageUploadAndWebcamComponent from "../../Components/Common/ImageUploadAndWebcamComponent ";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Loader from "../../Components/Common/Loader ";

const emptyTaskAndIssue = {
  TaskAndIssue_id: 0,
  TaskAndIssue_Type: "",
  Client_id: 0,
  TaskAndIssue_Subject: "",
  TaskAndIssue_Detail: "",
  Developer_id: 0,
  Expected_ResolveDate: "",
  Priority: "",
};

export default function TaskAndIssueComponent({
  TaskAndIssueID,
  ClientID,
  editedTaskAndIssueData,
  getTaskAndIssueData,
}) {
  const [refreshKey, setRefreshKey] = useState(0);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyTaskAndIssue);
  const TaskAndIssueFormRef = useRef(null);
  const imageInputRef = useRef(null);
  const [clientDDValidation, setClientDDValidation] = useState(false);
  const [PriorityValidation, setPriorityValidation] = useState(false);
  const [typeValidation, setTypeValidation] = useState(false);
  const [taskAndIssueDetailValidation, setTaskAndIssueDetailValidation] =
    useState(false);
  const webcamRef = useRef(null);
  const [openModel, setOpenModel] = useState(false);

  const [selectedImage, setSelectedImage] = useState([]);
  const [isLoading,setIsLoading]=useState(false);

  useEffect(() => {
    if (editedTaskAndIssueData) {
      setFormData({
        TaskAndIssue_id: editedTaskAndIssueData.taskAndIssueID,
        TaskAndIssue_Type: editedTaskAndIssueData.taskAndIssueType,
        Client_id: editedTaskAndIssueData.clientID,
        TaskAndIssue_Subject: editedTaskAndIssueData.taskAndIssueSubject,
        TaskAndIssue_Detail: editedTaskAndIssueData.taskAndIssueDetail,
        Developer_id: editedTaskAndIssueData.developerID,
        Expected_ResolveDate: editedTaskAndIssueData.expectedResolveDate,
        TaskAndIssueStatusMaster_id:
          editedTaskAndIssueData.taskAndIssueStatusMasterID,
        Priority: editedTaskAndIssueData.priority,
      });
      setPriorityType(editedTaskAndIssueData.priority || "");
      setTaskAndIssueName(editedTaskAndIssueData.taskAndIssueType || "");
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [editedTaskAndIssueData]);

  useEffect(() => {
    if (ClientID) {
      // Use the prevState parameter to access the previous state
      setFormData((prevState) => ({
        ...prevState,
        Client_id: ClientID,
      }));
    }
  }, [ClientID]);

  const insertOrUpdateTaskAndIssue = (event) => {
    setIsLoading(true)
    setValidated(true);
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setTaskAndIssueDetailValidation(formData.TaskAndIssue_Detail == "");
      setClientDDValidation(formData.Client_id === 0);
      setPriorityValidation(formData.Priority === "");
      setTypeValidation(formData.TaskAndIssue_Type === "");

      return;
    }

    const form_Data = new FormData();
    form_Data.append("TaskAndIssueID", formData?.TaskAndIssue_id || 0);
    form_Data.append("TaskAndIssueType", formData.TaskAndIssue_Type);
    form_Data.append("ClientID", formData.Client_id);
    form_Data.append("TaskAndIssueSubject", formData.TaskAndIssue_Subject);
    form_Data.append("TaskAndIssueDetail", formData.TaskAndIssue_Detail);
    form_Data.append(
      "DeveloperID",
      formData.Developer_id === null ? 0 : formData.Developer_id
    );
    form_Data.append("ExpectedResolveDate", formData.Expected_ResolveDate);
    form_Data.append("TaskAndIssueStatusMasterID", 1);
    form_Data.append("Priority", formData.Priority);

    for (let i = 0; i < selectedImage.length; i++) {
      form_Data.append(`Files[${i}]`, selectedImage[i]);
    }

    const endpoint =
      formData.TaskAndIssue_id === 0
        ? "TaskAndIssue/InsertTaskAndIssue"
        : "TaskAndIssue/EditTaskAndIssue";

    api[formData.TaskAndIssue_id === 0 ? "post" : "patch"](endpoint, form_Data)
      .then((result) => {
        if (result.status === 200) {
          setClientDDValidation(false);
          setPriorityValidation(false);
          setTypeValidation(false);
          TaskAndIssueID(result.data.data);
          toast.success(result.data.message);
          setSelectedImage([]);
        } else {
          toast.error(result.data.message);
        }
        setRefreshKey((prevKey) => prevKey + 1);
      })
      .catch(handleApiError)
      .finally(()=>{
        resetForm();
        setValidated(false);
        setIsLoading(false);
      })

   
  };

  const resetForm = () => {
    setFormData(emptyTaskAndIssue);
    setTaskAndIssueName("");
    setPriorityType("");
    if (imageInputRef.current) {
      imageInputRef.current.value = null; // Reset the input value
    }
  };

  const [taskAndIssueName, setTaskAndIssueName] = useState("");

  const handleTaskAndIssueRadioChange = (event) => {
    setTaskAndIssueName(event.target.value);
    setTypeValidation(false);
    formData.TaskAndIssue_Type = event.target.value;
  };

  const [priorityType, setPriorityType] = useState("");

  const handlePriorityTypeRadioChange = (event) => {
    setPriorityType(event.target.value);
    setPriorityValidation(false);
    formData.Priority = event.target.value;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if(name == "TaskAndIssue_Detail"){
      setTaskAndIssueDetailValidation(false)
    }
    setFormData((prevData) => ({ ...prevData, [name]: value }));

  };

  const handelSelectedImage = (selectedFiles) => {
    setSelectedImage((prevData) => [...prevData, selectedFiles]);
  };

  return (
    <>
    {isLoading && <Loader/>}
      <Form
        noValidate
        validated={validated}
        onSubmit={(e) => {
          if (e) {
            e.preventDefault();
          }

          if (TaskAndIssueFormRef.current && e) {
            if (e.target.name === "TaskAndIssueForm") {
              insertOrUpdateTaskAndIssue(e);
            }
          }
        }}
        ref={TaskAndIssueFormRef}
        name="TaskAndIssueForm"
      >
        <Row>
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="TaskAndIssue_Name">
              <Form.Label>TaskAndIssue Name:</Form.Label>
              <InputGroup className="mb-3" hasValidation>
                <ButtonGroup required isInvalid>
                  <ToggleButton
                    id={`radio-type-Task`}
                    type="radio"
                    name="taskAndIssueName"
                    value={"Task"}
                    checked={taskAndIssueName === "Task"}
                    variant="outline-success"
                    onChange={handleTaskAndIssueRadioChange}
                    required
                  >
                    <i className="fa fa-thumb-tack"></i> {"Task"}
                  </ToggleButton>
                  <ToggleButton
                    id={`radio-type-Issue`}
                    type="radio"
                    name="taskAndIssueName"
                    value={"Issue"}
                    variant="outline-primary"
                    checked={taskAndIssueName === "Issue"}
                    onChange={handleTaskAndIssueRadioChange}
                    required
                  >
                    <i className="fa fa-bug"></i> {"Issue"}
                  </ToggleButton>
                  <ToggleButton
                    id={`radio-type-Complain`}
                    type="radio"
                    name="taskAndIssueName"
                    value={"Complain"}
                    variant="outline-danger"
                    checked={taskAndIssueName === "Complain"}
                    onChange={handleTaskAndIssueRadioChange}
                    required
                  >
                    <i className="fa fa-exclamation-triangle"></i> {"Complain"}
                  </ToggleButton>
                </ButtonGroup>
                {typeValidation && (
                  <div className=" ValidationErrorMsg mt-0 ">
                    Please provide a TaskAndIssueName.
                  </div>
                )}
              </InputGroup>
            </Form.Group>
          </Col>
          <ClientDDComponent
            onSelectClient={(clientID) => {
              setFormData((prevData) => ({
                ...prevData,
                Client_id: clientID,
              }));
              setClientDDValidation(false);
            }}
            ValidationError={clientDDValidation}
            selectedClient={formData.Client_id}
            key={`ClientDDComponent-${refreshKey}`}
          />
        </Row>
        <Row>
          <Col md={8} sm={8} lg={8}>
            <Form.Group controlId="TaskAndIssue_Subject">
              <Form.Label>Subject:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i class="fa fa-file-signature"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="TaskAndIssue_Subject"
                  placeholder="Enter Subject"
                  value={formData.TaskAndIssue_Subject}
                  onChange={handleInputChange}
                  className="rounded-end"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a subject.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={8} sm={8} lg={8}>
            <Form.Group controlId="HeaderField">
              <Form.Label>Detail:</Form.Label>
              <InputGroup className="mb-3">
                <div style={{ height: "400px", width: "100%" }}>
                  <CKEditor
                    editor={ClassicEditor}
                    data={formData.TaskAndIssue_Detail}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      handleInputChange({
                        target: { name: "TaskAndIssue_Detail", value: data },
                      });
                    }}
                    required
                  />
                </div>
                {taskAndIssueDetailValidation && (
                  <div className="ValidationErrorMsg">
                    Please Enter Details Of TaskAndIssue
                  </div>
                )}
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <DeveloperDDComponent
            onSelectDeveloper={(developerID) => {
              setFormData((prevData) => ({
                ...prevData,
                Developer_id: developerID,
              }));
            }}
            selectedDeveloper={formData.Developer_id}
            key={`DeveloperDDComponent-${refreshKey}`}
          />
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="TaskAndIssue_Name">
              <Form.Label> Priority:</Form.Label>
              <InputGroup className="mb-3">
                {/* <InputGroup.Text id="basic-addon1">
                  <i class="fa fa-sliders"></i>
                </InputGroup.Text> */}
                <ButtonGroup className="w-50">
                  {/* Priority ToggleButton group */}
                  {["Low", "Medium", "High"].map((priority) => (
                    <ToggleButton
                      key={priority}
                      id={`radio-priority-${priority}`}
                      type="radio"
                      variant={
                        priority === "Low"
                          ? "outline-success"
                          : priority === "Medium"
                          ? "outline-primary"
                          : "outline-danger"
                      }
                      name="priorityType"
                      value={priority}
                      checked={priorityType === priority}
                      onChange={handlePriorityTypeRadioChange}
                      required
                    >
                      {priority}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
                {PriorityValidation && (
                  <div className=" ValidationErrorMsg mt-0 ">
                    Please provide a Priority.
                  </div>
                )}
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="Expected_ResolveDate">
              <Form.Label>Expected ResolveDate:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i class="fa fa-calendar"></i>
                </InputGroup.Text>
                <Form.Control
                  type="datetime-local"
                  name="Expected_ResolveDate"
                  value={formData.Expected_ResolveDate}
                  onChange={handleInputChange}
                  className="rounded-end"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please Expected ResolveDate
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6} lg={6} className="my-3">
            <Form.Group controlId="Image">
              <Form.Label>Upload Image:</Form.Label>
              <Button
                onClick={() => setOpenModel(true)}
                className="mx-3"
                variant="outline-primary"
              >
                {" "}
                upload Image ({selectedImage.length})
              </Button>
              <Button
                onClick={() => setSelectedImage([])}
                variant="outline-danger"
              >
                {" "}
                Clear
              </Button>
            </Form.Group>
          </Col>
        </Row>

        <div className="card-footer margin_top10">
          <Button type="submit" variant="primary" className="mb-20px">
            {formData?.TaskAndIssue_id > 0 ? "Update" : "Save"}
          </Button>
        </div>
      </Form>
      {openModel && (
        <ImageUploadAndWebcamComponent
          showModal={openModel}
          onClose={() => setOpenModel(false)}
          onImageCapture={handelSelectedImage}
          onFileUpload={handelSelectedImage}
          onClear={() => setSelectedImage([])}
        />
      )}{" "}
    </>
  );
}
