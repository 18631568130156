import React, { useState, useEffect } from "react";
import api, { handleApiError } from "../../Utils/api";
import { Form, Col, InputGroup, Modal } from "react-bootstrap";
import SourceTypeComponent from "./SourceTypeComponent";
import Select from "react-select";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";

export default function SourceTypeDDComponent({
  onSelectSourceType,
  selectedSourceType,
  onAdditionalChange,
  ValidationError,
}) {
  const [SourceTypeOptions, setSourceTypeOptions] = useState([]);
  const [isSourceTypeModalOpen, setIsSourceTypeModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [validation, setValidation] = useState(false);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getSourceTypeName();
    CheckRoleTemplatePermissions("SourceType")
      .then((obj) => {
        setRoleTemplatePermissions(obj);
      })
      .catch((error) => null);
  }, []);

  useEffect(() => {
    if (ValidationError && selectedSourceType === 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  }, [ValidationError, selectedSourceType]);

  useEffect(() => {
    if (selectedSourceType) {
      const selectedOption = SourceTypeOptions.find(
        (option) => option.sourceTypeID === selectedSourceType
      );
      setSelectedOption({
        value: selectedOption?.sourceTypeID,
        label: selectedOption?.sourceName,
      });
    }
  }, [selectedSourceType, SourceTypeOptions]);

  // Fetch all City Name
  const getSourceTypeName = () => {
    api
      .get("SourceType/AllSourceType")
      .then(({ data }) => setSourceTypeOptions(data.data))
      .catch(handleApiError);
  };

  const handleSourceTypeChange = (option) => {
    setSelectedOption(option);
    onSelectSourceType(option ? option.value : null);

    if (onAdditionalChange) {
      onAdditionalChange({ onAdditionalChange });
    }
  };

  // Insert New State
  const SourceTypeModalOpen = () => {
    setIsSourceTypeModalOpen(true);
  };

  const closeSourceTypeModal = () => {
    setIsSourceTypeModalOpen(false);
  };

  const selectDDSourceTypeID = (SourceTypeID) => {
    handleSourceTypeChange({ value: SourceTypeID });
    getSourceTypeName();
    closeSourceTypeModal();
  };

  return (
    <>
      <Col md={4} sm={4} lg={4}>
        <Form.Group controlId="SourceType_id">
          <Form.Label>SourceType Name:</Form.Label>
          <div className="mb-3 d-flex justify-content-between">
            <div className="selectStartIcon input-group-text" id="basic-addon1">
              <i className="fa fa-handshake-o"></i>
            </div>
            <Select
              className={`w-100 ${validation ? "borderRed" : ""} ${
                RoleTemplatePermissions?.addPermission
                  ? "RadiusNo"
                  : "RadiusYes"
              }`}
              options={SourceTypeOptions.map((option) => ({
                value: option.sourceTypeID,
                label: option.sourceName,
              }))}
              value={selectedOption}
              onChange={handleSourceTypeChange}
              placeholder="Select SourceType Name"
              required
            />
            {RoleTemplatePermissions &&
              RoleTemplatePermissions.addPermission && (
                <div className="selectEndIcon input-group-text">
                  <i
                    className="fa fa-plus plusIcon"
                    onClick={SourceTypeModalOpen}
                  ></i>
                </div>
              )}
          </div>
          {validation && (
            <div className="ValidationErrorMsg">
              Please select an SourceType.
            </div>
          )}
        </Form.Group>
      </Col>

      {/* City Modal */}
      <Modal
        show={isSourceTypeModalOpen}
        onHide={closeSourceTypeModal}
        size="xl"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton closeVariant={"white"}>
          <Modal.Title id="example-custom-modal-styling-title">
            New SourceType
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <SourceTypeComponent
              SourceTypeID={selectDDSourceTypeID}
            ></SourceTypeComponent>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
}
