import React, { useState, useEffect } from "react";
import api, { handleApiError } from "../../Utils/api";
import { Form, Col, InputGroup, Modal } from "react-bootstrap";
import ClientComponent from "./ClientComponent";
import Select from "react-select";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";

export default function ClientDDComponent({
  onSelectClient,
  selectedClient,
  onAdditionalChange,
  ValidationError,
}) {
  const [clientOptions, setClientOptions] = useState([]);
  const [isClientModalOpen, setIsClientModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [validation, setValidation] = useState(false);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getClientName();
    CheckRoleTemplatePermissions("Client")
      .then((obj) => {
        setRoleTemplatePermissions(obj);
      })
      .catch((error) => null);
  }, []);

  useEffect(() => {
    if (ValidationError && selectedClient === 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  }, [ValidationError, selectedClient]);

  useEffect(() => {
    if (selectedClient) {
      const selectedOption = clientOptions.find(
        (option) => option.clientID === selectedClient
      );
      setSelectedOption({
        value: selectedOption?.clientID,
        label: selectedOption?.clientName,
      });
      setValidation(false);
    }
  }, [selectedClient, clientOptions]);

  // Fetch all City Name
  const getClientName = () => {
    api
      .get("Client/AllClient")
      .then(({ data }) => setClientOptions(data.data))
      .catch(handleApiError);
  };

  const handleClientChange = (option) => {
    setSelectedOption(option);
    onSelectClient(option ? option.value : null);

    if (onAdditionalChange) {
      onAdditionalChange({ onAdditionalChange });
    }
  };

  // Insert New State
  const ClientModalOpen = () => {
    setIsClientModalOpen(true);
  };

  const closeClientModal = () => {
    setIsClientModalOpen(false);
  };

  const selectDDClientID = (ClientID) => {
    handleClientChange({ value: ClientID });
    getClientName();
    closeClientModal();
  };

  return (
    <>
      <Col md={4} sm={4} lg={4}>
        <Form.Group controlId="client_id">
          <Form.Label>Client Name:</Form.Label>
          <div className="mb-3 d-flex justify-content-between">
            <div className="selectStartIcon input-group-text" id="basic-addon1">
              <i className="fas fa-user"></i>
            </div>
            <Select
              className={`w-100 ${validation ? "borderRed" : ""} ${
                RoleTemplatePermissions?.addPermission
                  ? "RadiusNo"
                  : "RadiusYes"
              }`}
              options={clientOptions.map((option) => ({
                value: option.clientID,
                label: option.clientName,
              }))}
              value={selectedOption}
              onChange={handleClientChange}
              placeholder="Select Client Name"
              required
            />
            {RoleTemplatePermissions &&
              RoleTemplatePermissions.addPermission && (
                <div className="selectEndIcon input-group-text">
                  <i
                    className="fa fa-plus plusIcon"
                    onClick={ClientModalOpen}
                  ></i>
                </div>
              )}
          </div>
          {validation && (
            <div className="ValidationErrorMsg">Please select an Client.</div>
          )}
        </Form.Group>
      </Col>

      {/* City Modal */}
      <Modal
        show={isClientModalOpen}
        onHide={closeClientModal}
        size="xl"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton closeVariant={"white"}>
          <Modal.Title id="example-custom-modal-styling-title">
            New Client
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <ClientComponent ClientID={selectDDClientID}></ClientComponent>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
}
