import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../Utils/api";
import { Form, Button, Col, Row, InputGroup } from "react-bootstrap";
import Select from "react-select";

const emptyUserTypeMaster = {
  UserTypeMaster_id: 0,
  RoleTemplate_ID: 0,
  Form_ID: 0,
};

export default function UserTypeMasterComponent({
  UserTypeMasterID,
  editedUserTypeMasterData,
  getUserTypeMasterData,
}) {
  const [refreshKey, setRefreshKey] = useState(0); // Key for refreshing the dropdown
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyUserTypeMaster);
  const UserTypeMasterFormRef = useRef(null);
  const [userOption, setUserOption] = useState([]);
  const [formOptions, setFormOptions] = useState([]);
  const [selectedFormOption, setSelectedFormOption] = useState(null);
  const [formDDvalidation, setFormDDValidation] = useState(false);

  useEffect(() => {
    if (editedUserTypeMasterData) {
      setFormData({
        UserTypeMaster_id: editedUserTypeMasterData.userTypeMasterID,
        RoleTemplate_ID: editedUserTypeMasterData.roleTemplateID,
        Form_ID: editedUserTypeMasterData.formID,
      });

      setSelectedFormOption({
        label : editedUserTypeMasterData.formName,
        value : editedUserTypeMasterData.formID
      })
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [editedUserTypeMasterData]);

  useEffect(() => {
    getRoleTemplateName();
    getFormName();
  }, []);

  const getFormName = () => {
    api
      .get("Form/AllForm")
      .then((result) => {
        setFormOptions(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getRoleTemplateName = () => {
    return new Promise((resolve, reject) => {
      api
        .get("RoleTemplateDetail/AllRoleTemplate")
        .then((result) => {
          setUserOption(result.data.data);
          resolve(result.data.data); // Resolve with the data
        })
        .catch((error) => {
          console.log(error);
          reject(error); // Reject with the error
        });
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormChange = (selectedOption) => {
    if (selectedOption != null) {
      setSelectedFormOption(selectedOption);
      setFormData({ ...formData, Form_ID : selectedOption.value })
      setFormDDValidation(false);
    }
  };

  // Insert & Update Data
  const insertUserTypeMasterMaster = (event) => {
    
    setValidated(true);
    event.preventDefault();

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      
      event.stopPropagation();
      setFormDDValidation(formData.Form_ID === 0);
      return;
    } else {
      const data = {
        UserTypeMasterID: formData?.UserTypeMaster_id || 0,
        RoleTemplateID: formData.RoleTemplate_ID,
        FormID: formData.Form_ID,
      };
      const endpoint =
        formData.UserTypeMaster_id === 0
          ? "UserTypeMaster/InsertUserTypeMaster"
          : "UserTypeMaster/EditUserTypeMaster";

      api[formData.UserTypeMaster_id === 0 ? "post" : "patch"](endpoint, data)
        .then((result) => {
          if (result.status === 200) {
            toast.success(result.data.message);
            UserTypeMasterID(result.data.data);
          } else {
            toast.error(result.data.message);
          }
          setRefreshKey(refreshKey + 1);
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred");
        });
        setFormDDValidation(false);
      resetForm();
      setValidated(false); // Reset validation state
    }
  };

  // Reset form fields
  const resetForm = () => {
    setFormData(emptyUserTypeMaster);
    setSelectedFormOption(null);
  };

  return (
    <>
      <Form
        noValidate
        validated={validated}
        // onSubmit={(event) => insertUserTypeMasterMaster(event, { resetForm })}
        onSubmit={(e) => {
          if (e) {
            e.preventDefault();
          }

          if (UserTypeMasterFormRef.current && e) {
            // Check for the formName to call insertCity
            if (e.target.name === "UserTypeMasterForm") {
              insertUserTypeMasterMaster(e);
            }
          }
        }}
        ref={UserTypeMasterFormRef}
        name="UserTypeMasterForm"
      >
        <Row>
          <Col md={2} className="text-end">
            <Form.Label>User Type Name:</Form.Label>
          </Col>
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="user_type">
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-user"></i>
                </InputGroup.Text>
                <Form.Select
                  aria-label="Select User Type Name"
                  value={formData.RoleTemplate_ID}
                  name="RoleTemplate_ID"
                  onChange={handleInputChange}
                  required
                >
                  <option value="">-- Select User Type Name --</option>
                  {userOption.map((option) => (
                    <option key={option.roleName} value={option.roleTemplateID}>
                      {option.roleName}
                    </option>
                  ))}
                </Form.Select>
                {validated && !formData.RoleTemplate_ID && (
                  <div className="invalid-feedback">
                    Please Select User Type Name.
                  </div>
                )}
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={2} className="text-end">
            <Form.Label>Form Name:</Form.Label>
          </Col>
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="area_id">
              <div className="mb-3 d-flex justify-content-between">
                <div
                  className="selectStartIcon input-group-text"
                  id="basic-addon1"
                >
                  <i className="fas fa-building"></i>
                </div>
                <Select
                  className={"w-100 RadiusYes " + (formDDvalidation ? "borderRed" : "")}
                  options={formOptions.map((option) => ({
                    value: option.formID,
                    label: option.formName,
                  }))}
                  value={selectedFormOption}
                  onChange={handleFormChange}
                  placeholder=" -- Select Form Name -- "
                  required
                />
              </div>
              {formDDvalidation && (
                <div className="ValidationErrorMsg">Please select an Form Name.</div>
              )}
            </Form.Group>
          </Col>
        </Row>
        <div className="card-footer margin_top10">
          <Button type="submit" variant="primary" className="mb-20px">
            {formData?.UserTypeMaster_id > 0 ? "Update" : "Save"}
          </Button>
        </div>
      </Form>
    </>
  );
}
