import React, { useEffect } from "react";
import Login from "../Login/Login";

export default function Logout() {
    
    useEffect(() => {
        
    localStorage.setItem("token", "");
    localStorage.setItem("userName", "");
    localStorage.setItem("user_ID", 0);
    window.location.assign("/");
    <Login />;
  });
}
