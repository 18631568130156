import React, { useState, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import api, { handleApiError } from "../../Utils/api";
import {
  Card,
  Row,
  Col,
  Table,
  Container,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
import { getCurrentDate } from "../../Utils/commonFunction";
import Select from "react-select";
import { toast } from "react-toastify";
import { format } from "date-fns";
import TypeaheadComponent from "../../Components/Common/TypeaheadComponent";

const emptyInvoiceDue = {
  InvoiceReceive_ID: 0,
  invoiceID: 0,
  PaymentType_ID: 0,
  Received_Amount: 0,
  Invoice_ReceivedDate: getCurrentDate(),
  Remark: "",
};

export default function InvoiceReceiveComponent({
  InvoiceReceiveID,
  editedInvoiceReceiveData,
  getInvoiceReceiveData,
}) {
  const [clientOptions, setClientOptions] = useState([]);
  const [selectedclients, setSelectedClients] = useState([]); // Changed to an array
  const [clientID, setClientID] = useState(0);
  const [clientWishDueInvoiceList, setClientWishDueInvoiceList] = useState([]);
  const [SelectedInvoiceID, setSelectedInvoiceID] = useState(0);
  const [paymentTypeOptions, setPaymentTypeOptions] = useState([]);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);
  const [formData, setFormData] = useState(emptyInvoiceDue);
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    if (editedInvoiceReceiveData) {
      const selectedPayment = paymentTypeOptions.find(
        (payment) =>
          payment.paymentTypeID === editedInvoiceReceiveData.obj.paymentTypeID
      );

      setFormData({
        InvoiceReceive_ID: editedInvoiceReceiveData.obj.invoiceReceiveID,
        invoiceID: editedInvoiceReceiveData.obj.invoiceID,
        PaymentType_ID: selectedPayment.paymentTypeID,
        Received_Amount: editedInvoiceReceiveData.obj.amount,
        Invoice_ReceivedDate: format(
          editedInvoiceReceiveData.obj.invoiceReceiveDate,
          "yyyy-MM-dd"
        ),
        Remark: editedInvoiceReceiveData.obj.remarks,
      });

      setSelectedInvoiceID(editedInvoiceReceiveData.obj.invoiceID);

      const selectedClient = [
        {
          clientID: editedInvoiceReceiveData.obj.clientID,
          clientName: editedInvoiceReceiveData.obj.clientName,
        },
      ];
      setSelectedClients(selectedClient);
      setClientID(editedInvoiceReceiveData.obj.clientID);

      setSelectedPaymentOption({
        value: selectedPayment.paymentTypeID,
        label: selectedPayment.paymentName,
      });

      setClientWishDueInvoiceList(editedInvoiceReceiveData.list);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [editedInvoiceReceiveData]);

  useEffect(() => {
    api
      .get("Invoice/AllPaymentTypeName")
      .then(({ data }) => setPaymentTypeOptions(data.data))
      .catch(handleApiError);
  }, []);

  const handleInput = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handlePaymentType = (option) => {
    
    setSelectedPaymentOption(option);
    setFormData((prevData) => ({ ...prevData, PaymentType_ID: option.value }));
  };

  const insertOrUpdateInvoiceReceive = (event) => {
    
    
    if (SelectedInvoiceID === 0) {
      toast.error("select Invoice");
      return;
    }
    
    const data = {
      InvoiceReceiveID: formData?.InvoiceReceive_ID || 0,
      InvoiceID: SelectedInvoiceID,
      PaymentTypeID: formData.PaymentType_ID,
      Amount: formData.Received_Amount,
      InvoiceReceiveDate: formData.Invoice_ReceivedDate,
      Remarks: formData.Remark,
    };

    const endpoint =
      formData.InvoiceReceive_ID === 0
        ? "InvoiceReceive/InsertInvoiceReceive"
        : "InvoiceReceive/EditInvoiceReceive";

    api[formData.InvoiceReceive_ID === 0 ? "post" : "patch"](endpoint, data)
      .then((result) => {
        if (result.status === 200) {
          toast.success(result.data.message);
          if (InvoiceReceiveID) {
            InvoiceReceiveID(result.data.data);
          }
        } else {
          toast.error(result.data.message);
        }
        setRefreshKey((prevKey) => prevKey + 1);
      })
      .catch(handleApiError);

    setFormData(emptyInvoiceDue);
    setClientOptions([]);
    setClientWishDueInvoiceList([]);
    setSelectedClients([]);
    setSelectedInvoiceID(0);
    setSelectedPaymentOption(null);
  };

  const handleClientSelection = (selectedClient) => {
    
    if (selectedClient) {
      api
        .get(`Invoice/ClientWishDueInvoice?ClientID=${selectedClient}`)
        .then(({ data }) => setClientWishDueInvoiceList(data.data))
        .catch(handleApiError);
    }
  };
  return (
    <>
      <Row>
        <Col md={2} className="text-end">
          <Form.Label>Date:</Form.Label>
        </Col>
        <Col md={2}>
          <Form.Group controlId="Invoice_Name">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <i class="fa fa-calendar"></i>
              </InputGroup.Text>
              <Form.Control
                type="date"
                name="Invoice_ReceivedDate"
                value={formData.Invoice_ReceivedDate}
                onChange={handleInput}
                required
              />
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row className="text-center">
        <Col md={2} sm={2} lg={2} className="text-end">
          <strong>Client Name:</strong>
        </Col>
        <Col md={6} sm={6} lg={6}>
          {/* <Typeahead
            id="clientTypeahead"
            labelKey="clientName" // Corrected to match the key in clientOptions
            options={clientOptions}
            placeholder="Search for a Client..."
            onChange={handleInputChange}
            selected={selectedclients}
            onInputChange={handleSearch}
            className="typeHade"
          /> */}
          <TypeaheadComponent
            type={"Client"}
            onClientSelect={handleClientSelection}
            onClear={refreshKey}
            editTimeSelectedClient={selectedclients}
          />
        </Col>
      </Row>

      <Table bordered className="mt-4" style={{ border: "#20336b" }}>
        <thead style={{ color: "red", backgroundColor: "white" }}>
          <tr>
            <th>Select</th>
            <th>Invoice No</th>
            <th>Invoice Date</th>
            <th>Net Amount</th>
            <th>Receive Amount</th>
            <th>Due Amount</th>
          </tr>
        </thead>
        <tbody>
          {clientWishDueInvoiceList &&
            clientWishDueInvoiceList.map((item) => (
              <tr
                key={item.invoiceID}
                onClick={() => {
                  setSelectedInvoiceID(item.invoiceID);
                }}
              >
                <td>
                  <input
                    type="radio"
                    checked={SelectedInvoiceID === item.invoiceID}
                    readOnly
                  />
                </td>
                <td>{item.invoiceNo}</td>
                <td>{item.invoiceDate}</td>
                <td>{item.netAmount}</td>
                <td>{item.totalReceivedAmount}</td>
                <td>{item.dueAmount}</td>
              </tr>
            ))}
        </tbody>
      </Table>

      <Row>
        <Col md={5} sm={5} lg={5}>
          <Form.Group controlId="Invoice_Name">
            <InputGroup className="mb-3">
              <Form.Control
                as="textarea" // Set the 'as' prop to 'textarea'
                name="Remark"
                rows={8}
                placeholder="Add Customer Notes"
                value={formData.Remark}
                onChange={handleInput}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col md={7} sm={7} lg={7}>
          <Card>
            <Card.Body>
              <Row>
                <Col md={3} className="text-end">
                  <Form.Label>Payment:</Form.Label>
                </Col>
                <Col md={9}>
                  <div className="mb-3 d-flex justify-content-between">
                    <div
                      className="selectStartIcon input-group-text"
                      id="basic-addon1"
                    >
                      <i className="fas fa-credit-card"></i>
                    </div>
                    <Select
                      className="w-100 rounded-end"
                      options={paymentTypeOptions.map((option) => ({
                        value: option.paymentTypeID,
                        label: option.paymentName,
                      }))}
                      value={selectedPaymentOption}
                      onChange={handlePaymentType}
                      placeholder="Mode"
                      required
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={3} className="text-end">
                  <Form.Label>Amount:</Form.Label>
                </Col>
                <Col md={9}>
                  <Form.Group controlId="Invoice_Name">
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <i class="fa fa-inr"></i>
                      </InputGroup.Text>
                      <Form.Control
                        type="number"
                        name="Received_Amount"
                        placeholder="Enter Amount"
                        value={formData.Received_Amount}
                        onChange={handleInput}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <div className="text-center">
        <Button
          type="submit"
          variant="primary"
          className="my-3"
          onClick={insertOrUpdateInvoiceReceive}
        >
          {formData?.InvoiceReceive_ID > 0 ? "Update" : "Save"}
        </Button>
      </div>
    </>
  );
}
