import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import api, { handleApiError } from "../../Utils/api";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import TaskAndIssueComponent from "./TaskAndIssueComponent";
import { convertDateFormat } from "../../Utils/commonFunction";
import {
  Form,
  Button,
  Col,
  Row,
  InputGroup,
  Card,
  Modal,
} from "react-bootstrap";
import { getCurrentDate } from "../../Utils/commonFunction";
import TypeaheadComponent from "../../Components/Common/TypeaheadComponent";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";

const emptyFilterData = {
  start_date: getCurrentDate(),
  end_date: getCurrentDate(),
  ClientID: 0,
};

export default function TaskAndIssue() {
  const [taskAndIssueData, setTaskAndIssueData] = useState([]);
  const [editTaskAndIssueData, seteditTaskAndIssueData] = useState(null);
  const [formData, setFormData] = useState(emptyFilterData);
  const [refreshKey, setRefreshKey] = useState(0);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getTaskAndIssue();
    CheckRoleTemplatePermissions("TaskAndIssue")
      .then((obj) => {
        setRoleTemplatePermissions(obj);
      })
      .catch((error) => null);
  }, []);
  const notifySuccess = (message) => toast.success(message);

  const getTaskAndIssue = () => {
    api
      .get(
        `TaskAndIssue/AllTaskAndIssueDateWise?START_DATE=${formData.start_date}&END_DATE=${formData.end_date}&ClientID=${formData.ClientID}`
      )
      .then(({ data }) => setTaskAndIssueData(data.data))
      .catch(handleApiError);
  };

  const getSingleTaskAndIssue = (id) => {
    api
      .get(`TaskAndIssue/SingleTaskAndIssue?TaskAndIssueID=${id}`)
      .then(({ data }) => seteditTaskAndIssueData(data.data))
      .catch(handleApiError);
  };

  const deleteTaskAndIssue = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`TaskAndIssue/DeleteTaskAndIssue?TaskAndIssueID=${id}`)
          .then(({ status }) => {
            if (status === 200) {
              notifySuccess("Your record has been deleted.");
              getTaskAndIssue();
            }
          })
          .catch(handleApiError);
      }
    });
  };

  const handleClientSelection = (selectedClient) => {
    if (selectedClient) {
      setFormData((prevData) => ({ ...prevData, ClientID: selectedClient }));
    }
  };

  const columnDefs = [
    {
      headerName: "TicketNo",
      field: "ticketNo",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      flex: 1,
      minWidth: 130,
      suppressMovable:true
    },
    {
      headerName: "Type",
      field: "taskAndIssueType",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      flex: 1,
      minWidth: 120,
      suppressMovable:true
    },
    {
      headerName: "ClientName",
      field: "clientName",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      flex: 1,
      minWidth: 400,
      suppressMovable:true
    },
    {
      headerName: "Subject",
      field: "taskAndIssueSubject",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      flex: 1,
      minWidth: 300,
      suppressMovable:true
    },
    {
      headerName: "Developer Name",
      field: "developerName",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      flex: 1,
      minWidth: 180,
      suppressMovable:true
    },
    {
      headerName: "ExpectedResolveDate",
      field: "expectedResolveDate",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      flex: 1,
      cellRenderer: ({ value }) => convertDateFormat(value),
      minWidth: 200,
      suppressMovable:true
    },
    {
      headerName: "Status",
      field: "taskAndIssueStatusMasterName",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      flex: 1,
      minWidth: 120,
      suppressMovable:true
    },
    ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
            headerName: "Edit",
            width: 70,
            field: "taskAndIssueID",
            cellStyle: { textAlign: "center" },
            headerClass: "center-text widthTableColum",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="create-outline"
                class="iconsFonts editIcon"
                onClick={() => getSingleTaskAndIssue(value)}
              ></ion-icon>
            ),
            pinned: "right",
            suppressMovable:true
          },
        ]
      : []),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
      ? [
          {
            headerName: "Delete",
            width: 80,
            field: "taskAndIssueID",
            cellStyle: { textAlign: "center" },
            headerClass: "center-text widthTableColum",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="trash-outline"
                class="iconsFonts deleteIcon"
                onClick={() => deleteTaskAndIssue(value)}
              ></ion-icon>
            ),
            pinned: "right",
            suppressMovable:true
          },
        ]
      : []),
  ];

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      <ToastContainer />
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
        <Card>
          <Card.Header>
            <span>New TaskAndIssue</span>
          </Card.Header>
          <Card.Body>
            <TaskAndIssueComponent
              TaskAndIssueID={getTaskAndIssue}
              editedTaskAndIssueData={editTaskAndIssueData}
              getTaskAndIssueData={getSingleTaskAndIssue}
            />
          </Card.Body>
        </Card>
      )}
      <Card>
        <Card.Header>
          <Row>
            <strong> Task And Issue List </strong>
          </Row>
          <Row className="mt-3">
            <Col md={2} sm={4} lg={2}>
              <Form.Group controlId="Expected_ResolveDate">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <i class="fa fa-calendar"></i>
                  </InputGroup.Text>
                  <Form.Control
                    type="date"
                    name="start_date"
                    value={formData.start_date}
                    onChange={handleInputChange}
                    className="rounded-end"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Expected start_date
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={2} sm={4} lg={2}>
              <Form.Group controlId="Expected_ResolveDate">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <i class="fa fa-calendar"></i>
                  </InputGroup.Text>
                  <Form.Control
                    type="date"
                    name="end_date"
                    value={formData.end_date}
                    onChange={handleInputChange}
                    className="rounded-end"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Expected end_date
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={3} sm={3} lg={3}>
              <Form.Group controlId="Expected_ResolveDate" className="d-flex">
                <InputGroup className="mb-3">
                  <TypeaheadComponent
                    type={"Both"}
                    onClientSelect={handleClientSelection}
                    onClear={refreshKey}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={2}>
              <Button onClick={getTaskAndIssue} variant="outline-success">
                Search
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <AgGridComponent rowData={taskAndIssueData} columnDefs={columnDefs} height="70vh"/>
        </Card.Body>
      </Card>
    </>
  );
}
