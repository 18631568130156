import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import api,{handleApiError} from "../../Utils/api";
import { Card } from "react-bootstrap";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import SourceTypeComponent from "./SourceTypeComponent"
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";

export default function SourceType() {
  const [SourceTypeData, setSourceTypeData] = useState([]);
  const [editSourceTypeData, setEditSourceTypeData] = useState(null);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getSourceType();
    CheckRoleTemplatePermissions("SourceType")
      .then((obj) => {
        setRoleTemplatePermissions(obj);
      })
      .catch((error) => null);
  }, []);

  const notifySuccess = (message) => toast.success(message);

  const getSourceType = () => {
    api
      .get("SourceType/AllSourceType")
      .then(({ data }) => setSourceTypeData(data.data))
      .catch(handleApiError);
  };

  const getSingleSourceType = (id) => {
    
    api
      .get(`SourceType/SingleSourceType?SourceTypeID=${id}`)
      .then(({ data }) => setEditSourceTypeData(data.data))
      .catch(handleApiError);
  };

  const deleteSourceType = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`SourceType/DeleteSourceType?SourceTypeID=${id}`)
          .then(({ status }) => {
            if (status === 200) {
              notifySuccess("Your record has been deleted.");
              getSourceType();
            }
          })
          .catch(handleApiError);
      }
    });
  };

  const columnDefs = [
    {
      headerName: "SourceType  Name",
      field: "sourceName",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      flex: 1,
      minWidth:200
    }, ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
      headerName: "Edit",
      width: 70,
      field: "sourceTypeID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="create-outline"
          class="iconsFonts editIcon"
          onClick={() => getSingleSourceType(value)}
        ></ion-icon>
      ),
      pinned:"right"
    },
  ]
  : []),
...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
  ? [
      {
      headerName: "Delete",
      width: 80,
      field: "sourceTypeID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="trash-outline"
          class="iconsFonts deleteIcon"
          onClick={() => deleteSourceType(value)}
        ></ion-icon>
      ),
      pinned:"right"
    },
  ]
: []),
  ];

  return (
    <>
      <ToastContainer />
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
      <Card>
        <Card.Header>
          <span>New SourceType</span>
        </Card.Header>
        <Card.Body>
          <SourceTypeComponent
            SourceTypeID={getSourceType}
            editedSourceTypeData={editSourceTypeData}
            getSourceTypeData={getSingleSourceType}
          />
        </Card.Body>
      </Card>)}
      <Card>
        <Card.Header>
          <span>SourceType List</span>
        </Card.Header>
        <Card.Body>
          <AgGridComponent rowData={SourceTypeData} columnDefs={columnDefs} />
        </Card.Body>
      </Card>
    </>
  );
}
