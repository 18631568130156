import React, { useState, useEffect } from "react";
import api, { handleApiError } from "../../Utils/api";
import { Form, Col, InputGroup, Modal } from "react-bootstrap";
import SMSTemplateComponent from "./SMSTemplateComponent";
import Select from "react-select";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";

export default function SMSTemplateDDComponent({
  onSelectSMSTemplate,
  selectedSMSTemplate,
  onAdditionalChange,
  ValidationError,
  ColSize,
}) {
  const [SMSTemplateOptions, setSMSTemplateOptions] = useState([]);
  const [isSMSTemplateModalOpen, setIsSMSTemplateModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [validation, setValidation] = useState(false);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getSMSTemplateName();
    CheckRoleTemplatePermissions("SMSTemplate")
      .then((obj) => {
        setRoleTemplatePermissions(obj);
      })
      .catch((error) => null);
  }, []);

  useEffect(() => {
    if (ValidationError && selectedSMSTemplate === 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  }, [ValidationError, selectedSMSTemplate]);

  useEffect(() => {
    if (selectedSMSTemplate) {
      const selectedOption = SMSTemplateOptions.find(
        (option) => option.smsTemplateID === selectedSMSTemplate
      );
      setSelectedOption({
        value: selectedOption?.smsTemplateID,
        label: selectedOption?.smsTemplateName,
      });
      setValidation(false);
    }
  }, [selectedSMSTemplate, SMSTemplateOptions]);

  // Fetch all City Name
  const getSMSTemplateName = () => {
    api
      .get("SMSTemplate/AllSMSTemplate")
      .then(({ data }) => setSMSTemplateOptions(data.data))
      .catch(handleApiError);
  };

  const handleSMSTemplateChange = (option) => {
    setSelectedOption(option);
    onSelectSMSTemplate(option ? option.value : null);

    if (onAdditionalChange) {
      onAdditionalChange({ onAdditionalChange });
    }
  };

  // Insert New State
  const SMSTemplateModalOpen = () => {
    setIsSMSTemplateModalOpen(true);
  };

  const closeSMSTemplateModal = () => {
    setIsSMSTemplateModalOpen(false);
  };

  const selectDDSMSTemplateID = (smsTemplateID) => {
    handleSMSTemplateChange({ value: smsTemplateID });
    getSMSTemplateName();
    closeSMSTemplateModal();
  };

  return (
    <>
      <Col
        md={ColSize != null ? ColSize : 4}
        sm={ColSize != null ? ColSize : 4}
        lg={ColSize != null ? ColSize : 4}
      >
        <Form.Group controlId="SMSTemplate_id">
          <Form.Label>SMSTemplate Name:</Form.Label>
          <div className="mb-3 d-flex justify-content-between">
            <div className="selectStartIcon input-group-text" id="basic-addon1">
              <i className="fas fa-user"></i>
            </div>
            <Select
              className={`w-100 ${validation ? "borderRed" : ""} ${
                RoleTemplatePermissions?.addPermission
                  ? "RadiusNo"
                  : "RadiusYes"
              }`}
              options={SMSTemplateOptions.map((option) => ({
                value: option.smsTemplateID,
                label: option.smsTemplateName,
              }))}
              value={selectedOption}
              onChange={handleSMSTemplateChange}
              placeholder="Select SMSTemplate Name"
              required
            />
            {RoleTemplatePermissions &&
              RoleTemplatePermissions.addPermission && (
                <div className="selectEndIcon input-group-text">
                  <i
                    className="fa fa-plus plusIcon"
                    onClick={SMSTemplateModalOpen}
                  ></i>
                </div>
              )}
          </div>
          {validation && (
            <div className="ValidationErrorMsg">
              Please select an SMSTemplate.
            </div>
          )}
        </Form.Group>
      </Col>

      {/* City Modal */}
      <Modal
        show={isSMSTemplateModalOpen}
        onHide={closeSMSTemplateModal}
        size="xl"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton closeVariant={"white"}>
          <Modal.Title id="example-custom-modal-styling-title">
            New SMSTemplate
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <SMSTemplateComponent
              SMSTemplateID={selectDDSMSTemplateID}
            ></SMSTemplateComponent>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
}
