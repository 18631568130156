import React, { useState, useEffect } from "react";
import api, { handleApiError } from "../../Utils/api";
import { Form, Col, InputGroup, Modal } from "react-bootstrap";
import DeveloperComponent from "./DeveloperComponent";
import Select from "react-select";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";

export default function DeveloperDDComponent({
  onSelectDeveloper,
  selectedDeveloper,
  onAdditionalChange,
  ValidationError,
}) {
  const [DeveloperOptions, setDeveloperOptions] = useState([]);
  const [isDeveloperModalOpen, setIsDeveloperModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [validation, setValidation] = useState(false);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getDeveloperName();
    CheckRoleTemplatePermissions("Developer")
      .then((obj) => {
        setRoleTemplatePermissions(obj);
      })
      .catch((error) => null);
  }, []);

  useEffect(() => {
    if (ValidationError && selectedDeveloper === 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  }, [ValidationError, selectedDeveloper]);

  useEffect(() => {
    // Set initial value for the dropdown during edit time
    if (selectedDeveloper) {
      const selectedOption = DeveloperOptions.find(
        (option) => option.developerID === selectedDeveloper
      );
      setSelectedOption({
        value: selectedOption?.developerID,
        label: selectedOption?.developerName,
      });
      setValidation(false);
    }
  }, [selectedDeveloper, DeveloperOptions]);

  // Fetch all City Name
  const getDeveloperName = () => {
    api
      .get("Developer/AllDeveloper")
      .then(({ data }) => setDeveloperOptions(data.data))
      .catch(handleApiError);
  };

  const handleDeveloperChange = (option) => {
    setSelectedOption(option);
    onSelectDeveloper(option ? option.value : null);

    if (onAdditionalChange) {
      onAdditionalChange({ onAdditionalChange });
    }
  };

  // Insert New State
  const DeveloperModalOpen = () => {
    setIsDeveloperModalOpen(true);
  };

  const closeDeveloperModal = () => {
    setIsDeveloperModalOpen(false);
  };

  const selectDDDeveloperID = (DeveloperID) => {
    handleDeveloperChange({ value: DeveloperID });
    getDeveloperName();
    closeDeveloperModal();
  };

  return (
    <>
      <Col md={4} sm={4} lg={4}>
        <Form.Group controlId="Developer_id">
          <Form.Label>Developer Name:</Form.Label>
          <div className="mb-3 d-flex justify-content-between">
            <div className="selectStartIcon input-group-text" id="basic-addon1">
              <i className="fas fa-user"></i>
            </div>
            <Select
              className={`w-100 ${validation ? "borderRed" : ""} ${
                RoleTemplatePermissions?.addPermission
                  ? "RadiusNo"
                  : "RadiusYes"
              }`}
              options={DeveloperOptions.map((option) => ({
                value: option.developerID,
                label: option.developerName,
              }))}
              value={selectedOption}
              onChange={handleDeveloperChange}
              placeholder="Select Developer Name"
            />
            {RoleTemplatePermissions &&
              RoleTemplatePermissions.addPermission && (
                <div className="selectEndIcon input-group-text">
                  <i
                    className="fa fa-plus plusIcon"
                    onClick={DeveloperModalOpen}
                  ></i>
                </div>
              )}
          </div>
          {validation && (
            <div className="ValidationErrorMsg">
              Please select an Developer.
            </div>
          )}
        </Form.Group>
      </Col>

      {/* City Modal */}
      <Modal
        show={isDeveloperModalOpen}
        onHide={closeDeveloperModal}
        size="xl"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton closeVariant={"white"}>
          <Modal.Title id="example-custom-modal-styling-title">
            New Developer
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <DeveloperComponent
              DeveloperID={selectDDDeveloperID}
            ></DeveloperComponent>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
}
