import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../Utils/api";
import { Form, Button, Col, Row, InputGroup } from "react-bootstrap";
import Select from "react-select";
import ExpenseMasterDDComponent from "../ExpenseMaster/ExpenseMasterDDComponent";
import { format } from "date-fns";

const initialExpenseMaster = {
  expenseVoucher_Id: 0,
  expense_Date: "",
  expenseMaster_Id: 0,
  bill_Date: "",
  bill_No: "",
  expensePayment_Date: "",
  PaymentType_ID: 0,
  expense_Amount: "",
};

export default function ExpenseMasterComponent({
  expenseVoucherID,
  editexpenseVoucherData,
  getExpenseMasterData,
}) {
  const [refreshKey, setRefreshKey] = useState(0);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(initialExpenseMaster);
  const expenseMasterFormRef = useRef(null);
  const [ddValidationError, setDDValidationError] = useState(false);
  const [paymentTypeOptions, setPaymentTypeOptions] = useState([]);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);
  const ExpenseVoucherFormRef = useRef(null);

  useEffect(() => {
    api
      .get("Invoice/AllPaymentTypeName")
      .then(({ data }) => setPaymentTypeOptions(data.data))
      .catch((error) => {
        console.error("Error fetching payment types:", error);
      });
  }, [refreshKey]);

  useEffect(() => {
    if (editexpenseVoucherData) {
      const selectedPayment = paymentTypeOptions.find(
        (payment) =>
          payment.paymentTypeID === editexpenseVoucherData.paymentTypeID
      );

      setFormData({
        expenseVoucher_Id: editexpenseVoucherData.expenseVoucherID,
        expense_Date: format(
          editexpenseVoucherData.expenseVoucherDate,
          "yyyy-MM-dd"
        ),
        expenseMaster_Id: editexpenseVoucherData.expenseMasterID,
        bill_Date: format(editexpenseVoucherData.billDate, "yyyy-MM-dd"),
        bill_No: editexpenseVoucherData.billNo,
        expensePayment_Date: format(
          editexpenseVoucherData.expensePaymentDate,
          "yyyy-MM-dd"
        ),
        PaymentType_ID: editexpenseVoucherData.paymentTypeID,
        expense_Amount: editexpenseVoucherData.amount,
      });
      setSelectedPaymentOption({
        value: selectedPayment.paymentTypeID,
        label: selectedPayment.paymentName,
      });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [editexpenseVoucherData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Update the expense date
    if (name === "expense_Date") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        expensePayment_Date: value, // Set payment expense date to expense date
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handlePaymentType = (option) => {
    setSelectedPaymentOption(option);
    setFormData((prevData) => ({ ...prevData, PaymentType_ID: option.value }));
  };

  const insertExpensVoucher = (event) => {
    setValidated(true);
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setDDValidationError(formData.expenseVoucher_Id === 0);
      return;
    } else {
      const data = {
        expenseVoucherID: formData?.expenseVoucher_Id || 0,
        expenseVoucherDate: formData?.expense_Date,
        expenseMasterID: formData?.expenseMaster_Id,
        billDate: formData?.bill_Date,
        billNo: formData?.bill_No,
        expensePaymentDate: formData?.expensePayment_Date,
        paymentTypeID: formData?.PaymentType_ID,
        amount: formData?.expense_Amount,
      };
      const endpoint =
        formData.expenseVoucher_Id === 0
          ? "ExpenseVoucher/InsertExpenseVourcher"
          : "ExpenseVoucher/EditExpenseVoucher";

      api[formData.expenseVoucher_Id === 0 ? "post" : "patch"](endpoint, data)
        .then((result) => {
          if (result.status === 200) {
            toast.success(result.data.message);
            if (expenseVoucherID) {
              expenseVoucherID(result.data.data);
            }
          } else {
            toast.error(result.data.message);
          }
          setRefreshKey(refreshKey + 1);
        })
        .catch((error) => {
          console.error("Error inserting/updating expense master:", error);
          toast.error("An error occurred");
        });

      resetForm();
      setValidated(false);
    }
  };

  const resetForm = () => {
    setFormData(initialExpenseMaster);
    setSelectedPaymentOption(null);
  };

  return (
    <>
      <Form
        noValidate
        validated={validated}
        onSubmit={(e) => {
          if (e) {
            e.preventDefault();
          }

          if (ExpenseVoucherFormRef.current && e) {
            // Check for the formName to call insertCity
            if (e.target.name === "ExpenseVoucherForm") {
              insertExpensVoucher(e);
            }
          }
        }}
        ref={ExpenseVoucherFormRef}
        name="ExpenseVoucherForm"
      >
        <Row>
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="expense_Date">
              <Form.Label>Expense Date:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-calendar"></i>
                </InputGroup.Text>
                <Form.Control
                  type="date"
                  name="expense_Date"
                  placeholder="Select Expense Date"
                  value={formData.expense_Date}
                  onChange={handleInputChange}
                  required
                />
              </InputGroup>
              {validated && !formData.expense_Date && (
                <div className="invalid-feedback">
                  Please select Expense Date.
                </div>
              )}
            </Form.Group>
          </Col>
          
            <ExpenseMasterDDComponent
              onSelectExpenseMaster={(expenseMasterID) => {
                setFormData((prevData) => ({
                  ...prevData,
                  expenseMaster_Id: expenseMasterID,
                }));
              }}
              ValidationError={ddValidationError}
              selectedExpenseMaster={formData.expenseMaster_Id}
              key={refreshKey}
            />
          
          </Row>
          <Row>
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="bill_Date">
              <Form.Label>Bill Date:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-calendar"></i>
                </InputGroup.Text>
                <Form.Control
                  type="date"
                  name="bill_Date"
                  placeholder="Select Bill Date"
                  value={formData.bill_Date}
                  onChange={handleInputChange}
                  required
                />
              </InputGroup>
              {validated && !formData.bill_Date && (
                <div className="invalid-feedback">Please select Bill Date.</div>
              )}
            </Form.Group>
          </Col>
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="bill_No">
              <Form.Label>Bill No:</Form.Label>
              <InputGroup className="mb-5">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-file-invoice-dollar"></i>
                </InputGroup.Text>
                <Form.Control
                  type="number"
                  name="bill_No"
                  placeholder="Enter Bill No"
                  value={formData.bill_No}
                  onChange={handleInputChange}
                  required
                />
              </InputGroup>
              {validated && !formData.bill_No && (
                <div className="invalid-feedback">Please enter Bill No.</div>
              )}
            </Form.Group>
          </Col>
        </Row>

        <div className="border-bottom"></div>
        <Row className="mt-4">
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="expensePayment_Date">
              <Form.Label>Expense Payment Date:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-calendar"></i>
                </InputGroup.Text>
                <Form.Control
                  type="date"
                  name="expensePayment_Date"
                  placeholder="Enter Expense Payment Date"
                  value={formData.expensePayment_Date}
                  onChange={handleInputChange}
                  required
                />
              </InputGroup>
              {validated && !formData.expensePayment_Date && (
                <div className="invalid-feedback">
                  Please enter Expense Payment Date.
                </div>
              )}
            </Form.Group>
          </Col>
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="PaymentType_ID">
              <Form.Label>Payment Type:</Form.Label>
              <div className="mb-3 d-flex justify-content-between">
                <div
                  className="selectStartIcon input-group-text"
                  id="basic-addon1"
                >
                  <i className="fas fa-credit-card"></i>
                </div>
                <Select
                  className="w-100 rounded-end"
                  options={paymentTypeOptions.map((option) => ({
                    value: option.paymentTypeID,
                    label: option.paymentName,
                  }))}
                  value={selectedPaymentOption}
                  onChange={handlePaymentType}
                  placeholder="Select Payment Type"
                  required
                />
              </div>
            </Form.Group>
          </Col>

          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="expense_Amount">
              <Form.Label>Amount:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-dollar-sign"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="expense_Amount"
                  placeholder="Enter Amount"
                  value={formData.expense_Amount}
                  onChange={handleInputChange}
                  required
                />
              </InputGroup>
              {validated && !formData.expense_Amount && (
                <div className="invalid-feedback">Please enter Amount.</div>
              )}
            </Form.Group>
          </Col>
        </Row>

        <div className="card-footer margin_top10">
          <Button type="submit" variant="primary" className="mb-20px">
            {formData?.expenseVoucher_Id > 0 ? "Update" : "Save"}
          </Button>
        </div>
      </Form>
    </>
  );
}
