import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import api, { handleApiError } from "../../Utils/api";
import { Card } from "react-bootstrap";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import PrintInvoiceRecieveComponent from "./PrintInvoiceRecieveComponent";
import { convertDateFormat } from "../../Utils/commonFunction";
import InvoiceReceiveComponent from "./InvoiceReceiveComponent";
import ReactDOMServer from "react-dom/server";
import {
  Form,
  Button,
  Col,
  Row,
  InputGroup,
} from "react-bootstrap";
import { getCurrentDate } from "../../Utils/commonFunction";
import TypeaheadComponent from "../../Components/Common/TypeaheadComponent";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";

export default function InvoiceReceive() {
  const DateTimeFilterData = {
    start_date: getCurrentDate(),
    end_date: getCurrentDate(),
    ClientID: 0,
  };

  const [InvoiceReceiveData, setInvoiceReceiveData] = useState([]);
  const [editInvoiceReceiveData, setEditInvoiceReceiveData] = useState(null);
  const [formData, setFormData] = useState(DateTimeFilterData);
  const [refreshKey, setRefreshKey] = useState(0);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getInvoiceReceive();
    CheckRoleTemplatePermissions("InvoiceReceive")
      .then((obj) => {
        setRoleTemplatePermissions(obj);
      })
      .catch((error) => null);
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const notifySuccess = (message) => toast.success(message);

  const getInvoiceReceive = () => {
    
    api
      .get("InvoiceReceive/AllInvoiceReceive")
      .then(({ data }) => setInvoiceReceiveData(data.data))
      .catch(handleApiError);
  };

  const getSingleInvoiceReceive = (id) => {
    api
      .get(`InvoiceReceive/SingleInvoiceReceive?InvoiceReceiveID=${id}`)
      .then(({ data }) => setEditInvoiceReceiveData(data.data))
      .catch(handleApiError);
  };

  const getinvoicerecievefilter = () => {
    
    api
      .get(
        `InvoiceReceive/FilterInvoiceData?START_DATE=${formData.start_date}&END_DATE=${formData.end_date}&ClientID=${formData.ClientID}`
      )
      .then(({ data }) => setInvoiceReceiveData(data.data))
      .catch(handleApiError);
  };

  const deleteInvoiceReceive = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`InvoiceReceive/DeleteInvoiceReceive?InvoiceReceiveID=${id}`)
          .then(({ status }) => {
            if (status === 200) {
              notifySuccess("Your record has been deleted.");
              getInvoiceReceive();
            }
          })
          .catch(handleApiError);
      }
    });
  };

  const columnDefs = [
    {
      headerName: "InvoiceNo",
      field: "invoiceNo",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      flex: 1,
      minWidth: 120,
      suppressMovable:true
    },
    {
      headerName: "Invoice ReceiveNo",
      field: "invoiceReceiveNo",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      minWidth: 100,
      flex: 1,
      suppressMovable:true
    },
    {
      headerName: "Invoice ReceiveDate",
      field: "invoiceReceiveDate",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      cellRenderer: ({ value }) => convertDateFormat(value),
      flex: 1,
      minWidth: 120,
      suppressMovable:true
    },

    {
      headerName: "Client Name",
      field: "clientName",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      flex: 1,
      minWidth: 400,
      suppressMovable:true
    },
    {
      headerName: "Receive Amount",
      field: "amount",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      flex: 1,
      suppressMovable:true,
      minWidth: 100,
    }, ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
      headerName: "Edit",
      width: 70,
      field: "invoiceReceiveID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="create-outline"
          class="iconsFonts editIcon"
          onClick={() => getSingleInvoiceReceive(value)}
        ></ion-icon>
      ),
      pinned: "right", 
       suppressMovable:true// Pin to the right side
    },
   ]
      : []),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
      ? [
          {
      headerName: "Delete",
      width: 80,
      field: "invoiceReceiveID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="trash-outline"
          class="iconsFonts deleteIcon"
          onClick={() => deleteInvoiceReceive(value)}
        ></ion-icon>
      ),
      pinned: "right",
      suppressMovable:true // Pin to the right side
    },
  ]
: []),
    {
      headerName: "Print",
      width: 70,
      field: "invoiceReceiveID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="print-outline"
          class="iconsFonts editIcon"
          onClick={() => openPrintTab(value)}
        ></ion-icon>
      ),
      pinned: "right",
      suppressMovable:true // Pin to the right side
    },
  ];

  const handleClientSelection = (selectedClient) => {
    
    if (selectedClient) {
      setFormData((prevData) => ({ ...prevData, ClientID: selectedClient }));
    }
  };

  const openPrintTab = (invoiceReceiveID) => {
    const printWindow = window.open("", "_blank");
    api
      .get(
        `InvoiceReceive/InvoiceRecieptPrint?InvoiceReceiveID=${invoiceReceiveID}`
      )
      .then(({ data }) => {
        const printComponent = (
          <PrintInvoiceRecieveComponent InvoiceReceiveData={data.data} />
        );
        const printContent =
          ReactDOMServer.renderToStaticMarkup(printComponent);

        if (printWindow) {
          printWindow.document.write(`
            <html>
              <head>
                <title>Print</title>
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet">
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
              </head>
              <body>
                ${printContent}
              </body>
            </html>`);

          setTimeout(() => {
            printWindow.print();
          }, 300);

          // printWindow.close();
        }
      })
      .catch((error) => {
        console.error("API error:", error);
      });
  };

  return (
    <>
      <ToastContainer />
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
      <Card>
        <Card.Header>
          <span>Client Invoice Receive</span>
        </Card.Header>
        <Card.Body>
          <InvoiceReceiveComponent
            InvoiceReceiveID={getInvoiceReceive}
            editedInvoiceReceiveData={editInvoiceReceiveData}
            getInvoiceReceiveData={getSingleInvoiceReceive}
          />
        </Card.Body>
      </Card>)}
      <Card>
        <Card.Header>
          <span>Invoice List</span>
        </Card.Header>
        <Card.Body>
          <Card.Body>
            <Row className="justify-content-center">
              <Col md={3} sm={3} lg={3}>
                <Form.Group controlId="Expected_ResolveDate" className="d-flex">
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <i className="fa fa-calendar"></i>
                    </InputGroup.Text>
                    <Form.Control
                      type="date"
                      name="start_date"
                      value={formData.start_date}
                      onChange={handleInputChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Expected start_date
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={3} sm={3} lg={3}>
                <Form.Group controlId="Expected_ResolveDate" className="d-flex">
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <i className="fa fa-calendar"></i>
                    </InputGroup.Text>
                    <Form.Control
                      type="date"
                      name="end_date"
                      value={formData.end_date}
                      onChange={handleInputChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Expected end_date
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={3} sm={3} lg={3}>
                <Form.Group controlId="Expected_ResolveDate" className="d-flex">
                  <InputGroup className="mb-3">
                    <TypeaheadComponent
                      type={"Client"}
                      onClientSelect={handleClientSelection}
                      onClear={refreshKey}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={1} sm={1} lg={1}>
                <Button
                  variant="outline-success"
                  onClick={getinvoicerecievefilter}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card.Body>
        <Card.Body>
          <AgGridComponent
            rowData={InvoiceReceiveData}
            columnDefs={columnDefs}
          />
        </Card.Body>
      </Card>
    </>
  );
}
