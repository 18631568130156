import React, { useState, useEffect } from "react";
import "./OpportunityDataComponent.css";
import { Card, Modal, Form } from "react-bootstrap";
import api, { handleApiError } from "../../Utils/api";
import { convertDateFormat } from "../../Utils/commonFunction";
import ManageClientLeadPopup from "../ManageClientLead/ManageClientLeadPopup";


export default function OpportunityDataComponent({
  clientID,
  pipelineID,
  areaID,
}) {
  const [pipelinesStageList, setPipelinesStageList] = useState([]);
  const [allOpportunities, setAllOpportunities] = useState([]);
  const [selectedClientID, setSelectedClientID] = useState(null);

  useEffect(() => {
    if (clientID || pipelineID || areaID) {
      setAllOpportunities([]);
      setPipelinesStageList([]);
      getSinglePipeline();
    }
  }, [clientID, pipelineID, areaID]);

  const getSinglePipeline = () => {
    api
      .get(
        `Opportunity/ShowPipelinesList?searchPipelinesMasterID=${pipelineID}&clientID=${clientID}&AreaID=${areaID}`
      )
      .then(({ data }) => {
        setPipelinesStageList(data.data.pipelinesStageList);
        setAllOpportunities(data.data.allOpportunities);
      })
      .catch(handleApiError);
  };

  const filterOpportunitiesByStage = (stageID) =>
    allOpportunities.filter((item) => item.pipelineStageID === stageID);

  const openClientModal = (clientID) => {
    
    setSelectedClientID(clientID);
  };

  return (
    <>
      <Card>
        <div className="pipeline-container">
          <div className="pipeline-stages">
            {pipelinesStageList.map((stage, index) => (
              <div key={index} className="pipeline-stage">
                <Card.Header>
                  <div className="cardnumber">
                    {/* {
                      allOpportunities.filter(
                        (item) => item.pipelineStageID === stage.pipelineStageID
                      ).length
                    } */}
                    {filterOpportunitiesByStage(stage.pipelineStageID).length}
                  </div>
                  <div>
                    <h4>{stage.pipelineStageName} </h4>
                  </div>
                </Card.Header>
                <div className="scrollable-data">
                  <ul>
                    {/* {allOpportunities
                      .filter(
                        (item) => item.pipelineStageID === stage.pipelineStageID
                      ) */}
                    {filterOpportunitiesByStage(stage.pipelineStageID).map(
                      (filteredItem, i) => (
                        <li key={i}>
                          <div className="cardDiv">
                            <table>
                              <tr>
                                <td colSpan={2}>
                                  <div
                                    className="ClientName"
                                    onClick={() =>
                                      openClientModal(filteredItem.clientID)
                                    }
                                  >
                                    <i className="fa fa-building-o"></i>
                                    &nbsp;&nbsp;{filteredItem.clientName}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2} className="address">
                                  <label>
                                    <i className="fa fa-map-marker"></i>{" "}
                                    Address:
                                  </label>{" "}
                                  {filteredItem.address}
                                </td>
                              </tr>
                              <tr>
                                <td className="date">
                                  <i className="fa fa-calendar"></i>
                                  &nbsp;&nbsp;
                                  {convertDateFormat(filteredItem.createdDate)}
                                </td>
                                <td className="mobile">
                                  <i className="fa fa-phone fa-flip-horizontal"></i>
                                  &nbsp;&nbsp;{filteredItem.clientMobileNumber}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>


      {selectedClientID && (
        <ManageClientLeadPopup
          clientID={selectedClientID}
          onClose={() => { setSelectedClientID(null); getSinglePipeline(); }}
        />
      )}
    </>
  );
}
