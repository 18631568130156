import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api, { handleApiError } from "../../Utils/api";
import { Form, Button, Col, Row, InputGroup } from "react-bootstrap";
import SMSApiDDComponent from "../SMSApi/SMSApiDDComponent";

const emptySMSTemplate = {
  SMSTemplate_id: 0,
  SMSTemplate_Name:"",
  SMSApi_id: 0,
  SMSText: "",
  CCNumber: "",
  IsActive: true,
};

export default function SMSTemplateComponent({
  SMSTemplateID,
  editedSMSTemplateData,
  getSMSTemplateData,
}) {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptySMSTemplate);
  const [refreshKey, setRefreshKey] = useState(0);
  const [SMSApiDDValidationError, setSMSApiValidationError] = useState(false);

  const SMSTemplateFormRef = useRef(null);

  useEffect(() => {
    
    if (editedSMSTemplateData) {
      setFormData({
        SMSTemplate_id:editedSMSTemplateData.smsTemplateID,
        SMSTemplate_Name :editedSMSTemplateData.smsTemplateName,
        SMSApi_id: editedSMSTemplateData.smsApiID,
        SMSText: editedSMSTemplateData.smsText,
        CCNumber: editedSMSTemplateData.ccNumber,
        IsActive: editedSMSTemplateData.isActive,
      });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [editedSMSTemplateData]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
  };

  const insertOrUpdateSMSTemplate = (event) => {
    
    setValidated(true);
    event.preventDefault();

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setSMSApiValidationError(formData.SMSApi_id === 0);
      return;
    }
    const data = {
        SMSTemplateID : formData?.SMSTemplate_id||0,
        SMSTemplateName : formData.SMSTemplate_Name,
        SMSText:formData.SMSText,
        CCNumber:formData.CCNumber,
        IsActive:formData.IsActive,
        SMSApiID:formData.SMSApi_id,
    };

    const endpoint =
      formData.SMSTemplate_id === 0
        ? "SMSTemplate/InsertSMSTemplate"
        : "SMSTemplate/EditSMSTemplate";

    api[formData.SMSTemplate_id === 0 ? "post" : "patch"](endpoint, data)
      .then((result) => {
        if (result.status === 200) {
          toast.success(result.data.message);
          if (SMSTemplateID) {
            SMSTemplateID(result.data.data);
          }
        } else {
          toast.error(result.data.message);
        }
        setRefreshKey((prevKey) => prevKey + 1);
      })
      .catch(handleApiError);

    resetForm();
    setValidated(false);
  };

  const resetForm = () => {
    setFormData(emptySMSTemplate);
  };

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={(e) => {
        if (e) {
          e.preventDefault();
        }

        if (SMSTemplateFormRef.current && e) {
          if (e.target.name === "SMSTemplateForm") {
            insertOrUpdateSMSTemplate(e);
          }
        }
      }}
      ref={SMSTemplateFormRef}
      name="SMSTemplateForm"
    >
      <Row>
        <SMSApiDDComponent
            onSelectSMSApi={(SMSApiID) => {
              setFormData((prevData) => ({
                ...prevData,
                SMSApi_id: SMSApiID,
              }));
              setSMSApiValidationError(false); // Reset areaDDValidationError when selecting an area
            }}
            selectedSMSApi={formData.SMSApi_id}
            ValidationError={SMSApiDDValidationError} // Pass the validation error to AreaDDComponent
            key={`SMSApiDDComponent-${refreshKey}`}
          />
          <Col md={4} sm={2} lg={4}>
            <Form.Group controlId="Client_MobileNumber">
              <Form.Label>Template Name:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i class="fas fa-tag"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="SMSTemplate_Name"
                  placeholder="Enter Template Name"
                  value={formData.SMSTemplate_Name}
                  onChange={handleInputChange}
                  required
                />
                {validated && !formData.SMSTemplate_Name && (
                  <div className="invalid-feedback">
                    Please Enter MobileNumber Name.
                  </div>
                )}
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={4} sm={2} lg={4}>
            <Form.Group controlId="Client_MobileNumber">
              <Form.Label>CC Number:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i class="fas fa-mobile"></i>
                </InputGroup.Text>
                <Form.Control
                  type="number"
                  name="CCNumber"
                  placeholder="Enter CC Number"
                  value={formData.CCNumber}
                  onChange={handleInputChange}
                  required
                />
                {validated && !formData.CCNumber && (
                  <div className="invalid-feedback">
                    Please Enter MobileNumber Name.
                  </div>
                )}
              </InputGroup>
            </Form.Group>
          </Col>
      </Row>
      <Row>
        <Col md={8} sm={8} lg={8}>
          <Form.Group controlId="HeaderField">
            <Form.Label> SMS Text</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <i className="fa fa-commenting"></i>
              </InputGroup.Text>
              <Form.Control
                as="textarea" // Use textarea
                rows={6} // Specify the number of rows you want
                name="SMSText" // Using camelCase
                placeholder="Enter SMS Text"
                value={formData.SMSText}
                onChange={handleInputChange}
              />
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row>
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="isActive">
              <Form.Label></Form.Label>
              <Form.Check
                type="checkbox"
                label="Is Active"
                name="IsActive"
                checked={formData.IsActive}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row>

      <div className="card-footer margin_top10">
        <Button type="submit" variant="primary" className="mb-20px">
          {formData?.SMSTemplate_id > 0 ? "Update" : "Save"}
        </Button>
      </div>
    </Form>
  );
}
