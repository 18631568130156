import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card} from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import CalenderFilterData from "./CalenderFilterData";
import CalendarDataWithCalendar from "./CalendarDataWithCalendar";

export default function CalendarData() {
 
  return (
    <>
      <ToastContainer />
      <Card>
        <Tabs
          defaultActiveKey="home"
          id="uncontrolled-tab-example"
          variant="underline"
        >
          <Tab eventKey="home" title="CALENDAR" className="mr-4 ">
            <CalendarDataWithCalendar/>
          </Tab>
          <Tab eventKey="profile" title="CALENDAR DATA">
            <CalenderFilterData />
          </Tab>
        </Tabs>
      </Card>
    </>
  );
}
