import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import api from "../../Utils/api";
import { Card } from "react-bootstrap";
import StateComponent from "./StateComponent";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";

export default function State() {
  const [StateData, setStateData] = useState([]);
  const [editStateData, setEditStateData] = useState(null);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getState();
    CheckRoleTemplatePermissions("State")
      .then((obj) => {
        setRoleTemplatePermissions(obj);
      })
      .catch((error) => null);
  }, []);

  // Fetch all States
  const getState = () => {
    api
      .get("State/AllState")
      .then((result) => {
        setStateData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Get data of a single State
  const getSingleState = (id) => {
    api
      .get(`State/SingleState?StateID=${id}`)
      .then((result) => {
        const StateData = result.data.data;
        setEditStateData(StateData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Delete State
  const deleteState = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`State/DeleteState?StateID=${id}`)
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              getState();
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  };

  // AG Grid Table Column
  const columnDefs = [
    {
      headerName: "State Name",
      field: "stateName",
      flex: 1,
      sortable: true,
      filter: true,
      cellStyle: { textAlign: "left" },
      minWidth: 200,
    },
    ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
            headerName: "Edit",
            width: 70,
            field: "stateID",
            cellStyle: { textAlign: "center" },
            headerClass: "center-text widthTableColum",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="create-outline"
                class="iconsFonts editIcon"
                onClick={() => getSingleState(value)}
              ></ion-icon>
            ),
            pinned: "right",
          },
        ]
      : []),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
      ? [
          {
            headerName: "Delete",
            width: 80,
            field: "stateID",
            cellStyle: { textAlign: "center" },
            headerClass: "center-text widthTableColum",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="trash-outline"
                class="iconsFonts deleteIcon"
                onClick={() => deleteState(value)}
              ></ion-icon>
            ),
            pinned: "right",
          },
        ]
      : []),
  ];

  return (
    <>
      <ToastContainer />
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
        <Card>
          <Card.Header>
            <span>New State</span>
          </Card.Header>
          <Card.Body>
            <Card.Body>
              <StateComponent
                stateID={getState}
                editStateData={editStateData}
              />
            </Card.Body>
          </Card.Body>
        </Card>
      )}
      <Card>
        <Card.Header>
          <span>State List</span>
        </Card.Header>
        <Card.Body>
          <AgGridComponent rowData={StateData} columnDefs={columnDefs} />
        </Card.Body>
      </Card>
    </>
  );
}
