import React, { useState, useEffect } from "react";
import api, { handleApiError } from "../../Utils/api";
import { Form, Col, Modal } from "react-bootstrap";
import SMSApiComponent from "./SMSApiComponent";
import Select from "react-select";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";

export default function SMSApiDDComponent({
  onSelectSMSApi,
  selectedSMSApi,
  onAdditionalChange,
  ValidationError,
  ColSize,
}) {
  const [SMSApiOptions, setSMSApiOptions] = useState([]);
  const [isSMSApiModalOpen, setIsSMSApiModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [validation, setValidation] = useState(false);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getSMSApiName();
    CheckRoleTemplatePermissions("SMSApi")
      .then((obj) => {
        setRoleTemplatePermissions(obj);
      })
      .catch((error) => null);
  }, []);

  useEffect(() => {
    if (ValidationError && selectedSMSApi === 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  }, [ValidationError, selectedSMSApi]);

  useEffect(() => {
    if (selectedSMSApi) {
      const selectedOption = SMSApiOptions.find(
        (option) => option.smsApiID === selectedSMSApi
      );
      setSelectedOption({
        value: selectedOption?.smsApiID,
        label: selectedOption?.smsCompanyName,
      });
      setValidation(false);
    }
  }, [selectedSMSApi, SMSApiOptions]);

  // Fetch all City Name
  const getSMSApiName = () => {
    api
      .get("SMSApi/AllSMSApi")
      .then(({ data }) => setSMSApiOptions(data.data))
      .catch(handleApiError);
  };

  const handleSMSApiChange = (option) => {
    setSelectedOption(option);
    onSelectSMSApi(option ? option.value : null);

    if (onAdditionalChange) {
      onAdditionalChange({ onAdditionalChange });
    }
  };

  // Insert New State
  const SMSApiModalOpen = () => {
    setIsSMSApiModalOpen(true);
  };

  const closeSMSApiModal = () => {
    setIsSMSApiModalOpen(false);
  };

  const selectDDSMSApiID = (smsApiID) => {
    handleSMSApiChange({ value: smsApiID });
    getSMSApiName();
    closeSMSApiModal();
  };

  return (
    <>
      <Col
        md={ColSize != null ? ColSize : 4}
        sm={ColSize != null ? ColSize : 4}
        lg={ColSize != null ? ColSize : 4}
      >
        <Form.Group controlId="SMSApi_id">
          <Form.Label>SMSApi Name:</Form.Label>
          <div className="mb-3 d-flex justify-content-between">
            <div className="selectStartIcon input-group-text" id="basic-addon1">
              <i className="fas fa-user"></i>
            </div>
            <Select
              className={`w-100 ${validation ? "borderRed" : ""} ${
                RoleTemplatePermissions?.addPermission
                  ? "RadiusNo"
                  : "RadiusYes"
              }`}
              options={SMSApiOptions.map((option) => ({
                value: option.smsApiID,
                label: option.smsCompanyName,
              }))}
              value={selectedOption}
              onChange={handleSMSApiChange}
              placeholder="Select SMSApi Name"
              required
            />
            {RoleTemplatePermissions &&
              RoleTemplatePermissions.addPermission && (
                <div className="selectEndIcon input-group-text">
                  <i
                    className="fa fa-plus plusIcon"
                    onClick={SMSApiModalOpen}
                  ></i>
                </div>
              )}
          </div>
          {validation && (
            <div className="ValidationErrorMsg">Please select an SMSApi.</div>
          )}
        </Form.Group>
      </Col>

      {/* City Modal */}
      <Modal
        show={isSMSApiModalOpen}
        onHide={closeSMSApiModal}
        size="xl"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton closeVariant={"white"}>
          <Modal.Title id="example-custom-modal-styling-title">
            New SMSApi
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <SMSApiComponent SMSApiID={selectDDSMSApiID}></SMSApiComponent>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
}
