import React, { useState, useEffect } from "react";
import api from "../../Utils/api";
import { Form, Col, InputGroup, Modal } from "react-bootstrap";
import CityComponent from "./CityComponent";
import Select from "react-select";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";

export default function CityDDComponent({
  onSelectCity,
  selectedCity,
  ValidationError,
}) {
  const [cityOptions, setCityOptions] = useState([]);
  const [isCityModalOpen, setIsCityModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [validation, setValidation] = useState(false);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getCityName();
    CheckRoleTemplatePermissions("City")
      .then((obj) => {
        setRoleTemplatePermissions(obj);
      })
      .catch((error) => null);
  }, []);

  useEffect(() => {
    
    if (ValidationError && selectedCity === 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  }, [ValidationError, selectedCity]);

  useEffect(() => {
    // Set initial value for the dropdown during edit time
    if (selectedCity) {
      const selectedOption = cityOptions.find(
        (option) => option.cityID === selectedCity
      );
      setSelectedOption({
        value: selectedOption?.cityID,
        label: selectedOption?.cityName,
      });
      setValidation(false);
    }
  }, [selectedCity, cityOptions]);

  // Fetch all City Name
  const getCityName = () => {
    api
      .get("City/AllCity")
      .then((result) => {
        setCityOptions(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCityChange = (option) => {
    setSelectedOption(option);
    onSelectCity(option ? option.value : null);
  };

  // Insert New State
  const cityModalOpen = () => {
    setIsCityModalOpen(true);
  };

  const closeCityModal = () => {
    setIsCityModalOpen(false);
  };

  const selectDDCityID = (cityID) => {
    handleCityChange({ value: cityID });
    getCityName();
    closeCityModal();
  };

  return (
    <>
      <Col md={4} sm={4} lg={4}>
        <Form.Group controlId="city_id">
          <Form.Label>City Name:</Form.Label>
          <div className="mb-3 d-flex justify-content-between">
            <div className="selectStartIcon input-group-text" id="basic-addon1">
              <i className="fas fa-building"></i>
            </div>
            <Select
             className={`w-100 ${validation ? "borderRed" : ""} ${
              RoleTemplatePermissions && RoleTemplatePermissions.addPermission
                ? "RadiusNo"
                : "RadiusYes"
            }`}
            
              options={cityOptions.map((option) => ({
                value: option.cityID,
                label: option.cityName,
              }))}
              value={selectedOption}
              onChange={handleCityChange}
              placeholder="Select City Name"
              required
            />
             {RoleTemplatePermissions &&
              RoleTemplatePermissions.addPermission && (
            <div className="selectEndIcon input-group-text">
              <i className="fa fa-plus plusIcon" onClick={cityModalOpen}></i>
            </div>)}
          </div>
          {validation && (
            <div className="ValidationErrorMsg">Please select an test City.</div>
          )}
        </Form.Group>
      </Col>

      {/* City Modal */}
      <Modal
        show={isCityModalOpen}
        onHide={closeCityModal}
        size="xl"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton closeVariant={"white"}>
          <Modal.Title id="example-custom-modal-styling-title">
            New City
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <CityComponent cityID={selectDDCityID}></CityComponent>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
}
