import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import api from "../../Utils/api";
import { Card, Form, Button, Col, Row, InputGroup } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import DeveloperDDComponent from "../Developer/DeveloperDDComponent";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";

export default function UserMaster() {
  const [userData, setUserData] = useState([]);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    user_id: 0,
    user_name: "",
    password: "",
    mobile_Number: "",
    email_id: "",
    developer_id: null,
    user_type: "",
  });

  const [options, setOptions] = useState([]);
  const [selectedDeveloper, setSelectedDeveloper] = useState(0);
  const [isDeveloperModalOpen, setIsDeveloperModalOpen] = useState(false);
  const [userOption, setUserOption] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [developerDDValidation, setDeveloperDDValidation] = useState(false);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  const handleDeveloperChange = (event) => {
    setSelectedDeveloper(event.target.value);
    handleInputChange({
      target: { name: "developer_id", value: event.target.value },
    });
  };

  useEffect(() => {
    getUser();
    getDeveloperName();
    getRoleTemplateName();
    CheckRoleTemplatePermissions("UserMaster")
    .then((obj) => {
      setRoleTemplatePermissions(obj);
    })
    .catch((error) => null);
  }, []);

  // Fetch all Users
  const getUser = () => {
    api
      .get("User/AllUser")
      .then((result) => {
        setUserData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Fetch all Developer
  const getDeveloperName = () => {
    return new Promise((resolve, reject) => {
      api
        .get("Developer/AllDeveloper")
        .then((result) => {
          setOptions(result.data.data);
          resolve(result.data.data); // Resolve with the data
        })
        .catch((error) => {
          console.log(error);
          reject(error); // Reject with the error
        });
    });
  };

  // Fetch all Developer
  const getRoleTemplateName = () => {
    return new Promise((resolve, reject) => {
      api
        .get("RoleTemplateDetail/AllRoleTemplate")
        .then((result) => {
          setUserOption(result.data.data);
          resolve(result.data.data); // Resolve with the data
        })
        .catch((error) => {
          console.log(error);
          reject(error); // Reject with the error
        });
    });
  };

  const emptyUser = {
    user_id: 0,
    user_name: "",
    password: "",
    mobile_Number: "",
    email_id: "",
    developer_id: 0,
    user_type: "",
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Insert & Update Data
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const data = {
        userID: formData?.user_id || 0,
        userName: formData.user_name,
        password: formData.password,
        mobileNumber: formData.mobile_Number,
        developerID: formData.developer_id,
        userType: formData.user_type,
        isActive: true,
        emailID: formData.email_id,
      };

      const endpoint =
        formData.user_id === 0 ? "User/InsertUser" : "User/EditUser";

      api[formData.user_id === 0 ? "post" : "patch"](endpoint, data)
        .then((result) => {
          setSelectedDeveloper(0);
          getUser();
          formData?.user_id === 0
            ? toast.success(result.data.message)
            : toast.success(result.data.message);

          setRefreshKey((prevKey) => prevKey + 1);
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred");
        });

      resetForm();
      setValidated(false); // Reset validation state
    }
  };

  // Reset form fields
  const resetForm = () => {
    setFormData(emptyUser);
  };

  // Get data of a single User
  const getSingleUser = (id) => {
    api
      .get(`User/SingleUser?UserID=${id}`)
      .then((result) => {
        const UserData = result.data.data;
        setFormData({
          user_id: UserData.userID,
          user_name: UserData.userName,
          password: UserData.password,
          mobile_Number: UserData.mobileNumber,
          email_id: UserData.emailID,
          developer_id: UserData.developerID,
          user_type: UserData.userType,
          isActive: UserData.isActive,
        });
        setSelectedDeveloper(UserData.developerID);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Delete User
  const deleteUser = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`User/DeleteUser?UserID=${id}`)
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              getUser();
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  };

  // AG Grid Table Column
  const columnDefs = [
    {
      headerName: "User Name",
      field: "userName",
      flex: 1,
      sortable: true,
      filter: true,
      cellStyle: { textAlign: "left" },
      minWidth:200
    },
    {
      headerName: "Developer Name",
      field: "developerName",
      flex: 1,
      sortable: true,
      filter: true,
      cellStyle: { textAlign: "left" },
      minWidth:200
    },
    {
      headerName: "Mobile Number",
      field: "mobileNumber",
      flex: 1,
      sortable: true,
      filter: true,
      cellStyle: { textAlign: "left" },
      minWidth:150
    },
    {
      headerName: "Email ID",
      field: "emailID",
      flex: 1,
      sortable: true,
      filter: true,
      cellStyle: { textAlign: "left" },
      minWidth:250
    },
    {
      headerName: "Active",
      field: "isActive",
      headerClass: "center-text widthTableColum",
      width: 150,
      cellStyle: { textAlign: "center" },
      cellRenderer: ({ value, data }) => {
        if (data.isActive) {
          return (
            <button
              className="btn btn-outline-success mb-2"
              onClick={() =>
                UpdateActiveAndDeActive(data.userID, data.isActive)
              }
            >
              <strong>Active</strong>
            </button>
          );
        } else {
          return (
            <button
              className="btn btn-danger mb-2"
              onClick={() =>
                UpdateActiveAndDeActive(data.userID, data.isActive)
              }
            >
              <strong>De-Active</strong>
            </button>
          );
        }
      },
    },
    ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
    {
      headerName: "Edit",
      width: 70,
      field: "userID",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="create-outline"
          class="iconsFonts editIcon"
          onClick={() => getSingleUser(value)}
        ></ion-icon>
      ),
      pinned:"right"
    },
  ]
  : []),
...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
  ? [
    {
      headerName: "Delete",
      width: 80,
      field: "userID",
      cellStyle: { textAlign: "center" },
      headerClass: "centered-header",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="trash-outline"
          class="iconsFonts deleteIcon"
          onClick={() => deleteUser(value)}
        ></ion-icon>
      ),
      pinned:"right"
    },
  ]
  : []),
];

  const UpdateActiveAndDeActive = (u_ID, active_type) => {
    const Active = !Boolean(active_type);
    api
      .patch(
        `User/UpdateUserActivateOrDeActivate?UserID=${u_ID}&IsActive=${Active}`
      )
      .then((result) => {
        getUser();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Insert New Developer
  const developerModalOpen = () => {
    setIsDeveloperModalOpen(true);
  };

  const closeDeveloperModal = () => {
    setIsDeveloperModalOpen(false);
  };

  return (
    <>
      <ToastContainer />
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
      <Card>
        <Card.Header>
          <span>New User</span>
        </Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
              <Col md={4} sm={2} lg={4}>
                <Form.Group controlId="user_name">
                  <Form.Label>User Name:</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <i class="fas fa-user"></i>
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="user_name"
                      placeholder="Enter User Name"
                      value={formData.user_name}
                      onChange={handleInputChange}
                      required
                    />
                    {validated && !formData.client_name && (
                      <div className="invalid-feedback">
                        Please Enter User Name.
                      </div>
                    )}
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={4} sm={2} lg={4}>
                <Form.Group controlId="password">
                  <Form.Label>Password:</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <i class="fas fa-lock"></i>
                    </InputGroup.Text>
                    <Form.Control
                      type="password"
                      name="password"
                      placeholder="Enter Password"
                      value={formData.password}
                      onChange={handleInputChange}
                      required
                    />
                    {validated && !formData.client_name && (
                      <div className="invalid-feedback">
                        Please Enter Password.
                      </div>
                    )}
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={4} sm={2} lg={4}>
                <Form.Group controlId="mobile_Number">
                  <Form.Label>Mobile No:</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <i class="fas fa-mobile"></i>
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="mobile_Number"
                      placeholder="Enter Mobile Number"
                      value={formData.mobile_Number}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4} sm={2} lg={4}>
                <Form.Group controlId="email_id">
                  <Form.Label>Email ID:</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <i class="fas fa-envelope"></i>
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="email_id"
                      placeholder="Enter Email Address"
                      value={formData.email_id}
                      onChange={handleInputChange}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <DeveloperDDComponent
                onSelectDeveloper={(developerID) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    developer_id: developerID,
                  }));
                }}
                selectedDeveloper={formData.developer_id}
                key={refreshKey}
              />
              <Col md={4} sm={4} lg={4}>
                <Form.Group controlId="user_type">
                  <Form.Label>User Type Name:</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <i className="fas fa-user"></i>
                    </InputGroup.Text>
                    <Form.Select
                      aria-label="Select User Type Name"
                      value={formData.user_type}
                      name="user_type"
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">-- Select User Type Name --</option>
                      {userOption.map((option) => (
                        <option key={option.roleName} value={option.roleName}>
                          {option.roleName}
                        </option>
                      ))}
                    </Form.Select>
                    {validated && !formData.user_type && (
                      <div className="invalid-feedback">
                        Please Select User Type Name.
                      </div>
                    )}
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <div className="card-footer margin_top10">
              <Button type="submit" variant="primary" className="mb-20px">
                {formData?.user_id > 0 ? "Update" : "Save"}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
       )}
      <Card>
        <Card.Header>
          <span>User List</span>
        </Card.Header>
        <Card.Body>
          <AgGridComponent rowData={userData} columnDefs={columnDefs} />
        </Card.Body>
      </Card>

      {/* Developer */}
      <Modal
        show={isDeveloperModalOpen}
        onHide={closeDeveloperModal}
        size="xl"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton closeVariant={"white"}>
          <Modal.Title id="example-custom-modal-styling-title">
            Developer
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            {/* <Developer
              insertRecord={handleInsertDeveloper}
              closeModal={() => setIsDeveloperModalOpen(false)} // Close the modal
              hideAgGrid={agGridHide}
            /> */}
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
}
