import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api, { handleApiError } from "../../Utils/api";
import { Form, Button, Col, Row, InputGroup } from "react-bootstrap";
import AreaDDComponent from "../Area/AreaDDComponent";

const emptyDeveloper = {
  Developer_id: 0,
  Developer_Name: "",

};

export default function DeveloperComponent({
  DeveloperID,
  editedDeveloperData,
  getDeveloperData,
}) {
  const [refreshKey, setRefreshKey] = useState(0);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyDeveloper);
  const DeveloperFormRef = useRef(null);
  const [validationError, setValidationError] = useState(true);

  useEffect(() => {
    
    if (editedDeveloperData) {
      setFormData({
        Developer_id: editedDeveloperData.developerID,
        Developer_Name: editedDeveloperData.developerName,
      });
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }
  }, [editedDeveloperData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const insertOrUpdateDeveloper = (event) => {
    setValidated(true);
    event.preventDefault();

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }

    const data = {
      DeveloperID: formData?.Developer_id || 0,
      DeveloperName: formData.Developer_Name,
    };

    const endpoint =
      formData.Developer_id === 0
        ? "Developer/InsertDeveloper"
        : "Developer/EditDeveloper";
      
    api[formData.Developer_id === 0 ? "post" : "patch"](endpoint, data)
      .then((result) => {
       
        setValidationError(true);
        if (result.status === 200) {
          toast.success(result.data.message);
          if (DeveloperID) {
            DeveloperID(result.data.data);
          }
        } else {
          toast.error(result.data.message);
        }
        setRefreshKey((prevKey) => prevKey + 1);
      })
      .catch(handleApiError);

    resetForm();
    setValidated(false);
  };

  const resetForm = () => {
    setFormData(emptyDeveloper);
  };

  return (
    <>
      <Form
        noValidate
        validated={validated}
        onSubmit={(e) => {
          if (e) {
            e.preventDefault();
          }

          if (DeveloperFormRef.current && e) {
            if (e.target.name === "DeveloperForm") {
              insertOrUpdateDeveloper(e);
            }
          }
        }}
        ref={DeveloperFormRef}
        name="DeveloperForm"
      >
        <Row>
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="Developer_Name">
              <Form.Label>Developer Name:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i class="fa fa-user"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="Developer_Name"
                  placeholder="Enter Developer Name"
                  value={formData.Developer_Name}
                  onChange={handleInputChange}
                  required
                />
                {validated && !formData.Developer_Name && (
                  <div className="invalid-feedback">
                    Please Enter Developer Name.
                  </div>
                )}
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <div className="card-footer margin_top10">
          <Button type="submit" variant="primary" className="mb-20px">
            {formData?.Developer_id > 0 ? "Update" : "Save"}
          </Button>
        </div>
      </Form>
    </>
  );
}
