import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../Utils/api";
import { Form, Button, Col, Row, InputGroup } from "react-bootstrap";
import StateDDComponent from "../State/StateDDComponent";

const emptyCity = {
  city_id: 0,
  city_name: "",
  state_id: 0,
};

export default function CityComponent({ cityID, editCityData, getCityData }) {
  const [refreshKey, setRefreshKey] = useState(0); // Key for refreshing the dropdown
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyCity);
  const cityFormRef = useRef(null);
  const [ddValidationError, setDDValidationError] = useState(false);


  useEffect(() => {}, [refreshKey]);
  useEffect(() => {
    if (editCityData) {
      setFormData({
        city_id: editCityData.cityID,
        city_name: editCityData.cityName,
        state_id: editCityData.stateID,
      });
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }
  }, [editCityData]);

 

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Insert & Update Data
  const insertCity = (event) => {
    
    setValidated(true);
    if (event) {
      event.preventDefault();
    }
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
        setDDValidationError(formData.state_id === 0); // Set ddValidationError based on the condition
      return;
    } else {
      const data = {
        cityID: formData?.city_id || 0,
        cityName: formData.city_name,
        stateID: formData.state_id,
      };

      const endpoint =
        formData.city_id === 0 ? "City/InsertCity" : "City/EditCity";

      api[formData.city_id === 0 ? "post" : "patch"](endpoint, data)
        .then((result) => {
          if (result.status === 200) {
            setDDValidationError(false);
            toast.success(result.data.message);
            if(cityID){
              cityID(result.data.data);
            }
          } else {
            toast.error(result.data.message);
          }
          setRefreshKey(refreshKey + 1);
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred");
        });
      resetForm();
      setValidated(false); // Reset validation state
    }
  };

  // Reset form fields
  const resetForm = () => {
    setFormData(emptyCity);
  };

  return (
    <>
      <Form
        noValidate
        validated={validated}
        onSubmit={(e) => {
          if (e) {
            e.preventDefault();
          }

          if (cityFormRef.current && e) {
            // Check for the formName to call insertCity
            if (e.target.name === "cityForm") {
              insertCity(e);
            }
          }
        }}
        ref={cityFormRef}
        name="cityForm"
      >
        <Row>
          <StateDDComponent
            onSelectState={(stateId) => {
              setFormData((prevData) => ({
                ...prevData,
                state_id: stateId,
              }));
              setDDValidationError(false);
            }}
            ValidationError={ddValidationError}
            selectedState={formData.state_id}
            key={refreshKey}
          />
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="city_name">
              <Form.Label>City Name:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i class="fa fa-city"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="city_name"
                  placeholder="Enter City Name"
                  value={formData.city_name}
                  onChange={handleInputChange}
                  required
                />
                {validated && !formData.city_name && (
                  <div className="invalid-feedback">
                    Please Enter City Name.
                  </div>
                )}
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <div className="card-footer margin_top10">
          <Button type="submit" variant="primary" className="mb-20px">
            {formData?.city_id > 0 ? "Update" : "Save"}
          </Button>
        </div>
      </Form>
    </>
  );
}
