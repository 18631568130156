import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api, { handleApiError } from "../../Utils/api";
import { Form, Button, Col, Row, InputGroup } from "react-bootstrap";


const emptySourceType = {
  SourceType_id: 0,
  SourceType_Name: "",

};

export default function SourceTypeComponent({
  SourceTypeID,
  editedSourceTypeData,
  getSourceTypeData,
}) {
  const [refreshKey, setRefreshKey] = useState(0);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptySourceType);
  const SourceTypeFormRef = useRef(null);
  const [validationError, setValidationError] = useState(true);

  useEffect(() => {
    
    if (editedSourceTypeData) {
      setFormData({
        SourceType_id: editedSourceTypeData.sourceTypeID,
        SourceType_Name: editedSourceTypeData.sourceName,
      });
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }
  }, [editedSourceTypeData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const insertOrUpdateSourceType = (event) => {
    setValidated(true);
    event.preventDefault();

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }

    const data = {
      SourceTypeID: formData?.SourceType_id || 0,
      SourceName: formData.SourceType_Name,
    };

    const endpoint =
      formData.SourceType_id === 0
        ? "SourceType/InsertSourceType"
        : "SourceType/EditSourceType";

    api[formData.SourceType_id === 0 ? "post" : "patch"](endpoint, data)
      .then((result) => {
        setValidationError(true);
        if (result.status === 200) {
          toast.success(result.data.message);
          if (SourceTypeID) {
            SourceTypeID(result.data.data);
          }
        } else {
          toast.error(result.data.message);
        }
        setRefreshKey((prevKey) => prevKey + 1);
      })
      .catch(handleApiError);

    resetForm();
    setValidated(false);
  };

  const resetForm = () => {
    setFormData(emptySourceType);
  };

  return (
    <>
      <Form
        noValidate
        validated={validated}
        onSubmit={(e) => {
          if (e) {
            e.preventDefault();
          }

          if (SourceTypeFormRef.current && e) {
            if (e.target.name === "SourceTypeForm") {
              insertOrUpdateSourceType(e);
            }
          }
        }}
        ref={SourceTypeFormRef}
        name="SourceTypeForm"
      >
        <Row>
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="SourceType_Name">
              <Form.Label>SourceType Name:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i class="fa fa-user"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="SourceType_Name"
                  placeholder="Enter SourceType Name"
                  value={formData.SourceType_Name}
                  onChange={handleInputChange}
                  required
                />
                {validated && !formData.SourceType_Name && (
                  <div className="invalid-feedback">
                    Please Enter SourceType Name.
                  </div>
                )}
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <div className="card-footer margin_top10">
          <Button type="submit" variant="primary" className="mb-20px">
            {formData?.SourceType_id > 0 ? "Update" : "Save"}
          </Button>
        </div>
      </Form>
    </>
  );
}
