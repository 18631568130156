import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import api from "../../Utils/api";
import { Card } from "react-bootstrap";
import UserTypeMasterComponent from "./UserTypeMasterComponent";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";

export default function UserTypeMaster() {
  const [UserTypeMasterData, setUserTypeMasterData] = useState([]);
  const [editUserTypeMasterData, setEditUserTypeMasterData] = useState(null);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    ;
    getUserTypeMaster();
    CheckRoleTemplatePermissions("UserTypeMaster")
      .then((obj) => {
        setRoleTemplatePermissions(obj);
      })
      .catch((error) => null);
  }, []);

  // Fetch all UserTypeMasters
  const getUserTypeMaster = () => {
    api
      .get("UserTypeMaster/AllUserTypeMaster")
      .then((result) => {
        setUserTypeMasterData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Get data of a single UserTypeMaster
  const getSingleUserTypeMaster = (id) => {
    api
      .get(`UserTypeMaster/SingleUserTypeMaster?UserTypeMasterID=${id}`)
      .then((result) => {
        const UserTypeMasterData = result.data.data;
        setEditUserTypeMasterData(UserTypeMasterData);
        console.log(RoleTemplatePermissions);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Delete UserTypeMaster
  const deleteUserTypeMaster = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`UserTypeMaster/DeleteUserTypeMaster?UserTypeMasterID=${id}`)
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              getUserTypeMaster();
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  };

  // AG Grid Table Column
  const columnDefs = [
    {
      headerName: "User RoleName",
      field: "roleName",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Form Name",
      field: "formName",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      flex: 1,
      minWidth: 150,
    },
    ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
            headerName: "Edit",
            width: 70,
            field: "userTypeMasterID",
            cellStyle: { textAlign: "center" },
            headerClass: "center-text widthTableColum",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="create-outline"
                class="iconsFonts editIcon"
                onClick={() => getSingleUserTypeMaster(value)}
              ></ion-icon>
            ),
            pinned: "right",
          },
        ]
      : []),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
      ? [
          {
            headerName: "Delete",
            width: 80,
            field: "userTypeMasterID",
            cellStyle: { textAlign: "center" },
            headerClass: "center-text widthTableColum",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="trash-outline"
                class="iconsFonts deleteIcon"
                onClick={() => deleteUserTypeMaster(value)}
              ></ion-icon>
            ),
            pinned: "right",
          },
        ]
      : []),
  ];
  return (
    <>
      <ToastContainer />
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
        <Card>
          <Card.Header>
            <span>New UserTypeMaster</span>
          </Card.Header>
          <Card.Body>
            <UserTypeMasterComponent
              UserTypeMasterID={getUserTypeMaster}
              editedUserTypeMasterData={editUserTypeMasterData}
              getUserTypeMasterData={getSingleUserTypeMaster}
            />
          </Card.Body>
        </Card>
      )}
      <Card>
        <Card.Header>
          <span>UserTypeMaster List</span>
        </Card.Header>
        <Card.Body>
          <AgGridComponent
            rowData={UserTypeMasterData}
            columnDefs={columnDefs}
          />
        </Card.Body>
      </Card>
    </>
  );
}
