import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api, { handleApiError } from "../../Utils/api";
import { Form, Button, Col, Row, InputGroup } from "react-bootstrap";

const emptyPipeline = {
  Pipeline_id: 0,
  Pipeline_Name: "",
  IsActive: true,
};

export default function PipelineComponent({
  PipelineID,
  editedPipelineData,
  getPipelineData,
}) {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyPipeline);
  const PipelineFormRef = useRef(null);

  useEffect(() => {
    if (editedPipelineData) {
      setFormData({
        Pipeline_id: editedPipelineData.pipelineID,
        Pipeline_Name: editedPipelineData.pipelineName,
        IsActive: editedPipelineData.isActive,
      });
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }
  }, [editedPipelineData]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    // Use the checked property for checkboxes
    const inputValue = type === "checkbox" ? checked : value;

    setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
  };

  const insertOrUpdatePipeline = (event) => {
    setValidated(true);
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }
    const data = {
      PipelineID: formData?.Pipeline_id || 0,
      PipelineName: formData.Pipeline_Name,
      IsActive: formData.IsActive,
    };
    const endpoint =
      formData.Pipeline_id === 0
        ? "Pipeline/InsertPipeline"
        : "Pipeline/EditPipeline";

    api[formData.Pipeline_id === 0 ? "post" : "patch"](endpoint, data)
      .then((result) => {
        if (result.status === 200) {
          toast.success(result.data.message);
          if (PipelineID) {
            PipelineID(result.data.data);
          }
        } else {
          toast.error(result.data.message);
        }
      })
      .catch(handleApiError);
    resetFormPipeline();
    setValidated(false);
  };

  const resetFormPipeline = () => {
    setFormData(emptyPipeline);
  };

  return (
    <>
      <Form
        noValidate
        validated={validated}
        onSubmit={(e) => {
          if (e) {
            e.preventDefault();
          }

          if (PipelineFormRef.current && e) {
            if (e.target.name === "PipelineForm") {
              insertOrUpdatePipeline(e);
            }
          }
        }}
        ref={PipelineFormRef}
        name="PipelineForm"
      >
        <Row>
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="Pipeline_Name">
              <Form.Label>Pipeline Name:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fa fa-star"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="Pipeline_Name"
                  placeholder="Enter Pipeline Name"
                  value={formData.Pipeline_Name}
                  onChange={handleInputChange}
                  required
                />
                {validated && !formData.Pipeline_Name && (
                  <div className="invalid-feedback">
                    Please Enter Pipeline Name.
                  </div>
                )}
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="IsActive">
              <Form.Label></Form.Label>
              <Form.Check
                type="checkbox"
                label="Is Active"
                name="IsActive"
                checked={formData.IsActive}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <div className="card-footer margin_top10">
          <Button type="submit" variant="primary" className="mb-20px">
            {formData?.Pipeline_id > 0 ? "Update" : "Save"}
          </Button>
        </div>
      </Form>
    </>
  );
}
