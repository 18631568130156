import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import api, { handleApiError } from "../../Utils/api";
import { Card } from "react-bootstrap";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import PipelineStageComponent from "./PipelineStageComponent";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";

export default function PipelineStage() {
  const [PipelineStageData, setPipelineStageData] = useState([]);
  const [editPipelineStageData, setEditPipelineStageData] = useState(null);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getPipelineStage();
    CheckRoleTemplatePermissions("PipelineStage")
      .then((obj) => {
        setRoleTemplatePermissions(obj);
      })
      .catch((error) => null);
  }, []);

  const notifySuccess = (message) => toast.success(message);

  const getPipelineStage = () => {
    api
      .get("PipelineStage/AllPipelineStage")
      .then(({ data }) => setPipelineStageData(data.data))
      .catch(handleApiError);
  };

  const getSinglePipelineStage = (id) => {
    ;
    api
      .get(`PipelineStage/SinglePipelineStage?PipelineStageID=${id}`)
      .then(({ data }) => setEditPipelineStageData(data.data))
      .catch(handleApiError);
  };

  const deletePipelineStage = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      ;
      if (result.isConfirmed) {
        api
          .patch(`PipelineStage/DeletePipelineStage?PipelineStageID=${id}`)
          .then(({ status }) => {
            if (status === 200) {
              notifySuccess("Your record has been deleted.");
              getPipelineStage();
            }
          })
          .catch(handleApiError);
      }
    });
  };

  const columnDefs = [
    {
      headerName: "Pipeline Name",
      field: "pipelineName",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      flex: 1,
      minWidth:200
    },
    {
      headerName: "PipelineStage Name",
      field: "pipelineStageName",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      flex: 1,
      minWidth:200
    },
    {
      headerName: "Display Priority",
      field: "displayPriority",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      flex: 1,
      minWidth:150
    },
    {
      headerName: "IsActive",
      field: "isActive",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      flex: 1,
      cellRenderer: (params) => {
        return params.value ? "Active" : "De-Active";
      },
      minWidth:120
    }, ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
      headerName: "Edit",
      width: 70,
      field: "pipelineStageID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="create-outline"
          class="iconsFonts editIcon"
          onClick={() => getSinglePipelineStage(value)}
        ></ion-icon>
      ),
      pinned:"right"
    },
  ]
  : []),
...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
  ? [
      {
      headerName: "Delete",
      width: 80,
      field: "pipelineStageID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="trash-outline"
          class="iconsFonts deleteIcon"
          onClick={() => deletePipelineStage(value)}
        ></ion-icon>
      ),
      pinned:"right"
    },
  ]
: []),
  ];

  return (
    <>
      <ToastContainer />
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
      <Card>
        <Card.Header>
          <span>New PipelineStage</span>
        </Card.Header>
        <Card.Body>
          <PipelineStageComponent
            PipelineStageID={getPipelineStage}
            editedPipelineStageData={editPipelineStageData}
            getPipelineStageData={getSinglePipelineStage}
          />
        </Card.Body>
      </Card>)}
      <Card>
        <Card.Header>
          <span>PipelineStage List</span>
        </Card.Header>
        <Card.Body>
          <AgGridComponent
            rowData={PipelineStageData}
            columnDefs={columnDefs}
          />
        </Card.Body>
      </Card>
    </>
  );
}


