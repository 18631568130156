import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../Utils/api";
import { Form, Button, Col, Row, InputGroup } from "react-bootstrap";
import CityDDComponent from "../City/CityDDComponent";

const emptyArea = {
  area_id: 0,
  area_name: "",
  city_id: 0,
};

export default function AreaComponent({ areaID, editedAreaData, getAreaData }) {
  const [refreshKey, setRefreshKey] = useState(0); // Key for refreshing the dropdown
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyArea);
  const areaFormRef = useRef(null);
  const [ddValidationError, setDDValidationError] = useState(false);

  useEffect(() => {}, [refreshKey]);
  useEffect(() => {
    if (editedAreaData) {
      setFormData({
        area_id: editedAreaData.areaID,
        area_name: editedAreaData.areaName,
        city_id: editedAreaData.cityID,
      });
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }
  }, [editedAreaData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Insert & Update Data
  const insertAreaMaster = (event) => {
    setValidated(true);
    event.preventDefault();

    const form = event.currentTarget;
  

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setDDValidationError(formData.city_id === 0);
      return;
    } else {
      const data = {
        areaID: formData?.area_id || 0,
        areaName: formData.area_name,
        cityID: formData.city_id,
      };
      const endpoint =
        formData.area_id === 0 ? "Area/InsertArea" : "Area/EditArea";

      api[formData.area_id === 0 ? "post" : "patch"](endpoint, data)
        .then((result) => {
          if (result.status === 200) {
            toast.success(result.data.message);
            setDDValidationError(false);
            areaID(result.data.data);
          } else {
            toast.error(result.data.message);
          }
          setRefreshKey(refreshKey + 1);
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred");
        });

      resetForm();
      setValidated(false); // Reset validation state
    }
  };

  // Reset form fields
  const resetForm = () => {
    setFormData(emptyArea);
  };

  return (
    <>
      <Form
        noValidate
        validated={validated}
        // onSubmit={(event) => insertAreaMaster(event, { resetForm })}
        onSubmit={(e) => {
          if (e) {
            e.preventDefault();
          }

          if (areaFormRef.current && e) {
            // Check for the formName to call insertCity
            if (e.target.name === "areaForm") {
              insertAreaMaster(e);
            }
          }
        }}
        ref={areaFormRef}
        name="areaForm"
      >
        <Row>
          <CityDDComponent
            onSelectCity={(cityId) => {
              setFormData((prevData) => ({
                ...prevData,
                city_id: cityId,
              }));
              setDDValidationError(false);
            }}
            ValidationError={ddValidationError}
            selectedCity={formData.city_id} // Pass the state_id
            key={refreshKey} // Key to force re-render
          />
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="area_name">
              <Form.Label>Area Name:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-building"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="area_name"
                  placeholder="Enter Area Name"
                  value={formData.area_name}
                  onChange={handleInputChange}
                  required
                />
                {validated && !formData.client_name && (
                  <div className="invalid-feedback">
                    Please Enter Area Name.
                  </div>
                )}
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <div className="card-footer margin_top10">
          <Button type="submit" variant="primary" className="mb-20px">
            {formData?.area_id > 0 ? "Update" : "Save"}
          </Button>
        </div>
      </Form>
    </>
  );
}
