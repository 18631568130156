import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api, { handleApiError } from "../../Utils/api";
import { Form, Button, Col, Row, InputGroup } from "react-bootstrap";

const emptyCalendarHead = {
  calendarHead_id: 0,
  calendarHead_Name: "",
  isActive: true,
};

export default function CalendarHeadComponent({
  CalendarHeadID,
  editedCalendarHeadData,
  getCalendarHeadData,
}) {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyCalendarHead);
  const CalendarHeadFormRef = useRef(null);

  useEffect(() => {
    if (editedCalendarHeadData) {
      setFormData({
        calendarHead_id: editedCalendarHeadData.calendarHeadID,
        calendarHead_Name: editedCalendarHeadData.calendarHeadName,
        isActive: editedCalendarHeadData.isActive,
      });
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }
  }, [editedCalendarHeadData]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    // Use the checked property for checkboxes
    const inputValue = type === "checkbox" ? checked : value;

    setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
  };

  const insertOrUpdateCalendarHead = (event) => {
    
    setValidated(true);
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }
    const data = {
      CalendarHeadID: formData?.calendarHead_id || 0,
      CalendarHeadName: formData.calendarHead_Name,
      IsActive: formData.isActive,
    };
    const endpoint =
      formData.calendarHead_id === 0
        ? "CalendarHead/InsertCalendarHead"
        : "CalendarHead/EditCalendarHead";

    api[formData.calendarHead_id === 0 ? "post" : "patch"](endpoint, data)
      .then((result) => {
        if (result.status === 200) {
          toast.success(result.data.message);
          if (CalendarHeadID) {
            CalendarHeadID(result.data.data);
          }
        } else {
          toast.error(result.data.message);
        }
      })
      .catch(handleApiError);
    resetFormCalendarHead();
    setValidated(false);
  };

  const resetFormCalendarHead = () => {
    setFormData(emptyCalendarHead);
  };

  return (
    <>
      <Form
        noValidate
        validated={validated}
        onSubmit={(e) => {
          if (e) {
            e.preventDefault();
          }

          if (CalendarHeadFormRef.current && e) {
            if (e.target.name === "CalendarHeadForm") {
              insertOrUpdateCalendarHead(e);
            }
          }
        }}
        ref={CalendarHeadFormRef}
        name="CalendarHeadForm"
      >
        <Row>
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="calendarHead_Name">
              <Form.Label>Calendar Head Name:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fa fa-calendar"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="calendarHead_Name"
                  placeholder="Enter Calendar Head Name"
                  value={formData.calendarHead_Name}
                  onChange={handleInputChange}
                  required
                />
                {validated && !formData.calendarHead_Name && (
                  <div className="invalid-feedback">
                    Please Enter CalendarHead Name.
                  </div>
                )}
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="isActive">
              <Form.Label></Form.Label>
              <Form.Check
                type="checkbox"
                label="Is Active"
                name="isActive"
                checked={formData.isActive}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <div className="card-footer margin_top10">
          <Button type="submit" variant="primary" className="mb-20px">
            {formData?.calendarHead_id > 0 ? "Update" : "Save"}
          </Button>
        </div>
      </Form>
    </>
  );
}
