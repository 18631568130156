import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import api, { handleApiError } from "../../Utils/api";
import { Card } from "react-bootstrap";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import CalendarHeadComponent from "./CalendarHeadComponent";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";

export default function CalendarHead() {
  const [calendarHeadData, setCalendarHeadData] = useState([]);
  const [editCalendarHeadData, setEditCalendarHeadData] = useState(null);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getCalendarHead();
    CheckRoleTemplatePermissions("CalendarHead")
    .then((obj) => {
      setRoleTemplatePermissions(obj);
    })
    .catch((error) => null);
  }, []);

  const notifySuccess = (message) => toast.success(message);

  const getCalendarHead = () => {
    api
      .get("CalendarHead/AllCalendarHead")
      .then(({ data }) => setCalendarHeadData(data.data))
      .catch(handleApiError);
  };

  const getSingleCalendarHead = (id) => {
    api
      .get(`CalendarHead/SingleCalendarHead?CalendarHeadID=${id}`)
      .then(({ data }) => setEditCalendarHeadData(data.data))
      .catch(handleApiError);
  };

  const deleteCalendarHead = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`CalendarHead/DeleteCalendarHead?CalendarHeadID=${id}`)
          .then(({ status }) => {
            if (status === 200) {
              notifySuccess("Your record has been deleted.");
              getCalendarHead();
            }
          })
          .catch(handleApiError);
      }
    });
  };

  const columnDefs = [
    {
      headerName: "Calendar Head Name",
      field: "calendarHeadName",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      flex: 1,
      minWidth:200
    },
    {
      headerName: "IsActive",
      field: "isActive",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      flex: 1,
      cellRenderer: (params) => {
        return params.value ? "Active" : "De-Active";
      },
      minWidth:150
    },
    ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
      headerName: "Edit",
      width: 70,
      field: "calendarHeadID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="create-outline"
          class="iconsFonts editIcon"
          onClick={() => getSingleCalendarHead(value)}
        ></ion-icon>
      ),
      pinned:"right"
    }, ]
    : []),
  ...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
    ? [
        {
      headerName: "Delete",
      width: 80,
      field: "calendarHeadID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="trash-outline"
          class="iconsFonts deleteIcon"
          onClick={() => deleteCalendarHead(value)}
        ></ion-icon>
      ),
      pinned:"right"
    },
  ]
: []),
];


  return (
    <>
      <ToastContainer />
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
      <Card>
        <Card.Header>
          <span>New Calendar Head</span>
        </Card.Header>
        <Card.Body>
          <CalendarHeadComponent
            CalendarHeadID={getCalendarHead}
            editedCalendarHeadData={editCalendarHeadData}
            getCalendarHeadData={getSingleCalendarHead}
          />
        </Card.Body>
      </Card>
      )}
      <Card>
        <Card.Header>
          <span>CalendarHead List</span>
        </Card.Header>
        <Card.Body>
          <AgGridComponent rowData={calendarHeadData} columnDefs={columnDefs} />
        </Card.Body>
      </Card>
    </>
  );
}
