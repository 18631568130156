import React, { useState } from "react";
import ManageClientLeadPopup from "../../Pages/ManageClientLead/ManageClientLeadPopup";
import TypeaheadComponent from "../Common/TypeaheadComponent";

export default function ClientSearch() {

   const [clientID, setClientID] = useState(0);
   const [openClientModel, setOpenClientModel] = useState(false);
   const [refreshKey, setRefreshKey] = useState(0);

  const handleClientSelection = (selectedClient) => {
    
    if (selectedClient ) {
      setClientID(selectedClient);
      setOpenClientModel(true);
    }
  };

  return (
    <>
      <TypeaheadComponent type={"Both"} onClientSelect={handleClientSelection}  onClear={refreshKey}/>

      {openClientModel && (
        <ManageClientLeadPopup
          clientID={clientID}
          onClose={() => {
            setClientID(0);
            setOpenClientModel(false);
            setRefreshKey((prevKey) => prevKey + 1);
           // setClientOptions([]);
          }}
        />
      )}
    </>
  );
}
