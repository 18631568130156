import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../Utils/api";
import { Form, Button, Col, Row, InputGroup } from "react-bootstrap";

export default function StateComponent({
  stateID,
  initialState,
  editStateData,
}) {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    state_id: 0,
    state_name: "",
  });

  useEffect(() => {
    if (initialState) {
      setFormData({ ...initialState });
    } else if (editStateData) {
      setFormData({
        state_id: editStateData.stateID,
        state_name: editStateData.stateName,
      });
    }
  }, [initialState, editStateData]);

  const emptyState = {
    state_id: 0,
    state_name: "",
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Insert & Update Data
  const insertState = (event) => {
    
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const data = {
        stateID: formData?.state_id || 0,
        stateName: formData.state_name,
      };

      const endpoint =
        formData.state_id === 0 ? "State/InsertState" : "State/EditState";

      api[formData.state_id === 0 ? "post" : "patch"](endpoint, data)
        .then((result) => {
          // Reset validation state
          if (result.status === 200) {
            toast.success(result.data.message);
            stateID(result.data.data);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred");
        });
      resetForm();
      setValidated(false);
    }
  };

  // Reset form fields
  const resetForm = () => {
    setFormData(emptyState);
  };

  return (
    <>
      <Form
        noValidate
        validated={validated}
        onSubmit={(event) => insertState(event)}
        
      >
        <Row>
          <Col md={4} sm={2} lg={4}>
            <Form.Group controlId="state_name">
              <Form.Label>State Name:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-building"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="state_name"
                  placeholder="Enter State Name"
                  value={formData.state_name}
                  onChange={handleInputChange}
                  required
                />
                {validated && !formData.state_name && (
                  <div className="invalid-feedback">
                    Please Enter State Name.
                  </div>
                )}
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <div className="card-footer margin_top10">
          <Button type="submit" variant="primary" className="mb-20px">
            {formData?.state_id > 0 ? "Update" : "Save"}
          </Button>
        </div>
      </Form>
    </>
  );
}
  