import React, { useState, useEffect } from "react";
import api from "../../Utils/api";
import { Form, Col, InputGroup, Modal } from "react-bootstrap";
import StateComponent from "./StateComponent";
import Select from "react-select";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";

export default function StateDDComponent({
  onSelectState,
  selectedState,
  ValidationError,
}) {
  const [stateOptions, setStateOptions] = useState([]);
  const [isStateModalOpen, setIsStateModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [validation, setValidation] = useState(false);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getStateName();
    CheckRoleTemplatePermissions("State")
      .then((obj) => {
        setRoleTemplatePermissions(obj);
      })
      .catch((error) => null);
  }, []);

  useEffect(() => {
    if (ValidationError && selectedState === 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  }, [ValidationError, selectedState]);

  useEffect(() => {
    // Set initial value for the dropdown during edit time
    if (selectedState) {
      const selectedOption = stateOptions.find(
        (option) => option.stateID === selectedState
      );
      setSelectedOption({
        value: selectedOption?.stateID,
        label: selectedOption?.stateName,
      });
      setValidation(false);
    }
  }, [selectedState, stateOptions]);

  // Fetch all State Name
  const getStateName = () => {
    api
      .get("State/AllState")
      .then((result) => {
        setStateOptions(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleStateChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onSelectState(selectedOption ? selectedOption.value : null);
  };

  // Insert New State
  const stateModalOpen = () => {
    setIsStateModalOpen(true);
  };

  const closeStateModal = () => {
    setIsStateModalOpen(false);
  };

  const selectDDStateID = (stateID) => {
    handleStateChange({ value: stateID });
    getStateName();
    closeStateModal();
  };

  return (
    <>
      <Col md={4} sm={4} lg={4}>
        <Form.Group controlId="state_id">
          <Form.Label>State Name:</Form.Label>
          <div className="mb-3 d-flex justify-content-between">
            <div className="selectStartIcon input-group-text" id="basic-addon1">
              <i className="fas fa-building"></i>
            </div>
            <Select
              className={`w-100 ${validation ? "borderRed" : ""} ${
                RoleTemplatePermissions?.addPermission
                  ? "RadiusNo"
                  : "RadiusYes"
              }`}
              options={stateOptions.map((option) => ({
                value: option.stateID,
                label: option.stateName,
              }))}
              value={selectedOption}
              onChange={handleStateChange}
              placeholder="Select State Name"
              required
            />
            {RoleTemplatePermissions &&
              RoleTemplatePermissions.addPermission && (
            <div className="selectEndIcon input-group-text">
              <i className="fa fa-plus plusIcon" onClick={stateModalOpen}></i>
            </div>)}
          </div>
          {validation && (
            <div className="ValidationErrorMsg">Please select an State.</div>
          )}
        </Form.Group>
      </Col>

      {/* State Modal */}
      <Modal
        show={isStateModalOpen}
        onHide={closeStateModal}
        size="xl"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton closeVariant={"white"}>
          <Modal.Title id="example-custom-modal-styling-title">
            New State
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <StateComponent stateID={selectDDStateID}></StateComponent>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
}
