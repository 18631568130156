import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import api, { handleApiError } from "../../Utils/api";
import { Card } from "react-bootstrap";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import SMSApiComponent from "./SMSApiComponent";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";

export default function SMSApi() {
  const [SMSApiData, setSMSApiData] = useState([]);
  const [editSMSApiData, setEditSMSApiData] = useState(null);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getSMSApi();
    CheckRoleTemplatePermissions("SMSApi")
      .then((obj) => {
        setRoleTemplatePermissions(obj);
      })
      .catch((error) => null);
  }, []);

  const notifySuccess = (message) => toast.success(message);

  const getSMSApi = () => {
  
    api
      .get(`SMSApi/AllSMSApi`)
      .then(({ data }) => setSMSApiData(data.data))
      .catch(handleApiError);
  };

  const getSingleSMSApi = (id) => {
    api
      .get(`SMSApi/SingleSMSApi?SMSApiID=${id}`)
      .then(({ data }) => setEditSMSApiData(data.data))
      .catch(handleApiError);
  };

  const deleteSMSApi = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`SMSApi/DeleteSMSApi?SMSApiID=${id}`)
          .then(({ status }) => {
            if (status === 200) {
              notifySuccess("Your record has been deleted.");
              getSMSApi();
            }
          })
          .catch(handleApiError);
      }
    });
  };


  const columnDefs = [
    {
      headerName: "Company Name",
      field: "smsCompanyName",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      minWidth: 100,
      flex:1
    },
    {
      headerName: "SMS Url Detail",
      field: "smsUrl",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      minWidth: 800,
      flex:1
    },
    {
      headerName: "Balance",
      field: "balance",
      sortable: true,
      filter: true,
      headerClass: "widthTableColum",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { textAlign: "left" },
      minWidth: 100,
      flex:1,
      cellRenderer: ({ value }) => {
        return value == null ? 0 : value;
      }
      
    }, ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
      headerName: "Edit",
      width: 70,
      field: "smsApiID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="create-outline"
          class="iconsFonts editIcon"
          onClick={() => getSingleSMSApi(value)}
        ></ion-icon>
      ),
      pinned: 'right',
    },
  ]
  : []),
...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
  ? [
      {
      headerName: "Delete",
      width: 80,
      field: "smsApiID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="trash-outline"
          class="iconsFonts deleteIcon"
          onClick={() => deleteSMSApi(value)}
        ></ion-icon>
      ),
      pinned: 'right',
    },
  ]
: []),
  ];

  return (
    <>
      <ToastContainer />
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
      <Card>
        <Card.Header>
          <span>New SMSApi</span>
        </Card.Header>
        <Card.Body>
          <SMSApiComponent
            SMSApiID={getSMSApi}
            editedSMSApiData={editSMSApiData}
            getSMSApiData={getSingleSMSApi}
          />
        </Card.Body>
      </Card>)}
      <Card>
        <Card.Body>
          <AgGridComponent rowData={SMSApiData} columnDefs={columnDefs} />
        </Card.Body>
      </Card >
    </>
  )
}
