
import React from 'react';
import { Modal } from 'react-bootstrap';

const ModelPopUpComponent = ({ showModal, onHide, title, data }) => {
    return (
        <Modal
            show={showModal}
            onHide={onHide}
            size="xl"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton closeVariant={"white"}>
                <Modal.Title id="example-custom-modal-styling-title">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='table-responsive'>
                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>Index</th>
                                {data && data.length > 0 && Object.keys(data[0]).map((key) => (
                                    <th key={key}>{key}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    {Object.values(item).map((value, index) => (
                                        <td key={index}>{value}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModelPopUpComponent;

