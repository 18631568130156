import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import api,{handleApiError} from "../../Utils/api";
import { Card } from "react-bootstrap";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import PipelineComponent from "./PipelineComponent";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";

export default function Pipeline() {
  const [pipelineData, setPipelineData] = useState([]);
  const [editPipelineData, setEditPipelineData] = useState(null);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getPipeline();
    CheckRoleTemplatePermissions("Pipeline")
      .then((obj) => {
        setRoleTemplatePermissions(obj);
      })
      .catch((error) => null);
  }, []);

  const notifySuccess = (message) => toast.success(message);

  const getPipeline = () => {
    api
      .get("Pipeline/AllPipeline")
      .then(({ data }) => setPipelineData(data.data))
      .catch(handleApiError);
  };

  const getSinglePipeline = (id) => {
    api
      .get(`Pipeline/SinglePipeline?PipelineID=${id}`)
      .then(({ data }) => setEditPipelineData(data.data))
      .catch(handleApiError);
  };

  const deletePipeline = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`Pipeline/DeletePipeline?PipelineID=${id}`)
          .then(({ status }) => {
            if (status === 200) {
              notifySuccess("Your record has been deleted.");
              getPipeline();
            }
          })
          .catch(handleApiError);
      }
    });
  };

  const columnDefs = [
    {
      headerName: "Pipeline Name",
      field: "pipelineName",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      flex: 1,
      minWidth:200
    },
    {
      headerName: "IsActive",
      field: "isActive",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      flex: 1,
      cellRenderer: (params) => {
        return params.value ? "Active" : "De-Active";
      },
      minWidth:120
    }, ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
      headerName: "Edit",
      width: 70,
      field: "pipelineID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="create-outline"
          class="iconsFonts editIcon"
          onClick={() => getSinglePipeline(value)}
        ></ion-icon>
      ),
      pinned:"right"
    },
  ]
  : []),
...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
  ? [
      {
      headerName: "Delete",
      width: 80,
      field: "pipelineID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="trash-outline"
          class="iconsFonts deleteIcon"
          onClick={() => deletePipeline(value)}
        ></ion-icon>
      ),
      pinned:"right"
    },
  ]
: [])
  ];

  return (
    <>
      <ToastContainer />
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
      <Card>
        <Card.Header>
          <span>New Pipeline</span>
        </Card.Header>
        <Card.Body>
          <PipelineComponent
            PipelineID={getPipeline}
            editedPipelineData={editPipelineData}
            getPipelineData={getSinglePipeline}
          />
        </Card.Body>
      </Card>)}
      <Card>
        <Card.Header>
          <span>Pipeline List</span>
        </Card.Header>
        <Card.Body>
          <AgGridComponent rowData={pipelineData} columnDefs={columnDefs} />
        </Card.Body>
      </Card>
    </>
  );
}
