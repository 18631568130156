import React, { useState, useEffect } from "react";
import api, { handleApiError } from "../../Utils/api";
import { Form, Col, InputGroup, Modal } from "react-bootstrap";
import PipelineComponent from "./PipelineComponent";
import Select from "react-select";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";

export default function PipelineDDComponent({
  onSelectPipeline,
  selectedPipeline,
  onAdditionalChange,
  ValidationError,
}) {
  const [PipelineOptions, setPipelineOptions] = useState([]);
  const [isPipelineModalOpen, setIsPipelineModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [validation, setValidation] = useState(false);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getPipelineName();
    CheckRoleTemplatePermissions("Pipeline")
      .then((obj) => {
        setRoleTemplatePermissions(obj);
      })
      .catch((error) => null);
  }, []);

  useEffect(() => {
    if (ValidationError && selectedPipeline === 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  }, [ValidationError, selectedPipeline]);

  useEffect(() => {
    // Set initial value for the dropdown during edit time
    if (selectedPipeline) {
      const selectedOption = PipelineOptions.find(
        (option) => option.pipelineID === selectedPipeline
      );
      setSelectedOption({
        value: selectedOption?.pipelineID, // Add a check for undefined
        label: selectedOption?.pipelineName, // Add a check for undefined
      });
      setValidation(false);
    }
  }, [selectedPipeline, PipelineOptions]);

  // Fetch all City Name
  const getPipelineName = () => {
    api
      .get("Pipeline/AllPipeline")
      .then(({ data }) => setPipelineOptions(data.data))
      .catch(handleApiError);
  };

  const handlePipelineChange = (option) => {
    setSelectedOption(option);
    onSelectPipeline(option ? option.value : null);

    if (onAdditionalChange) {
      onAdditionalChange({ onAdditionalChange });
    }
  };

  // Insert New State
  const PipelineModalOpen = () => {
    setIsPipelineModalOpen(true);
  };

  const closePipelineModal = () => {
    setIsPipelineModalOpen(false);
  };

  const selectDDPipelineID = (pipelineID) => {
    handlePipelineChange({ value: pipelineID });
    getPipelineName();
    closePipelineModal();
  };

  return (
    <>
      <Col md={4} sm={4} lg={4}>
        <Form.Group controlId="city_id">
          <Form.Label>Pipeline Name:</Form.Label>
          <div className="mb-3 d-flex justify-content-between">
            <div className="selectStartIcon input-group-text" id="basic-addon1">
              <i className="fas fa-star"></i>
            </div>
            <Select
              className={`w-100 ${validation ? "borderRed" : ""} ${
                RoleTemplatePermissions?.addPermission
                  ? "RadiusNo"
                  : "RadiusYes"
              }`}
              options={PipelineOptions.map((option) => ({
                value: option.pipelineID,
                label: option.pipelineName,
              }))}
              value={selectedOption}
              onChange={handlePipelineChange}
              placeholder="Select Pipeline Name"
              required
            />
            {RoleTemplatePermissions &&
              RoleTemplatePermissions.addPermission && (
                <div className="selectEndIcon input-group-text">
                  <i
                    className="fa fa-plus plusIcon"
                    onClick={PipelineModalOpen}
                  ></i>
                </div>
              )}
          </div>
          {validation && (
            <div className="ValidationErrorMsg">Please select an Pipeline.</div>
          )}
        </Form.Group>
      </Col>

      {/* City Modal */}
      <Modal
        show={isPipelineModalOpen}
        onHide={closePipelineModal}
        size="xl"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton closeVariant={"white"}>
          <Modal.Title id="example-custom-modal-styling-title">
            New Pipeline
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <PipelineComponent
              pipelineID={selectDDPipelineID}
            ></PipelineComponent>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
}
