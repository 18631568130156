import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import api, { handleApiError } from "../../Utils/api";
import { Card } from "react-bootstrap";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import ChargeComponent from "./ChargeComponent";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";

export default function Charge() {
  const [chargeData, setChargeData] = useState([]);
  const [editChargeData, setEditChargeData] = useState(null);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getCharge();
    CheckRoleTemplatePermissions("Charge")
    .then((obj) => {
      setRoleTemplatePermissions(obj);
    })
    .catch((error) => null);
  }, []);

  const notifySuccess = (message) => toast.success(message);

  const getCharge = () => {
    api
      .get("Charge/AllCharge")
      .then(({ data }) => setChargeData(data.data))
      .catch(handleApiError);
  };

  const getSingleCharge = (id) => {
    api
      .get(`Charge/SingleCharge?ChargeID=${id}`)
      .then(({ data }) => setEditChargeData(data.data))
      .catch(handleApiError);
  };

  const deleteCharge = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`Charge/DeleteCharge?ChargeID=${id}`)
          .then(({ status }) => {
            if (status === 200) {
              notifySuccess("Your record has been deleted.");
              getCharge();
            }
          })
          .catch(handleApiError);
      }
    });
  };

  const columnDefs = [
    {
      headerName: "Charge  Name",
      field: "chargeName",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      flex: 1,
      minWidth: 150, 
    },
    {
      headerName: "Rate",
      field: "rate",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      flex: 1,
      minWidth: 150, 
    },
    ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
      headerName: "Edit",
      width: 70,
      field: "chargeID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="create-outline"
          class="iconsFonts editIcon"
          onClick={() => getSingleCharge(value)}
        ></ion-icon>
      ),
      pinned: 'right'
    }, ]
    : []),
  ...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
    ? [
        {
      headerName: "Delete",
      width: 80,
      field: "chargeID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <ion-icon
          name="trash-outline"
          class="iconsFonts deleteIcon"
          onClick={() => deleteCharge(value)}
        ></ion-icon>
      ),
      pinned: 'right'
    },
  ]
: []),
];


  return (
    <>
      <ToastContainer />
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
      <Card>
        <Card.Header>
          <span>New Charge</span>
        </Card.Header>
        <Card.Body>
          <ChargeComponent
            ChargeID={getCharge}
            editedChargeData={editChargeData}
            getChargeData={getSingleCharge}
          />
        </Card.Body>
      </Card>
      )}
      <Card>
        <Card.Header>
          <span>Charge List</span>
        </Card.Header>
        <Card.Body>
          <AgGridComponent rowData={chargeData} columnDefs={columnDefs} />
        </Card.Body>
      </Card>
    </>
  );
}
