import React from "react";
import logo from "../../assets/logo.png";
import { convertDateFormatWithOutTime } from "../../Utils/commonFunction";

const PrintInvoiceComponent = ({ invoiceData }) => {
  const borderWithBottomNone = {
    border: "1px solid black",
    borderBottom: "none",
  };

  const borderWithTopNone = {
    border: "1px solid black",
    borderTop: "none",
  };

  const TableBottomMargin = {
    marginBottom: "0px",
  };

  const NormalFontSize = {
    fontSize: "12px",
  };

  
  const convertToWords = (number) => {
    const units = [
        "Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"
    ];
    const teens = [
        "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"
    ];
    const tens = [
        "", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"
    ];
    const thousands = ["", "Thousand", "Million", "Billion"];

    const convertChunk = (chunk) => {
        let result = "";
        const hundred = Math.floor(chunk / 100);
        const remainder = chunk % 100;

        if (hundred > 0) {
            result += units[hundred] + " Hundred ";
        }

        if (remainder > 0) {
            if (remainder < 10) {
                result += units[remainder];
            } else if (remainder < 20) {
                result += teens[remainder - 10];
            } else {
                const tensDigit = Math.floor(remainder / 10);
                const onesDigit = remainder % 10;
                result += tens[tensDigit] + (onesDigit > 0 ? " " + units[onesDigit] : "");
            }
        }

        return result.trim();
    };

    const recursiveConvert = (num, index) => {
        if (num === 0) {
            return "";
        }

        const chunk = num % 1000;
        const remainder = Math.floor(num / 1000);

        const chunkWords = convertChunk(chunk);
        const place = thousands[index];

        return recursiveConvert(remainder, index + 1) + (chunkWords ? chunkWords + " " + place + " " : "");
    };

    if (number === 0) {
        return "Zero";
    }

    return recursiveConvert(number, 0).trim();
};


  return (
    <>
      <div className="">
        <table className="table" style={TableBottomMargin}>
          <tbody>
            {/* ======first col code ==================================================================================*/}
            <tr>
              <td
                rowSpan={4}
                style={{
                  width: "200px",
                  padding: "10px",
                  ...borderWithBottomNone,
                }}
                className=" align-middle "
              >
                <img src={logo} alt="Company Logo" style={{ width: "180px" }} />
              </td>
              <th
                colSpan={2}
                className="text-center "
                style={{ fontSize: "26px", ...borderWithBottomNone }}
              >
                <strong>TAPTI TECHNOLOGY</strong>
              </th>
            </tr>
            {/* ======company Information code ==================================================================================*/}
            <tr>
              <td
                style={{
                  width: "70%",
                  ...borderWithBottomNone,
                  ...NormalFontSize,
                }}
              >
                <div>
                  <strong>
                    <i className="fa fa-map-marker"></i>
                    &nbsp;Address:&nbsp;
                  </strong>
                  B-24, Paras Society, Katargam, Surat-395004
                </div>
                <div>
                  <strong>
                    <i className="fa fa-phone"></i>&nbsp;MO.No:&nbsp;
                  </strong>
                  8000008862
                </div>
                <div>
                  <strong>
                    <i className="fa fa-envelope"></i>&nbsp;email:&nbsp;
                  </strong>
                  info@taptitechnology.com
                </div>
                <div>
                  <strong>
                    <i className="fa fa-globe font16px"></i>&nbsp;Website:&nbsp;
                  </strong>
                  www.taptitechnology.com
                </div>
              </td>
              <td
                style={{
                  width: "250px",
                  ...borderWithBottomNone,
                  ...NormalFontSize,
                }}
              >
                <div>
                  <strong>&nbsp;PAN:&nbsp;</strong>AAOFT1428K
                </div>
                <div>
                  <strong>&nbsp;UAN:&nbsp;</strong>GJ22D0235811
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        {/* ======Second Table code ==================================================================================*/}

        <table className="table" style={TableBottomMargin}>
          <tr>
            <th
              colspan="3"
              style={borderWithBottomNone}
              className="text-center"
            >
              <strong style={{ fontSize: "18px" }}>
                <i class="fa fa-file-text-o font16px"></i>&nbsp;INVOICE
              </strong>
            </th>
          </tr>

          {/* ======Client Information code ==================================================================================*/}
          <tr>
            <td
              style={{
                width: "50%",
                ...borderWithBottomNone,
                ...NormalFontSize,
              }}
            >
              <div className="text-center my-2">
                <strong style={{ fontSize: "14px" }}>
                  <i class="fa fa-user"></i>&nbsp;Client Information
                </strong>
              </div>
              <div>
                <strong>&nbsp;ClientName:&nbsp;</strong>
                {invoiceData.clientName}&nbsp;&nbsp;[
                {invoiceData.clientMobileNumber}]
              </div>
              <div>
                <strong>&nbsp;Address:&nbsp;</strong>
               {invoiceData.address}
              </div>
            </td>
            {/* ======Invoice Information code ==================================================================================*/}

            <td
              style={{
                width: "50%",
                ...borderWithBottomNone,
                ...NormalFontSize,
              }}
            >
              <div className="text-center my-2">
                <strong style={{ fontSize: "14px" }}>
                  <i class="fa fa-calendar"></i>&nbsp;Invoice Information
                </strong>
              </div>
              <div>
                <strong>&nbsp;Invoice No:&nbsp;</strong>
                {invoiceData.invoiceNo}
              </div>
              <div>
                <strong>&nbsp;Invoice Date:&nbsp;</strong>
                {convertDateFormatWithOutTime(invoiceData.invoiceDate)}
              </div>
            </td>
          </tr>
          {/* ======charge table code ==================================================================================*/}
        </table>
        <table
          className="table table-bordered border-dark"
          style={TableBottomMargin}
        >
          <thead>
            <tr>
              <th style={{ width: "50px" }} className="text-center">
                <strong>Sr.No.</strong>
              </th>
              <th
                colSpan="4"
                style={{ width: "400px", textAlign: "center" }}
                className="text-center"
              >
                <strong>
                  <i className="fa fa-list-alt"></i>&nbsp;Product
                </strong>
              </th>
              <th style={{ textAlign: "center" }} className="text-center">
                <strong>Qty</strong>
              </th>
              <th style={{ textAlign: "center" }} className="text-center">
                <strong>
                  <i className=""></i>&nbsp;Rate
                </strong>
              </th>
              <th
                style={{ width: "99px", textAlign: "center" }}
                className="text-center"
              >
                <strong>
                  <i className=""></i>&nbsp;Amount
                </strong>
              </th>
            </tr>
          </thead>
          <tbody>
            {invoiceData.listInvoiceDetailDataModel.map((item, index) => (
              <tr key={index}>
                <td style={{ textAlign: "center" }} className="text-center">
                  {index + 1}
                </td>
                <td
                  colSpan="4"
                  style={{ textAlign: "left" }}
                  className="text-left"
                >
                  <span>{item.chargeName}</span>
                  <br />
                  {item.remarks && (
                    <span>
                      <i>Note: {item.remarks}</i>
                    </span>
                  )}
                </td>
                <td style={{ textAlign: "center" }} className="text-center">
                  <span>{item.qty}</span>
                </td>
                <td style={{ textAlign: "right" }} className="text-end">
                  <span>
                    <i className="fa "></i>
                    {item.rate}
                  </span>
                </td>
                <td style={{ textAlign: "right" }} className="text-end">
                  <span>
                    <i className="fa "></i>
                    {item.amount}
                  </span>
                </td>
              </tr>
            ))}
            <tr style={{ height: "200px" }}>
              <td style={{ textAlign: "center" }} className="text-center"></td>
              <td
                colSpan="4"
                style={{ textAlign: "left" }}
                className="text-left"
              ></td>
              <td style={{ textAlign: "center" }} className="text-center"></td>
              <td style={{ textAlign: "right" }} className="text-end"></td>
              <td style={{ textAlign: "right" }} className="text-end"></td>
            </tr>
          </tbody>
        </table>
        {/* ======Amount In Word div code ==================================================================================*/}
        <table className="table" style={TableBottomMargin}>
          <tr>
            <td
              style={{ width: "50%", ...borderWithTopNone, ...NormalFontSize }}
            >
              <div className=" my-1">
                <strong>Amount In Word: &nbsp;&nbsp;</strong>
                {convertToWords(invoiceData.netAmount)} Only
              </div>
              <div className=" my-1">
                <strong>Paid Amount: &nbsp; &nbsp;</strong>
                {invoiceData.totalReceivedAmount}
              </div>
              <div className=" my-1">
                <strong>Due Amount: &nbsp;&nbsp;</strong>
                {invoiceData.dueAmount}
              </div>
            </td>
            {/* ======Amount div code ==================================================================================*/}

            <td
              style={{ width: "50%", ...borderWithTopNone, ...NormalFontSize }}
            >
              <div className="text-end my-1">
                <strong>Gross Amount: &nbsp;&nbsp;</strong>
                {invoiceData.grossAmount}
              </div>
              <div className="text-end my-1">
                <strong>Disc. Amount: &nbsp; &nbsp;</strong>
                {invoiceData.discAmount}
              </div>
              <div className="text-end my-1">
                <strong>Net Amount: &nbsp;</strong>
                {invoiceData.netAmount}
              </div>
              <div className="text-start my-1">
                <strong>Remarks: &nbsp;</strong>
                {invoiceData.remarks}
              </div>
            </td>
          </tr>
          {/* ======Bank Details div code ==================================================================================*/}

          <tr>
            <td colSpan={3} style={{ ...borderWithTopNone, ...NormalFontSize }}>
              <div>
                <strong>
                  <u> Bank Details. </u>
                </strong>
                <br />
              </div>
              <strong>NAME: &nbsp; &nbsp;</strong> TAPTI TECHNOLOGY <br />
              <strong>BANK NAME: &nbsp;&nbsp;</strong> IDBI BANK <br />
              <strong>
                ACCOUNT NUMBER: &nbsp;&nbsp;
              </strong> 1850102000023001 <br />
              <strong>IFSC CODE: &nbsp; &nbsp;</strong> IBKL0001850 <br />
              <strong>BRANCH NAME: &nbsp;&nbsp;</strong>VED ROAD
            </td>
          </tr>
          {/* ======Terms & Conditions. div code ==================================================================================*/}

          <tr>
            <td style={{ ...borderWithTopNone, ...NormalFontSize }} colSpan={3}>
              <div>
                <strong>
                  <u> Terms & Conditions.</u>
                </strong>
              </div>
              1. Payment should be made within 7 days of receiving this invoice.
              <br />
              2. No responsibility will be accepted by TAPTI Technology for
              damages or losses incurred by the client.
              <br />
              3. TAPTI Technology does not accept responsibility for errors,
              data losses, data damages, or additional costs related to
              third-party products that are necessary for completing the quoted
              application.
              <br />
              <strong>E & O.E :</strong>All disputes are subjected to Surat
              jurisdiction only.
            </td>
          </tr>
          {/* ======Last line  code ==================================================================================*/}

          <tr>
            <td style={borderWithTopNone} colSpan={3}>
              <div style={{ fontSize: "14px" }}>
                <strong>
                  This is a computer-generated Invoice. No signature is required
                </strong>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </>
  );
};

export default PrintInvoiceComponent;
