import React, { useState, useEffect } from "react";
import api, { handleApiError } from "../../Utils/api";
import { Form, Col, InputGroup, Modal } from "react-bootstrap";
import CalendarHeadComponent from "./CalendarHeadComponent";
import Select from "react-select";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";

export default function CalendarHeadDDComponent({
  onSelectCalendarHead,
  selectedCalendarHead,
  onAdditionalChange,
  ValidationError
}) {
  const [calendarHeadOptions, setCalendarHeadOptions] = useState([]);
  const [isCalendarHeadModalOpen, setIsCalendarHeadModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [validation, setValidation] = useState(false);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getCalendarHeadName();
    CheckRoleTemplatePermissions("CalendarHead")
    .then((obj) => {
      setRoleTemplatePermissions(obj);
    })
    .catch((error) => null);
  }, []);

  useEffect(() => {
    if (ValidationError && selectedCalendarHead === 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  }, [ValidationError, selectedCalendarHead]);

  useEffect(() => {
    // Set initial value for the dropdown during edit time
    if (selectedCalendarHead) {
      const selectedOption = calendarHeadOptions.find(
        (option) => option.calendarHeadID === selectedCalendarHead
      );
      setSelectedOption({
        value: selectedOption?.calendarHeadID, // Add a check for undefined
        label: selectedOption?.calendarHeadName, // Add a check for undefined
      });
    }
  }, [selectedCalendarHead, calendarHeadOptions]);

  // Fetch all City Name
  const getCalendarHeadName = () => {
    api
      .get("CalendarHead/AllCalendarHead")
      .then(({ data }) => setCalendarHeadOptions(data.data))
      .catch(handleApiError);
  };

  const handleCalendarHeadChange = (option) => {
    setSelectedOption(option);
    onSelectCalendarHead(option ? option.value : null);

    if (onAdditionalChange) {
      onAdditionalChange({ onAdditionalChange });
    }
  };

  // Insert New State
  const CalendarHeadModalOpen = () => {
    setIsCalendarHeadModalOpen(true);
  };

  const closeCalendarHeadModal = () => {
    setIsCalendarHeadModalOpen(false);
  };

  const selectDDCalendarHeadID = (CalendarHeadID) => {
    handleCalendarHeadChange({ value: CalendarHeadID });
    getCalendarHeadName();
    closeCalendarHeadModal();
  };

  return (
    <>
      <Col md={4} sm={4} lg={4}>
        <Form.Group controlId="city_id">
          <Form.Label>Calendar Head:</Form.Label>
          <div className="mb-3 d-flex justify-content-between">
            <div className="selectStartIcon input-group-text" id="basic-addon1">
              <i className="fas fa-calendar"></i>
            </div>
            <Select
              Select
              className={`w-100 ${validation && 'borderRed'} ${RoleTemplatePermissions && RoleTemplatePermissions.addPermission ? 'RadiusNo' : 'RadiusYes'}`}

              options={calendarHeadOptions.map((option) => ({
                value: option.calendarHeadID,
                label: option.calendarHeadName,
              }))}
              value={selectedOption}
              onChange={handleCalendarHeadChange}
              placeholder="Select Calendar Head"
            />
             {RoleTemplatePermissions &&
              RoleTemplatePermissions.addPermission && (
            <div className="selectEndIcon input-group-text" >
              <i
                className="fa fa-plus plusIcon"
                onClick={CalendarHeadModalOpen}
              ></i>
            </div>)}
          </div>
          {validation && (
            <div className="ValidationErrorMsg">Please select an CalenderHade.</div>
          )}
        </Form.Group>
      </Col>

      {/* City Modal */}
      <Modal
        show={isCalendarHeadModalOpen}
        onHide={closeCalendarHeadModal}
        size="xl"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton closeVariant={"white"}>
          <Modal.Title id="example-custom-modal-styling-title">
            New Calendar Head
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <CalendarHeadComponent
              CalendarHeadID={selectDDCalendarHeadID}
            ></CalendarHeadComponent>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
}
