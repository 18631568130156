import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import api from "../../Utils/api";
import { Card } from "react-bootstrap";
import ExpenseVoucherComponent from "./ExpenseVoucherComponent";
import moment from "moment";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";

export default function ExpenseVourcher() {
  const [expenseVoucherData, setexpenseVoucherData] = useState([]);
  const [editexpenseVoucherData, setEditexpenseVoucherData] = useState(null);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    getExpenseVoucher();
    CheckRoleTemplatePermissions("ExpenseVourcher")
      .then((obj) => {
        setRoleTemplatePermissions(obj);
      })
      .catch((error) => null);
  }, []);

  // Fetch all Areas
  const getExpenseVoucher = () => {
    api
      .get("ExpenseVoucher/AllExpenceVourcher")
      .then((result) => {
        setexpenseVoucherData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Get data of a single Area
  const getSingleExpenseVoucher = (id) => {
    api
      .get(`ExpenseVoucher/SingleExpenseVoucher?ExpenseVoucherID=${id}`)
      .then((result) => {
        const expenseVoucherData = result.data.data;
        setEditexpenseVoucherData(expenseVoucherData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Delete Area
  const deleteExpenseVoucher = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`ExpenseVoucher/DeleteExpenseVoucher?ExpenseVoucherID=${id}`)
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              getExpenseVoucher();
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  };

  // AG Grid Table Column
  const columnDefs = [
    {
      headerName: "ExpenseVoucher Date",
      field: "expenseVoucherDate",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => {
        return params.value ? moment(params.value).format("YYYY-MM-DD") : "";
      },
    },
    {
      headerName: "ExpenseMaster Name",
      field: "expenseMasterName",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Bill Date",
      field: "billDate",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => {
        return params.value ? moment(params.value).format("YYYY-MM-DD") : "";
      },
    },
    {
      headerName: "Bill No",
      field: "billNo",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "ExpensePayment Date",
      field: "expensePaymentDate",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => {
        return params.value ? moment(params.value).format("YYYY-MM-DD") : "";
      },
    },
    {
      headerName: "Payment Type",
      field: "paymentName",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Amount",
      field: "amount",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      flex: 1,
      minWidth: 150,
    },
    ...(RoleTemplatePermissions && RoleTemplatePermissions.editPermission
      ? [
          {
            headerName: "Edit",
            width: 70,
            field: "expenseVoucherID",
            cellStyle: { textAlign: "center" },
            headerClass: "center-text widthTableColum",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="create-outline"
                class="iconsFonts editIcon"
                onClick={() => getSingleExpenseVoucher(value)}
              ></ion-icon>
            ),
            pinned: "right",
          },
        ]
      : []),
    ...(RoleTemplatePermissions && RoleTemplatePermissions.deletePermission
      ? [
          {
            headerName: "Delete",
            width: 80,
            field: "expenseVoucherID",
            cellStyle: { textAlign: "center" },
            headerClass: "center-text widthTableColum",
            cellRenderer: ({ value }) => (
              <ion-icon
                name="trash-outline"
                class="iconsFonts deleteIcon"
                onClick={() => deleteExpenseVoucher(value)}
              ></ion-icon>
            ),
            pinned: "right",
          },
        ]
      : []),
  ];

  return (
    <>
      <ToastContainer />
      {RoleTemplatePermissions && RoleTemplatePermissions.addPermission && (
        <Card>
          <Card.Header>
            <span>New Expense</span>
          </Card.Header>
          <Card.Body>
            <ExpenseVoucherComponent
              expenseVoucherID={getExpenseVoucher}
              editexpenseVoucherData={editexpenseVoucherData}
              getExpenseeMasterData={getSingleExpenseVoucher}
            />
          </Card.Body>
        </Card>
      )}
      <Card>
        <Card.Header>
          <span>Expense Master List</span>
        </Card.Header>
        <Card.Body>
          <AgGridComponent
            rowData={expenseVoucherData}
            columnDefs={columnDefs}
          />
        </Card.Body>
      </Card>
    </>
  );
}
