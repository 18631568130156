import React, { useEffect, useState, useRef } from "react";
import "lightbox.js-react/dist/index.css";
import { SlideshowLightbox } from "lightbox.js-react";
import { Card, Col, Container, Row, Modal, Image } from "react-bootstrap";
import api, { handleApiError } from "../../Utils/api";
// import { ImageURL } from "../../imgConfig";
import "./TaskAndIssueDetailsComponent.css";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import UserSetting from "../../UserSettings.json";
import { convertDateFormat } from "../../Utils/commonFunction";
import Loader from "../../Components/Common/Loader ";

export default function TaskAndIssueDetailsComponent({
  TaskAndIssueID,
  onOpen,
  onClose,
}) {
  const [taskAndIssueData, setTaskAndIssueData] = useState([]);
  const [ImageList, SetImageList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getSingleClient(TaskAndIssueID);
  }, [TaskAndIssueID]);

  const getSingleClient = (id) => {
    setIsLoading(true);
    api
      .get(`TaskAndIssue/SingleTaskAndIssueWithImage?TaskAndIssueID=${id}`)
      .then(({ data }) => {
        const Data = data.data;
        if (!data.data.obj) {
          setTaskAndIssueData(Data);
        } else {
          setTaskAndIssueData(Data.obj);
          SetImageList(Data.list);
        }
      })
      .catch(handleApiError)
      .finally(()=>{
        setIsLoading(false);
      })
  };

  const ImageSlider = () => {
    const hasLoopRun = useRef(false);

    // Ensure the loop runs only once
    const slideshowContent = !hasLoopRun.current && (
      <SlideshowLightbox showThumbnails={true}>
        {ImageList.map((imageSrc, index) => (
          <img
            key={index}
            className="p-1 m-1 img-responsive img-thumbnail"
            src={UserSetting.imgURL + imageSrc.imagePath}
            alt={`Image ${index + 1}`}
            style={{ width: "250px" }}
          />
        ))}
      </SlideshowLightbox>
    );

    // Update the ref after the loop has run
    if (!hasLoopRun.current) {
      hasLoopRun.current = true;
    }

    return <div>{slideshowContent}</div>;
  };

  return (
    <>
    
      <Modal
        show={true}
        onHide={onClose}
        size="xl"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        style={{}}
      >
          {isLoading && <Loader/>}
        <Modal.Header closeButton>
          <Modal.Title>Task and Issue Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={4} className="mt-2">
              <div className="divBackground clientForDiv">
                <div className="divHeader clientDivHeader">
                  <i className="far fa-hospital pr-1"></i>{" "}
                  {taskAndIssueData.clientName}
                </div>
                <div className="p-2">
                  <div className="mb-2">
                    <strong className="pr-1">MobileNo: </strong>{" "}
                    {taskAndIssueData.clientMobileNumber}
                  </div>
                  <div className="mb-2">
                    <strong className="pr-1">Address: </strong>{" "}
                    {taskAndIssueData.address}
                  </div>
                  <div>
                    <strong className="pr-1">AreaName: </strong>{" "}
                    {taskAndIssueData.areaName}
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={8} className="mt-2">
              <div
                className={`divBackground ${taskAndIssueData.taskAndIssueType}-Div`}
              >
                <div
                  className={`divHeader ${taskAndIssueData.taskAndIssueType}-Header`}
                >
                  <i className="far fa-clone pr-1"></i> Information Of &nbsp;
                  {taskAndIssueData.taskAndIssueType}
                </div>
                <div className="p-2">
                  <div className="mb-2">
                    <strong className="pr-1">Ticket No:</strong>{" "}
                    {taskAndIssueData.ticketNo}
                    <span
                      className={`statusLbl ${taskAndIssueData.taskAndIssueStatusMasterName}`}
                    >
                      {taskAndIssueData.taskAndIssueStatusMasterName}
                    </span>
                  </div>

                  <div className="mb-2">
                    <strong className="pr-1">Priority: </strong>{" "}
                    {taskAndIssueData.priority}
                  </div>
                  <div className="mb-2">
                    <strong className="pr-1">Developer Name: </strong>{" "}
                    {taskAndIssueData.developerName}
                  </div>
                  <div className="mb-2">
                    <strong className="pr-1">Expected Resolve Date: </strong>{" "}
                    {convertDateFormat(taskAndIssueData.expectedResolveDate)}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="mt-4">
              <div className="detailDiv">
                <div className="divHeader detailDivHeader">
                  <i className="far fa-clone pr-1"></i> Details
                </div>
                <div
                  className="p-2"
                  dangerouslySetInnerHTML={{
                    __html: taskAndIssueData.taskAndIssueDetail,
                  }}
                ></div>
              </div>
            </Col>
          </Row>
          {ImageList.length !== 0 && (
            <Row className="mt-4 p-0">
              <Col>
                <div className="text-center">
                  {" "}
                  <Row className="m-0 p-0">
                    <ImageSlider />
                  </Row>
                </div>
              </Col>
            </Row>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
