import React, { useState, useCallback, useEffect } from "react";
import { format } from "date-fns";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import api, { handleApiError } from "../../Utils/api";
import { Card, Form, Button, Modal, Col, Row, Table } from "react-bootstrap";
import CalendarHeadDDComponent from "../CalendarHead/CalendarHeadDDComponent";
import CalendarDataComponent from "./CalendarDataComponent";
import { addMonths } from "date-fns";
import ManageClientLeadPopup from "../ManageClientLead/ManageClientLeadPopup";
import { CheckRoleTemplatePermissions } from "../../Utils/commonFunction";


export default function CalendarDataWithCalendar() {
  const [events, setEvents] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [calendarHeadID, setCalendarHeadID] = useState(0);
  const [showCalendarData, setShowCalendarData] = useState(false);
  const [showCalendarDataByDate, setShowCalendarDataByDate] = useState(false);
  const [DateWiseDataList, setDateWiseDataList] = useState([]);
  const [selectedClientID, setSelectedClientID] = useState(null);
  const [RoleTemplatePermissions, setRoleTemplatePermissions] = useState("");

  useEffect(() => {
    CheckRoleTemplatePermissions("CalendarData")
      .then((obj) => {
        setRoleTemplatePermissions(obj);
      })
      .catch((error) => null);
  }, []);

  const fetchEvents = useCallback(async (month, calendarHeadID) => {
    ;

    var calendarHead = calendarHeadID > 0 ? calendarHeadID : 0;
    try {
      api
        .get(
          `CalendarData/CalendarData?month=${month}&CalenderHeadID=${calendarHead}`
        )
        .then(({ data }) => {
          // Introduce a delay of 500 milliseconds before setting events
          setTimeout(() => {
            setEvents(data.data);
            console.log(data.data);
          }, 500);
        })
        .catch(handleApiError);
    } catch (error) {
      handleApiError(error);
    }
  }, []);

  const handleDateClick = (arg) => {
    api
      .get(`CalendarData/CalendarDataByDate?date=${arg.dateStr}`)
      .then(({ data }) =>{ 
        
       const list = data.data ;

      if(calendarHeadID > 0 ){
        const data = list.filter(item => item.calendarHeadID === calendarHeadID);
        setDateWiseDataList(data);
      }
      else{
        setDateWiseDataList(data.data)
      }
      })
      .catch(handleApiError);
    setShowCalendarDataByDate(true);
  };

  const getDataByCalenderHead = (id) => {
    ;
    console.log(id);
    const currentDate = new Date();
    const newMonth = format(currentDate, "yyyy-MM");
    fetchEvents(newMonth, id);
  };

  const handleDatesSet = (info) => {
    if (info.view.type == "dayGridMonth") {
      const nextMonth = format(addMonths(new Date(info.start), 1), "yyyy-MM");
      fetchEvents(nextMonth);
    } else {
      const newMonth = format(info.start, "yyyy-MM");
      fetchEvents(newMonth);
    }
  };

  const calendarOptions = {
    themeSystem: "bootstrap5",
    initialView: "dayGridMonth",
    headerToolbar: {
      start: "prev,next today",
      center: "title",
      end: "dayGridMonth,dayGridWeek,dayGridDay",
    },
    dayMaxEvents: true,
    eventContent: (arg) => {
      const isInactive =
        arg.event.extendedProps && arg.event.extendedProps.isActive === false;

      const style = {
        backgroundColor: isInactive ? "#90e690" : "#dcdcdc",
        color : isInactive ? "black" : "",
        width:"100%"
      };
      const styleDot = {
        border: isInactive ? "calc(var(--fc-daygrid-event-dot-width) / 2) solid black" : "calc(var(--fc-daygrid-event-dot-width) / 2) solid #20336b" 
      }

      return (
          <a className="fc-event fc-event-start fc-event-end fc-event-today fc-daygrid-event fc-daygrid-dot-event" style={style}>
            <div class="fc-daygrid-event-dot" style={styleDot}></div>
            <div class="fc-event-time">{arg.timeText}</div>
            <div class="fc-event-title">{arg.event.title}</div>
           </a>
      );
    },
  };

  return (
    <>
      <Card>
        <Card.Header>
          <Form noValidate>
            <Row>
              <CalendarHeadDDComponent
                onSelectCalendarHead={(calendarHeadID) => {
                  setCalendarHeadID(calendarHeadID);
                  getDataByCalenderHead(calendarHeadID);
                }}
                selectedCalendarHead={calendarHeadID}
                //onAdditionalChange={getDataByCalenderHead}
                key={refreshKey}
              />
              {RoleTemplatePermissions &&
                RoleTemplatePermissions.addPermission && (
                  <Col style={{ textAlign: "end" }}>
                    <br />
                    <Button
                      className="outline-primaryOfTapti"
                      onClick={() => setShowCalendarData(true)}
                    >
                      New +
                    </Button>
                  </Col>
                )}
            </Row>
          </Form>
        </Card.Header>
        <Card.Body>
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            {...calendarOptions}
            events={events}
            dateClick={handleDateClick}
            datesSet={handleDatesSet}
          />
        </Card.Body>
      </Card>

      <Modal
        show={showCalendarData}
        onHide={() => setShowCalendarData(false)}
        size="xl"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton closeVariant={"white"}>
          <Modal.Title id="example-custom-modal-styling-title">
            New Calendar
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CalendarDataComponent
            setShowCalendarData={() => {
              setShowCalendarData(false);
              const newMonth = format(new Date(), "yyyy-MM");
              fetchEvents(newMonth);
            }}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showCalendarDataByDate}
        onHide={() => {
          setShowCalendarDataByDate(false);
          setDateWiseDataList([]);
        }}
        size="xl"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton closeVariant={"white"}>
          <Modal.Title id="example-custom-modal-styling-title">
            New Calendar
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Calendar Head</th>
                <th>Client Name</th>
                <th>Remarks</th>
                <th>Reminder Date/Time</th>
              </tr>
            </thead>
            <tbody>
              {DateWiseDataList.map((item, index) => (
                <tr key={index}>
                  <td>{item.calendarHeadName}</td>
                  <td onClick={() => setSelectedClientID(item.clientID)}>
                    {item.clientName}
                  </td>
                  <td>{item.remarks}</td>
                  <td>
                    {format(
                      new Date(item.reminderDateTime),
                      "dd-MM-yyyy HH:mm"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      {selectedClientID && (
        <ManageClientLeadPopup
          clientID={selectedClientID}
          onClose={() => setSelectedClientID(null)}
        />
      )}
    </>
  );
}
